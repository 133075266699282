import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { useParams, useHistory } from "react-router-dom";
import userDetails from "../../services/userTokenDetails";
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import Successimg from '../../assets/images/success.jpg'
import Warningimg from '../../assets/images/Warning.jpg'
import WarningGIFimg from '../../assets/images/WARNINGG.gif'

const QrScan = () => {
    let emptyparticipant = {
        fullName: '',
        isarrived: '',
        ischeckedin: '',
        ispresent: '',
        mobileno: '',
        email: ''
    };
    const history = useHistory();
    const [participantdata, setparticipantdata] = useState(emptyparticipant);
    const [SuccessDialog, setSuccessDialog] = useState(false);
    const [AirportWarningDialog, setAirportWarningDialog] = useState(false);
    const [HotelWarningDialog, setHotelWarningDialog] = useState(false);
    const [VenueWarningDialog, setVenueWarningDialog] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    //const
    const param = useParams();
    const toast = useRef(null);
    var usrDetails = userDetails();
    const UserName = localStorage.getItem("Username");

    useEffect(() => {
        if (param.id != '' && param.id != undefined) {
            getparticipantDetailsbyid(param.id);
        }
    }, []);

    const getparticipantDetailsbyid = (id) => {
        debugger;
        axios.get('/api/Participant/GetParticipantById/' + id)
            .then((Response) => {
                debugger;
                let _participant = { ...Response.data };
                _participant['fullName'] = (Response.data.fullName);
                _participant['mobileno'] = (Response.data.mobileNo);
                _participant['email'] = (Response.data.email);
                //_participant['ispresent'] = (Response.data.present);
                _participant['ispresent'] = (Response.data.dayTwoPresent);
                _participant['ischeckedin'] = (Response.data.isCheckedIn);
                _participant['isarrived'] = (Response.data.isArrived);
                setparticipantdata(_participant);
                //UpdateParticipantRecord(Response.data.present, Response.data.isCheckedIn, Response.data.isArrived);
                UpdateParticipantRecord(Response.data.dayTwoPresent, Response.data.isCheckedIn, Response.data.isArrived);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    }

    const UpdateParticipantRecord = (Present, CheckedIn, Arrived) => {
        if (usrDetails.role == "AIRPORT CO-ORDINATOR") {
            if (Arrived != true) {
                axios.put('/api/Participant/UpdateParticipantOnQrScan', {
                    id: param.id,
                    IsArrived: true,
                    ModifiedBy: UserName,
                })
                    .then(res => {
                        setSuccessDialog(true);
                    }).catch((error) => {
                        setIsLoading(false);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                    });
            }
            else {
                setAirportWarningDialog(true);
                // toast.current.show({ severity: 'error', summary: 'Invalid', detail: 'Already Scanned Once For AIRPORT', life: 3000 });
            }
        }
        if (usrDetails.role == "HOTEL CO-ORDINATOR") {
            if (CheckedIn != true) {
                axios.put('/api/Participant/UpdateParticipantOnQrScan', {
                    id: param.id,
                    IsCheckedIn: true,
                    ModifiedBy: UserName,
                })
                    .then(res => {
                        setSuccessDialog(true);
                    }).catch((error) => {
                        setIsLoading(false);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                    });
            }
            else {
                setHotelWarningDialog(true);
                // toast.current.show({ severity: 'error', summary: 'Invalid', detail: 'Already Scanned Once For HOTEL', life: 3000 });
            }
        }
        if (usrDetails.role == "VENUE CO-ORDINATOR" || usrDetails.role == "ADMIN" || usrDetails?.role == "ITADMIN") {
            if (Present != true) {
                axios.put('/api/Participant/UpdateParticipantOnQrScan', {
                    id: param.id,
                    Present: true,
                    ModifiedBy: UserName,
                })
                    .then(res => {
                        setSuccessDialog(true);
                    }).catch((error) => {
                        setIsLoading(false);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                    });
            }
            else {
                setVenueWarningDialog(true);
                // toast.current.show({ severity: 'error', summary: 'Invalid', detail: 'Already Scanned Once For VENUE', life: 3000 });
            }
        }

    }

    //For Ok Button On Participant Register Success Dialog
    const footerContent = (
        <div>
            <Button label="OK" icon='fa fa-solid fa-check' onClick={(e) => { setSuccessDialog(false); history.push('/home/qrscanner') }}></Button>
        </div>
    );

    const airportfooterContent = (
        <div>
            <Button label="OK" icon='fa fa-solid fa-check' onClick={(e) => { setAirportWarningDialog(false); history.push('/home/qrscanner') }}></Button>
        </div>
    );

    const venuefooterContent = (
        <div>
            <Button label="OK" icon='fa fa-solid fa-check' onClick={(e) => { setVenueWarningDialog(false); history.push('/home/qrscanner') }}></Button>
        </div>
    );

    const hotelfooterContent = (
        <div>
            <Button label="OK" icon='fa fa-solid fa-check' onClick={(e) => { setHotelWarningDialog(false); history.push('/home/qrscanner') }}></Button>
        </div>
    );


    const Successheadertemplate = () => {
        return (
            <div className="flex justify-content-center">
                <img src={Successimg} height="160px" width="100px"/>
            </div>
        )
    }

    const Warningheadertemplate = () => {
        return (
            <div className="flex justify-content-center">
                <img src={Warningimg} height="100px" width="100px"/>
            </div>
        )
    }

    const dialogStyle = {
        backgroundColor: '#f0f0f0', // Set your desired background color here
        padding: '20px',
        borderRadius: '8px',
        // Add other styles as needed
      };

    return (
        <div>
            <Toast ref={toast} />

            <Dialog closable={false} header={Successheadertemplate} draggable={false}
                visible={SuccessDialog}
                onHide={false} footer={footerContent} style={{ width: "80%", fontFamily: "Serif" }}>

                <div className="flex justify-content-center mb-2" >
                    
                    <div className={(usrDetails.role == "VENUE CO-ORDINATOR" || usrDetails.role == "ADMIN" || usrDetails?.role == "ITADMIN") && (VenueWarningDialog == false) ? "" : "hidden"} >
                        <h4 className="font-bold" style={{ color: "rgb(42, 102, 151)" }}>
                            Scan complete! Attendance status: Present.
                        </h4>
                    </div>

                    <div className={usrDetails.role == "AIRPORT CO-ORDINATOR" && (AirportWarningDialog == false) ? "" : "hidden"} >
                        <h4 className="font-bold" style={{ color: "rgb(42, 102, 151)" }}>
                            QR Code Accepted. Airport Check-In Complete.
                        </h4>
                    </div>

                    <div className={usrDetails.role == "HOTEL CO-ORDINATOR" && (HotelWarningDialog == false) ? "" : "hidden"} >
                        <h4 className="font-bold" style={{ color: "rgb(42, 102, 151)" }}>
                            QR Code Accepted. Check-In Successful. Welcome to your hotel!
                        </h4>
                    </div>

                </div>

                <div className="flex" style={{ color: "rgb(42, 102, 151)" }}>
                    <h5 className="font-bold">
                        Name : </h5><span className="ml-2 mt-1">{participantdata.fullName}</span>
                </div>
{/*                 
                <div className="flex" style={{ color: "rgb(42, 102, 151)" }}>
                    <h5 className="font-bold">
                        Name : </h5><span className="ml-2 mt-1">{participantdata.fullName}</span>
                </div>

                <div className="flex" style={{ color: "rgb(42, 102, 151)" }}>
                    <h5 className="font-bold">
                        Mobile No : </h5><span className="ml-2 mt-1">{participantdata.mobileno}</span>
                </div>

                <div className="flex" style={{ color: "rgb(42, 102, 151)" }}>
                    <h5 className="font-bold">
                        Email : </h5><span className="ml-2 mt-1">{participantdata.email}</span>
                </div> */}

            </Dialog>


            <Dialog closable={false} header={Warningheadertemplate} draggable={false} 
            visible={AirportWarningDialog} 
            onHide={false}
                footer={airportfooterContent} style={{ width: "80%", fontFamily: "Serif" }}>
                <h5 className="flex justify-content-center">
                    Double Scan Alert: Airport check-in Already Noted.
                </h5>
                <h5 className="font-bold flex justify-content-center">THANKS</h5>

                <div className="flex" style={{ color: "rgb(42, 102, 151)" }}>
                    <h5 className="font-bold">
                        Name : </h5><span className="ml-2 mt-1">{participantdata.fullName}</span>
                </div>

            </Dialog>
            <Dialog closable={false}  header={Warningheadertemplate} draggable={false} 
            visible={HotelWarningDialog}
            onHide={false} footer={hotelfooterContent} style={{ width: "80%", fontFamily: "Serif" }}>
                <h4 className="flex justify-content-center">
                    Already Checked In. Enjoy Your Accommodation!
                </h4>
                <h5 className="font-bold flex justify-content-center">THANKS</h5>

                <div className="flex" style={{ color: "rgb(42, 102, 151)" }}>
                    <h5 className="font-bold">
                        Name : </h5><span className="ml-2 mt-1">{participantdata.fullName}</span>
                </div>

            </Dialog>
            <Dialog closable={false}  header={Warningheadertemplate} draggable={false} 
            visible={VenueWarningDialog}
            onHide={false} footer={venuefooterContent}
             style={{ width: "80%", fontFamily: "Serif" }}>
                <h4 className="flex justify-content-center">
                    Double Scan Alert: Attendance Already Noted.
                </h4>
                <h5 className="font-bold flex justify-content-center">THANKS</h5>

                <div className="flex" style={{ color: "rgb(42, 102, 151)" }}>
                    <h5 className="font-bold">
                        Name : </h5><span className="ml-2 mt-1">{participantdata.fullName}</span>
                </div>
                
            </Dialog>
        </div>
    )
}

export default QrScan;