import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { InputText } from 'primereact/inputtext';
import exportData from "../../services/exportToExcelService";
import { Toolbar } from 'primereact/toolbar';
import { Button } from "primereact/button";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Dropdown } from 'primereact/dropdown';

const RolesList = () => {

    const [Roles, SetRoles] = useState([]);
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(RowsPerPageDropdown[0])
    const [totalRecords, setTotalRecords] = useState(0)
    const [pageno, setpageno] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [RoleName, setRoleName] = useState(null);
    const [enableFilter, setEnableFilter] = useState(false);

    useEffect(() => {
        GetrolesList();
    }, [pageno, limit]);

    const GetrolesList = (isExportExcel = false) => {
        setIsLoading(true);
        if (!isExportExcel) SetRoles([])
        axios.post('/api/Roles/GetAll', {
            sortOrder: "-1",
            CurrentPageNumber: pageno,
            PageSize: limit,
            RoleName: RoleName ? RoleName : null
        }).then(Response => {
            if (isExportExcel) {
                exportData(Response.data.data, "Batch")
                setIsLoading(false);
                return false;
            }
            SetRoles(Response.data.data)
            setTotalRecords(Response.data.totalRecords)
            setIsLoading(false);
        })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };


    //pagination
    const onBasicPageChange = (event) => {
        setIsLoading(true);
        SetRoles([])
        setSkip(event.first)
        setLimit(event.rows)
        setpageno((event.first / event.rows) + 1)
        GetrolesList()
        setIsLoading(false);
    }
    //Template for pagination
    const template2 = {
        layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            )
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    const RolesFilter = () => {
        return (
            <InputText name="RoleName" value={RoleName} placeholder="Enter Role Name" onChange={(e) => setRoleName(e.target.value)}
                onKeyDown={(e) => RolesFilteronEnter(e)} style={{ width: '300px' }}></InputText>
        )
    }

    const RolesFilteronEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setRoleName(e.target.value);
                setIsLoading(true);
                GetrolesList();
            }
        }
    }

    const exportExcel = () => {
        GetrolesList(true);
    }

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button type="button" disabled = {RoleName == null ? true : false} icon="pi pi-filter-slash" className="mr-2" onClick={(e) => { setRoleName(null); GetrolesList() }} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>
                {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
                <Button disabled={!(Roles != null && Roles.length > 0)} tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} onClick={() => { exportExcel(); }} icon="pi pi-file-excel" className="mr-2" outlined />
            </div>
        )
    }

    return (

        <AccessControl
            allowedPermissions={["list:roles"]}
            renderNoAccess={() => <NoAccess />}
        >
            <div className="trico-Grid">
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <Toast ref={toast} />
                                    <h5 className="headerfont"><span><i className="fa fa-solid fa-cog mr-2"></i>Roles</span></h5>
                                    <Toolbar right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <DataTable ref={dt} value={Roles}
                                        dataKey="id" className="datatable-responsive"
                                        responsiveLayout="scroll" filterDisplay={ "row"}>
                                        <Column field="name" header="Name" sortable filter filterElement={RolesFilter} showFilterMenu={false}></Column>
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{marginRight: "15px"}} first={skip} rows={limit} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </AccessControl>
    )
}

export default RolesList;