import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory } from "react-router-dom";
import { FileUpload } from "primereact/fileupload";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import * as genderData from "./../../enums/Genders";
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Tooltip } from "primereact/tooltip";
import { useReactToPrint } from "react-to-print";
import DownloadICard from "../../services/downloadicard";
import userDetails from "../../services/userTokenDetails";
import * as Designations from "../../enums/Designations";

const ICardDetails = () => {
    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [dayOfEventScreen, setDayOfEventScreen] = useState();
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [fullName, setfullName] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [filterCity, setFilterCity] = useState(null);
    const [filterState, setFilterState] = useState(null);
    const [filterCountry, setFilterCountry] = useState(null);
    const [filterBoardingCity, setFilterBoardingCity] = useState(null);
    const [filterHospitalName, setFilterHospitalName] = useState(null);
    const [filterCardNo, setFilterCardNo] = useState(null);
    const [filterCompany, setFilterCompany] = useState(null);
    const [filterSpeciality, setFilterSpeciality] = useState(null);
    const [id, setid] = useState(new Array());
    const [ActionDisable, setActionDisable] = useState(true);
    const [name, setname] = useState(new Array());
    const [icarddata, seticarddata] = useState([]);

    const [InBatchfilter, setInBatchfilter] = useState(null);
    const [OutBatchfilter, setOutBatchfilter] = useState(null);
    const [HotelFilter, setHotelFilter] = useState(null);

    // Flags for show/hide columns
    const [isExportExcel, setIsExportExcel] = useState(false);
    const [enableFilter, setEnableFilter] = useState(false);

    // Const
    const toast = useRef(null);
    const componentRef = useRef();
    const history = useHistory();
    const dt = useRef(null);
    var usrDetails = userDetails();
    const UserName = localStorage.getItem("Username");

    let DayOfEvent = "";

    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: null,
        genderdropdowndata: null,
        fullName: null,
        filterHospitalName: null,
        filterEmail: null,
        filterMobileNo: null,
        filterCity: null,
        filterCountry: null,
        filterCardNo: null,
        filterAddress: null,
        filterSpeciality: null,
        filterBoardingCity: null,
        filterState: null,
        filterCountry: null,
        filterCompany: null,
        Designationfilter: null,
        inbatchfilter: null,
        outbatchfilter: null,
        HotelFilter: null,
    }); // For Sorting And Pagination Purpose

    // On Screen Load
    useEffect(() => {
        getparticipantlist();
        // document.documentElement.style.setProperty('overflow', 'hidden')
    }, []);

    //#region Common Methods

    // - Get All Participant Api
    const getparticipantlist = (isExportExcel = false, event, IsPresent = null) => {
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios
            .post("/api/Participant/GetAll", {
                paginationRequired: !isExportExcel,
                SortDirection: lazyState.current.sortOrder,
                PageSize: lazyState.current.rows,
                CurrentPageNumber: event && event.rows ? event.first / event.rows + 1 : lazyState.current.page,
                SortField: event && event.sortField ? event.sortField : "Firstname",
                registrationStatus: "APPROVED",
                CompanyFilter: lazyState.current.filterCompany ? lazyState.current.filterCompany.trim() : "",
                FullName: lazyState.current.fullName ? lazyState.current.fullName.trim() : null,
                Gender: lazyState.current.genderdropdowndata ? lazyState.current.genderdropdowndata.code.trim() : null,
                HospitalName: lazyState.current.filterHospitalName ? lazyState.current.filterHospitalName.trim() : null,
                Email: lazyState.current.filterEmail ? lazyState.current.filterEmail.trim() : null,
                MobileNo: lazyState.current.filterMobileNo ? lazyState.current.filterMobileNo.trim() : null,
                CardNo: lazyState.current.filterCardNo ? lazyState.current.filterCardNo.trim() : null,
                City: lazyState.current.filterCity ? lazyState.current.filterCity.trim() : null,
                Speciality: lazyState.current.filterSpeciality ? lazyState.current.filterSpeciality.trim() : null,
                BoardingCity: lazyState.current.filterBoardingCity ? lazyState.current.filterBoardingCity.trim() : null,
                State: lazyState.current.filterState ? lazyState.current.filterState.trim() : null,
                country: lazyState.current.filterCountry ? lazyState.current.filterCountry.trim() : null,
                Designation: lazyState.current.Designationfilter ? lazyState.current.Designationfilter.name.trim() : null,
                InBatchBooking: lazyState.current.inbatchfilter ? lazyState.current.inbatchfilter.trim() : null,
                OutBatchBooking: lazyState.current.outbatchfilter ? lazyState.current.outbatchfilter.trim() : null,
                ParticipantHotel: true,
                HotelFilter: lazyState.current.HotelFilter ? lazyState.current.HotelFilter.trim() : null,
            })
            .then((Response) => {
                DayOfEvent = "I-Card Detail";
                setDayOfEventScreen(DayOfEvent);

                if (!isExportExcel) setParticipantlist([]);
                let tempData = [];
                for (let dataObj of Response.data.data) {
                    // Pass body data in excelsheets.
                    let allExcelData;
                    let excelcolumnData = {
                        Id: dataObj.serialNumber,
                        SerialNo: dataObj.serialNumber,
                        Name: dataObj.fullName,
                        InBatch: dataObj.inBatchBooking,
                        OutBatch: dataObj.outBatchBooking,
                        Company: dataObj.company,
                        Email: dataObj.email,
                        MobileNo: dataObj.mobileNo,
                        CardNo: dataObj.cardNo,
                        Designation: dataObj.designation,
                        Hotel: dataObj.hotelName,
                        // Hospital: dataObj.hospitalName,
                        // "Gender": dataObj.gender,
                        // "City": dataObj.cityname,
                    };
                    if (selectedColumns.length > 0) {
                        for (let selectedColumn of selectedColumns) {
                            let fieldName = selectedColumn.field;
                            let headerName = selectedColumn.header;
                            excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] };
                        }
                        tempData.push(excelcolumnData);
                    } else {
                        allExcelData = {
                            Id: dataObj.serialNumber,
                            SerialNo: dataObj.serialNumber,
                            Name: dataObj.fullName,
                            InBatch: dataObj.inBatchBooking,
                            OutBatch: dataObj.outBatchBooking,
                            Company: dataObj.company,
                            Email: dataObj.email,
                            MobileNo: dataObj.mobileNo,
                            CardNo: dataObj.cardNo,
                            Designation: dataObj.designation,
                            Hotel: dataObj.hotelName,
                            // Hospital: dataObj.hospitalName,
                            // "City": dataObj.cityname,
                            // "Gender": dataObj.gender,
                        };
                        tempData.push(allExcelData);
                    }
                }
                if (isExportExcel) {
                    exportData(tempData, DayOfEvent);
                    setIsLoading(false);
                    return false;
                }
                setTotalRecords(Response.data.totalRecords);
                setParticipantlist(Response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    //Download I-card
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "I-Card",
    });

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(false, lazyState.current, null);
        setIsLoading(false);
        // };
    };

    //Template for pagination
    const template2 = {
        layout: " CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "19%", textAlign: "center" }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return (
                <span>
                    Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} />
                </span>
            );
        },
    };

    // Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <AccessControl allowedPermissions={["list:icarddetails"]}>
                <div className="my-2">
                    <FileUpload
                        disabled={!(participantlist != null && participantlist.length > 0)}
                        chooseOptions={{ label: "Import Card No", icon: "pi pi-file-excel" }}
                        mode="basic"
                        auto
                        url="https://www.primefaces.org/primereact-v8/upload.php"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        className="mr-2"
                        onUpload={importCardNo}
                    />
                    <Button
                        label="Download I-Card"
                        disabled={ActionDisable}
                        className="mr-2"
                        icon="fa fa-download"
                        onClick={() => {
                            handlePrint();
                        }}
                        outlined
                    ></Button>
                </div>
            </AccessControl>
        );
    };

    const removeFilter = () => {
        lazyState.current.fullName = null;
        setfullName(null);
        lazyState.current.filterHospitalName = null;
        setFilterHospitalName(null);
        lazyState.current.filterCompany = null;
        setFilterCompany(null);
        lazyState.current.filterEmail = null;
        setFilterEmail(null);
        lazyState.current.filterMobileNo = null;
        setFilterMobileNo(null);
        lazyState.current.filterCity = null;
        setFilterCity(null);
        lazyState.current.genderdropdowndata = null;
        lazyState.current.filterSpeciality = null;
        setFilterSpeciality(null);
        lazyState.current.filterBoardingCity = null;
        setFilterBoardingCity(null);
        lazyState.current.filterState = null;
        setFilterState(null);
        lazyState.current.filterCountry = null;
        setFilterCountry(null);
        lazyState.current.filterCardNo = null;
        setFilterCardNo(null);

        setInBatchfilter(null);
        lazyState.current.inbatchfilter = null;
        setOutBatchfilter(null);
        lazyState.current.outbatchfilter = null;

        lazyState.current.HotelFilter = null;
        setHotelFilter(null);

        getparticipantlist(false, lazyState.current, null);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl allowedPermissions={["list:icarddetails"]}>
                    <Button
                        disabled={
                            lazyState.current.fullName == null &&
                            lazyState.current.filterHospitalName == null &&
                            lazyState.current.filterCompany == null &&
                            lazyState.current.filterEmail == null &&
                            lazyState.current.filterMobileNo == null &&
                            lazyState.current.filterCity == null &&
                            lazyState.current.filterBoardingCity == null &&
                            lazyState.current.filterSpeciality == null &&
                            lazyState.current.filterState == null &&
                            lazyState.current.filterCountry == null &&
                            lazyState.current.filterCardNo == null &&
                            lazyState.current.genderdropdowndata == null &&
                            lazyState.current.inbatchfilter == null &&
                            lazyState.current.outbatchfilter == null &&
                            lazyState.current.HotelFilter == null
                                ? true
                                : false
                        }
                        icon="pi pi-filter-slash"
                        className="mr-2"
                        onClick={() => removeFilter()}
                        tooltip="Reset Filter"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        outlined
                    ></Button>
                    {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} /> : <Button type="button" icon="pi pi-filter" className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} />} */}
                    <div className="my-2">{header}</div>
                </AccessControl>
                <AccessControl allowedPermissions={["export:icarddetails"]}>
                    <Button
                        disabled={!(participantlist != null && participantlist.length > 0)}
                        onClick={() => {
                            getparticipantlist(true, null);
                        }}
                        icon="pi pi-file-excel"
                        className="mr-2"
                        tooltip="Export Excel"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        outlined
                    />
                </AccessControl>
            </div>
        );
    };

    //For Email
    const ButtonMailto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="mailto"
                to="#"
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >
                {rowData.email}
            </Link>
        );
    };

    //For Mobile No
    const ButtonPhoneto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="callto"
                to="#"
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >
                {rowData.mobileNo}
            </Link>
        );
    };

    // For Sorting
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(false, lazyState.current, null);
    };
    //#endregion

    //#region Method for view attendees
    const ViewAttendees = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="viewattendee"
                to="#"
                onClick={(e) => {
                    history.push("/home/viewparticipant/" + rowData.id);
                }}
            >
                {rowData.fullName}
            </Link>
        );
    };
    //#endregion

    //#region Method for Import Card No
    const toCapitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const importCardNo = (e, processName = "") => {
        const file = e.files[0];
        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, { type: "array" });
                const wsname = processName != "" ? "Exception Stat." : wb.SheetNames[0];
                const header = processName != "" ? ["ID", "Name", "Department", "Date", "On-duty", "Off-duty"] : 1;
                const ws = wb.Sheets[wsname];
                const data = xlsx.utils.sheet_to_json(ws, { header: header });

                // Prepare DataTable
                const cols = data[0];
                data.shift();
                let [colId, colName, colCompany, colEmail, colMobileNo, colCardNo] = cols;
                // if (colId == "Id" && colName == "Name" && colHospital == "Hospital" && colEmail == "Email" && colMobileNo == "MobileNo" && colGender == "Gender" && colCity == "City" && colCardNo == "CardNo") {
                if (colId == "Id" && colName == "Name" && colCompany == "Company" && colEmail == "Email" && colMobileNo == "MobileNo" && colCardNo == "CardNo") {
                    let _importedCols = cols.map((col) => ({ field: col, header: toCapitalize(col) }));
                    let _importedData = data.map((d) => {
                        return cols.reduce((obj, c, i) => {
                            obj[c] = d[i];
                            return obj;
                        }, {});
                    });
                    if (_importedData.length > 0) {
                        setIsLoading(true);
                        for (const dataObj of _importedData) {
                            if (dataObj.CardNo == undefined) {
                                dataObj.CardNo = "";
                            }
                            if (!isNaN(dataObj.CardNo)) {
                                dataObj.CardNo = dataObj.CardNo.toString();
                                dataObj.CardNo = dataObj.CardNo.slice(-10);
                                UpdateCardNo(dataObj);
                            } else {
                                dataObj.CardNo = "";
                            }
                        }
                        setIsLoading(false);
                        getparticipantlist(isExportExcel, null);
                        toast.current.show({ severity: "success", summary: "Successful", detail: "ICard No Imported Successfully", life: 3000 });
                    }
                } else {
                    toast.current.show({ severity: "error", summary: "Invalid", detail: "This file is not valid", life: 3000 });
                }
            };
            reader.readAsArrayBuffer(file);
        });
    };

    const UpdateCardNo = (attendeeData) => {
        const url = "/api/Participant/updateparticipantbymultiple";
        const data = {
            Id: attendeeData.Id,
            CardNo: attendeeData.CardNo,
            ModifiedBy: UserName,
        };
        axios
            .put(url, data)
            .then((res) => {
                getparticipantlist(false, lazyState.current, null);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };
    //#endregion

    // Row Selection / Unselect Functions
    const onAllRowSelect = (e) => {
        setActionDisable(false);
        setid(
            e.data.map((i, k) => {
                return i.id;
            })
        );
        seticarddata(e.data);
    };

    const onRowSelect = (event) => {
        setActionDisable(false);
        setid((ing) => [...ing, event.data.id]);
        setname((ing) => [...ing, event.data.titleName]);
        seticarddata((ing) => [...ing, event.data]);
    };

    const onRowUnselect = (event) => {
        if (id != undefined) {
            var removeidfromdata = id.indexOf(event.data.id);
            id.splice(removeidfromdata, 1);
            if (id.length == 0) {
                setActionDisable(true);
            }
        } else {
            var removefromarray = id.indexOf(event.data.id);
            id.splice(removefromarray, 1);
        }
        if (icarddata != undefined) {
            var removefromdata = icarddata.indexOf(event.data.id);
            icarddata.splice(removefromdata, 1);
        } else {
            var removefromdata = icarddata.indexOf(event.data.id);
            icarddata.splice(removefromdata, 1);
        }
    };

    const onAllRowUnSelect = (e) => {
        setActionDisable(true);
        setid(
            e.data.map((i, k) => {
                return i.id;
            })
        );
        id.splice(0, id.length);
        seticarddata(e.data);
        icarddata.splice(0, icarddata.length);
    };

    //#region Method for filteration
    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.fullName = e.target.value ? e.target.value : null;
                        break;
                    case "hospital":
                        lazyState.current.filterHospitalName = e.target.value ? e.target.value : null;
                        break;
                    case "company":
                        lazyState.current.filterCompany = e.target.value ? e.target.value : null;
                        break;
                    case "Email":
                        lazyState.current.filterEmail = e.target.value ? e.target.value : null;
                        break;
                    case "MobileNo":
                        lazyState.current.filterMobileNo = e.target.value ? e.target.value : null;
                        break;
                    case "city":
                        lazyState.current.filterCity = e.target.value ? e.target.value : null;
                        break;
                    case "CardNo":
                        lazyState.current.filterCardNo = e.target.value ? e.target.value : null;
                        break;
                    case "speciality":
                        lazyState.current.filterSpeciality = e.target.value ? e.target.value : null;
                        break;
                    case "boardingcity":
                        lazyState.current.filterBoardingCity = e.target.value ? e.target.value : null;
                        break;
                    case "state":
                        lazyState.current.filterState = e.target.value ? e.target.value : null;
                        break;
                    case "country":
                        lazyState.current.filterCountry = e.target.value ? e.target.value : null;
                        break;
                    case "inbatch":
                        lazyState.current.inbatchfilter = e.target.value ? e.target.value : null;
                        break;
                    case "outbatch":
                        lazyState.current.outbatchfilter = e.target.value ? e.target.value : null;
                        break;
                    case "Hotel":
                        lazyState.current.HotelFilter = e.target.value ? e.target.value : null;
                        break;
                }
                getparticipantlist(false, lazyState.current, null);
                setIsLoading(false);
            }
        }
    };

    const HotelFilterTemplate = () => {
        return <InputText id="Hotel" value={HotelFilter} placeholder="Enter Hotel" onChange={(e) => setHotelFilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const UserFilterTemplate = () => {
        return <InputText id="fullName" autoFocus value={fullName} placeholder="Enter FirstName" onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const InBatchFilterTemplate = () => {
        return <InputText id="inbatch" value={InBatchfilter} placeholder="Enter In Batch" onChange={(e) => setInBatchfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutBatchFilterTemplate = () => {
        return <InputText id="outbatch" value={OutBatchfilter} placeholder="Enter Out Batch" onChange={(e) => setOutBatchfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const HospitalFilterTemplate = () => {
        return <InputText id="hospital" value={filterHospitalName} placeholder="Enter Hospital" onChange={(e) => setFilterHospitalName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const EmailFilterTemplate = () => {
        return <InputText id="Email" value={filterEmail} placeholder="Enter Email" onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const MobileFilterTemplate = () => {
        return <InputText id="MobileNo" value={filterMobileNo} placeholder="Enter Mobile no" onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CardNoFilterTemplate = () => {
        return <InputText id="CardNo" value={filterCardNo} placeholder="Enter Card No" onChange={(e) => setFilterCardNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CompanyFilterTemplate = () => {
        return <InputText id="company" value={filterCompany} placeholder="Enter Company" onChange={(e) => setFilterCompany(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CityFilterTemplate = () => {
        return <InputText id="city" value={filterCity} placeholder="Enter City" onChange={(e) => setFilterCity(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const GenderFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.genderdropdowndata}
                options={genderData.Gender}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.genderdropdowndata = e.target.value ? e.target.value : null;
                    getparticipantlist();
                    e.preventDefault();
                }}
                placeholder="Select Gender"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const SpecialityFilterTemplate = () => {
        return <InputText id="speciality" value={filterSpeciality} placeholder="Enter Speciality" onChange={(e) => setFilterSpeciality(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const BoardingCityFilterTemplate = () => {
        return <InputText id="boardingcity" value={filterBoardingCity} placeholder="Enter Boarding City" onChange={(e) => setFilterBoardingCity(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const StateFilterTemplate = () => {
        return <InputText id="state" value={filterState} placeholder="Enter State" onChange={(e) => setFilterState(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CountryFilterTemplate = () => {
        return <InputText id="country" value={filterCountry} placeholder="Enter Country" onChange={(e) => setFilterCountry(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const DesignationFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Designationfilter}
                options={Designations.Designation}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Designationfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { Designation: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Designation"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };
    //#endregion

    //#region Method for Column toggle
    const hidecolumns = [
        // { field: "speciality", header: "Speciality" },
        { field: "hospitalName", header: "Hospital" },
        { field: "countryname", header: "Country" },
        { field: "statename", header: "State" },
        { field: "cityname", header: "City" },
        { field: "boardingcityname", header: "Boarding City" },
    ];

    let unSelectedColumns = [];
    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = hidecolumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        unSelectedColumns = hidecolumns.filter((o) => !selectedColumns.some((i) => i.field === o.field && i.header === o.header));
        unselectedColumnData();
        setSelectedColumns(orderedSelectedColumns);
    };

    const unselectedColumnData = () => {
        let isFiltered = false;
        if (unSelectedColumns) {
            unSelectedColumns.map((col) => {
                switch (col.header) {
                    case "Hospital":
                        if (lazyState.current.filterHospitalName != null) {
                            lazyState.current.filterHospitalName = null;
                            isFiltered = true;
                            return setFilterHospitalName(null);
                        }
                        break;
                    case "City":
                        if (lazyState.current.filterCity != null) {
                            lazyState.current.filterCity = null;
                            isFiltered = true;
                            return setFilterCity(null);
                        }
                        break;
                    // case "Speciality":
                    //     if (lazyState.current.filterSpeciality != null) {
                    //         lazyState.current.filterSpeciality = null;
                    //         isFiltered = true;
                    //         return setFilterSpeciality(null);
                    //     }
                    //     break;
                    case "Boarding City":
                        if (lazyState.current.filterBoardingCity != null) {
                            lazyState.current.filterBoardingCity = null;
                            isFiltered = true;
                            return setFilterBoardingCity(null);
                        }
                        break;
                    case "State":
                        if (lazyState.current.filterState != null) {
                            lazyState.current.filterState = null;
                            isFiltered = true;
                            return setFilterState(null);
                        }
                        break;
                    case "Country":
                        if (lazyState.current.filterCountry != null) {
                            lazyState.current.filterCountry = null;
                            isFiltered = true;
                            return setFilterCountry(null);
                        }
                        break;
                }
            });
            if (isFiltered) {
                getparticipantlist(false, lazyState.current, null);
            }
        }
    };

    const header = (
        <div style={{ textAlign: "left", marginRight: "10px" }}>
            <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: "20em" }} display="chip" />
        </div>
    );

    const sortColumn = {
        Speciality: "Speciality",
        Hospital: "HospitalName",
    };

    const hidecolumnComponents = selectedColumns.map((col) => {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                sortable
                sortField={sortColumn[col.header]}
                headerStyle={{ width: "15%", minWidth: "15rem" }}
                filter
                showFilterMenu={false}
                filterElement={col.header == "Hospital" ? HospitalFilterTemplate : col.header == "City" ? CityFilterTemplate : col.header == "Boarding City" ? BoardingCityFilterTemplate : col.header == "State" ? StateFilterTemplate : CountryFilterTemplate}
            />
        );
    });

    const ViewAttendeeIcon = (rowData) => {
        return (
            <div className="actions">
                <Link to="#" onClick={(e) => history.push({ pathname: "/home/viewattendeeRecord/" + rowData.id, state: { detail: "DayOfEvent" } })}>
                    {" "}
                    <span className={usrDetails?.role == "ADMIN" || usrDetails?.role == "ITADMIN" ? "fa fa-solid fa-eye" : "hidden"} id="viewattendeerecord" style={{ color: " rgb(42, 102, 151)" }}></span>
                </Link>
            </div>
        );
    };

    //#endregion

    return (
        <AccessControl allowedPermissions={["route:icarddetails"]} renderNoAccess={() => <NoAccess />}>
            <div className="trico-Grid">
                <div style={{ display: "none" }}>
                    {/* <div> */}
                    <div ref={componentRef} className="a4-container">
                        {icarddata.map((element) => {
                            return <DownloadICard data={element} />;
                        })}
                    </div>
                </div>
                <Toast ref={toast} />
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="p-col">
                            <div className="card">
                                <h5 className="headerfont">
                                    <span>
                                        <i className="fa fa-solid fa-calendar mr-2"></i>
                                        {dayOfEventScreen}
                                    </span>
                                </h5>
                                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                <Tooltip target="#viewattendee" content="View / Edit Attendee" />
                                <Tooltip target="#mailto" content="Mail To" />
                                <Tooltip target="#callto" content="Call To" />
                                <Tooltip target="#viewattendeerecord" content="View Attendee Record" />
                                <DataTable
                                    size="small"
                                    stripedRows
                                    ref={dt}
                                    value={participantlist}
                                    filterDisplay={"row"}
                                    selectionMode={"checkbox"}
                                    onAllRowsSelect={onAllRowSelect}
                                    lazy
                                    rows={lazyState.current.rows}
                                    onSort={onSort}
                                    sortField={lazyState.current.sortField}
                                    sortOrder={lazyState.current.sortOrder}
                                    removableSort
                                    selection={selectedParticipant}
                                    onSelectionChange={(e) => setSelectedParticipant(e.value)}
                                    onRowSelect={onRowSelect}
                                    onRowUnselect={onRowUnselect}
                                    onAllRowsUnselect={onAllRowUnSelect}
                                    dataKey="id"
                                    responsiveLayout="scroll"
                                    scrollable
                                    scrollHeight="calc(100vh - 250px)"
                                >
                                    <Column body={ViewAttendeeIcon} frozen exportable={true} headerStyle={{ width: "0%", minWidth: "2rem" }}></Column>
                                    <Column frozen selectionMode="multiple" headerStyle={{ width: "0%", minWidth: "1rem" }}></Column>
                                    <Column body={ViewAttendees} sortField="FullName" frozen field="fullName" header="Name" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={UserFilterTemplate}></Column>
                                    <Column field="inBatchBooking" header="In Batch" sortField="InBatchBooking" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={InBatchFilterTemplate}></Column>

                                    <Column field="outBatchBooking" sortField="OutBatchBooking" sortable header="Out Batch" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={OutBatchFilterTemplate}></Column>
                                    <Column field="company" header="Company" sortField="company" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate}></Column>
                                    <Column body={ButtonMailto} header="Email" sortField="Email" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={EmailFilterTemplate}></Column>
                                    <Column body={ButtonPhoneto} header="Mobile No" sortField="MobileNo" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={MobileFilterTemplate}></Column>
                                    <Column field="cardNo" header="Card No" sortField="CardNo" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={CardNoFilterTemplate}></Column>
                                    <Column field="designation" header="Designation" sortField="Designation" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={DesignationFilterTemplate}></Column>
                                    <Column field="hotelName" sortField="HotelName" sortable header="Hotel" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={HotelFilterTemplate}></Column>
                                    {/* <Column field="gender" header="Gender" sortField="Gender" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={GenderFilterTemplate} ></Column> */}
                                    {/* <Column field="cityname" header="City" sortField="cityname" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={CityFilterTemplate}></Column> */}
                                    {/* <Column field="hospitalName" header="Hospital" sortField="HospitalName" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate} ></Column> */}
                                    {hidecolumnComponents}
                                </DataTable>
                                <Paginator
                                    className="justify-content-end"
                                    style={{ marginRight: "15px" }}
                                    first={lazyState.current.first}
                                    rows={lazyState.current.rows}
                                    totalRecords={totalRecords}
                                    template={template2}
                                    rowsPerPageOptions={RowsPerPageDropdown}
                                    onPageChange={onBasicPageChange}
                                ></Paginator>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </AccessControl>
    );
};
export default ICardDetails;
