import React, { useState, useRef, useEffect } from "react";
import { Redirect, useHistory, Link } from "react-router-dom";
import Axios from "axios";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Messages } from "primereact/messages";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import LoadingSpinner from "../src/components/Spinner/LoadingSpinner";
import registernow from "./assets/images/registernow.png";
import TricoConference from "./assets/images/TricoConference.jpg";
import trico2015 from "./assets/images/trico2015.jpg";
import tricologo from "./assets/images/TricoLogo.jpg";

const Login = (props) => {
    const [email, setemail] = useState(null);
    const [ResetEmail, setResetEmail] = useState(null);
    const [RegEndDate, setRegEndDate] = useState(null);
    const [forgotPassworDialog, setforgotPasswordDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState(null);
    const history = useHistory();
    const message = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        getHeaders();
        if (props?.location?.state?.detail == "ResetPassword") {
            toast.current.show({ severity: "success", summary: "Successful", detail: "Your password has been reset successfully", life: 3000 });
        }
    }, []);

    // //Keydown Event
    const _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            GetLoginData(e);
        }
    };

    //Login data
    const GetLoginData = (e) => {
        e.preventDefault();
        if (email.trim() && password.trim()) {
            setIsLoading(true);
            Axios.post("/api/Account/login", {
                Email: email.trim(),
                Password: password.trim(),
                RememberMe: true,
                lockoutOnFailure: false,
            })
                .then((Response) => {
                    localStorage.clear();
                    setIsLoading(false);
                    if (Response.data.userID !== undefined || Response.data.Password !== undefined) {
                        if ((Response.data.userrole == "MR" || Response.data.userrole == "SUBMR") && (new Date() > new Date(RegEndDate))) {
                            history.push("/home/eventclosed");
                        } else {
                            localStorage.setItem("UserID", Response.data.userID);
                            localStorage.setItem("Username", Response.data.firstname);
                            localStorage.setItem("access_token", Response.data.access_token);
                            localStorage.setItem("menu_token", Response.data.menu_token);
                            localStorage.setItem("userRole", Response.data.userrole);
                            localStorage.setItem("Email", Response.data.email);
                            localStorage.setItem("userRoleId", Response.data.roleId);
                        }
                        // history.push('/home/dashboard')
                        if (Response.data.userrole == "ADMIN" || Response.data.userrole == "ITADMIN") {
                            history.push("/home/dashboard");
                        } else if (Response.data.userrole == "MR" && Response.data.company != null) {
                            if (new Date() > new Date(RegEndDate)) {
                                history.push("/home/eventclosed");
                            } else {
                                localStorage.setItem("Company", Response.data.company);
                                localStorage.setItem("CompanyId", Response.data.companyId);
                                history.push("/home/viewallattendees");
                            }
                        } else if (Response.data.userrole == "SUBMR" && Response.data.company != null) {
                            if (new Date() > new Date(RegEndDate)) {
                                history.push("/home/eventclosed");
                            } else {
                                localStorage.setItem("Company", Response.data.company);
                                localStorage.setItem("CompanyId", Response.data.companyId);
                                history.push("/home/viewallattendees");
                            }
                        } else if (Response.data.userrole == "DOCTOR") {
                            // if (new Date() > new Date(RegEndDate)) {
                                // history.push("/home/eventclosed");
                            // } else {
                                history.push("/home/doctorview");
                            // }
                        } else if (Response.data.userrole == "TRAVELAGENT") {
                            history.push("/home/booktickets");
                        } else {
                            history.push("/home/qrscanner");
                        }
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                    toast.current.show({ severity: "error", detail: "Invalid Username or Password", life: 4000 });
                });
        } else {
            toast.current.show({ severity: "error", detail: "Invalid Username or Password", life: 4000 });
        }
    };

    const getHeaders = () => {
        fetch("config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setRegEndDate(data[4].RegistrationendDt);
            });
    };

    const Register = () => {
        localStorage.clear();
        history.push("/home/addparticipant");
    };

    const ForgotPassword = () => {
        if (ResetEmail) {
            setIsLoading(true);
            Axios.post("/api/Account/ForgotPassword", { Email: ResetEmail.trim().toUpperCase() })
                .then((Response) => {
                    setIsLoading(false);
                    setforgotPasswordDialog(false);
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Forgot Password Mail Sent To : " + ResetEmail, life: 3000 });
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.current.show({ severity: "error", summary: "Error", detail: "Entered Email Does Not Exist !!", life: 3000 });
                });
        }
        else{
            toast.current.show({ severity: "error", summary: "Error", detail: "Please Enter Appropriate E-Mail", life: 3000 });
        }
    };

    const productDialogFooter = (
        <>
            <Button label="Save" icon="fa fa-check" style={{ fontSize: "1.3rem" }} outlined onClick={() => ForgotPassword()} />
            <Button label="Cancel" icon="fa fa-times" style={{ fontSize: "1.3rem" }} outlined onClick={() => setforgotPasswordDialog(false)} />
        </>
    );

    return (
        // <div>
        //     <Toast ref={toast} />
        //     {isLoading ? <LoadingSpinner /> :
        //         (
        //             <>
        //                 <div className="surface-ground  min-h-screen min-w-screen overflow-hidden" >
        //                     <div className="flex flex-column align-items-center justify-content-center" style={{ padding: '6rem' }} >
        //                         <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, rgba(42, 102, 151, 1) 10%, rgba(33, 150, 243, 0) 30%)' }}>
        //                             <div className="w-full surface-card py-5 px-5 sm:px-8" style={{ borderRadius: '53px', paddingBottom: '6rem', marginTop: '0.3rem', paddingRight: '3.5rem', paddingLeft: '3.5rem' }}>
        //                                 <div className="text-center mb-5 mt-0 ">
        //                                     {/* <img src="assets/layout/images/Trico.png" ></img> */}
        //                                     <img src="assets/layout/images/tricoguru-logo-3-1.png" ></img>
        //                                     <div className="text-900 text-3xl font-medium mb-3"></div>
        //                                     <Messages ref={message} />
        //                                 </div>
        //                                 <div>
        //                                     <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
        //                                         Email
        //                                     </label>
        //                                     <InputText value={email || ''} type="text" placeholder="Enter Email" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} onChange={(e) => setemail(e.target.value)} />

        //                                     <label htmlFor="password1" className="block text-900 font-medium text-xl mb-2">
        //                                         Password
        //                                     </label>
        //                                     <Password inputid="password1" value={password || ''} placeholder="Enter Password" toggleMask className="w-full mb-5" inputClassName='w-full p-3 md:w-30rem' feedback={false} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => _handleKeyDown(e)}></Password>
        //                                     <div className="flex align-items-center justify-content-between mb-5 gap-5">
        //                                         <div className="flex align-items-center">
        //                                             {/* <Checkbox inputid="rememberme1" className="mr-2" checked={checked} onChange={(e) => setChecked(e.checked)} ></Checkbox>
        //                             <label htmlFor="rememberme1">
        //                                 Remember me
        //                             </label> */}
        //                                         </div>
        //                                         <a className="font-medium no-underline ml-2 text-right cursor-pointer" onClick={() => setforgotPasswordDialog(true)} style={{ color: 'rgb(42, 102, 151)' }}>
        //                                             Forgot password?
        //                                         </a>
        //                                     </div>
        //                                     <Button label="Login" className="w-full p-3 text-xl" onClick={(e) => GetLoginData(e)} ></Button>
        //                                 </div>
        //                                 <div className="mt-2">
        //                                     {/* <Link to="/home/addparticipant"> <h5>Register Now</h5></Link> */}
        //                                     <Button label="Register Now" className="w-full p-3 text-xl" onClick={(e) => Register(e)}></Button>
        //                                 </div>
        //                                 <div className="mt-4 text-right">
        //                                     <label className="block text-900 font-medium text-xl">
        //                                         Ver. 1.1.12
        //                                     </label>
        //                                 </div>
        //                                 <Dialog visible={forgotPassworDialog} draggable={false} style={{ width: '550px' }}
        //                                     header="FORGOT PASSWORD"
        //                                     modal className="p-fluid" footer={productDialogFooter} onHide={() => setforgotPasswordDialog(false)}>
        //                                     <div className="field">
        //                                         <label id="label" htmlFor="email">Email</label>
        //                                         <label id="Mandatoryfield" >*</label>
        //                                         <InputText maxLength="50" id="name" name="name" value={ResetEmail} onChange={(e) => setResetEmail(e.target.value)} required autoFocus placeholder="Enter Registered Email" />
        //                                     </div>
        //                                 </Dialog>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </>
        //         )
        //     }
        // </div>

        <div>
            <Toast ref={toast} />
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <div>
                        <div className="main">
                            <div className="banner">
                                <h1 className="bannermain">
                                    <img style={{ width: "13%", marginTop: "-28px" }} className="mr-6" src={tricologo} />
                                    <span style={{ marginLeft: "170px", fontSize: "larger" }}>Welcome to TRICO : 2025</span>
                                </h1>
                            </div>
                            {/* <div className="banner">
                                    <input type={'hidden'} name={'__VIEWSTATEGENERATOR'} id="__VIEWSTATEGENERATOR" value="C2EE9ABB"></input>
                                    <div className="bannermain"> <img style={{ width: "5%" }} className="mr-6" src={trico2015} />
                                        <span id="lblwelcome">Welcome to TRICO: 2025</span></div>
                                </div> */}
                            <div>
                                <div className="bannerBorder"></div>
                                <div className="contentArea">
                                    <div className="welcome-text">
                                        TRICO is a Transradial Intervention course conducted every year by Apex Heart Institute under the leadership of Padma Shri Prof. Tejas Patel. TRICO is considered as one of the premier TRI course in the world. It is an effort to bring together the
                                        interventional cardiologists from all over the world and provide a stage to teach, innovate and improve the transradial technique. The participants, delegates and others can access their account.
                                    </div>
                                    <div className="LoginBox">
                                        <div className="LoginImage" style={{ height: "296px" }}>
                                            <img style={{ width: "100%" }} height="296px" src={TricoConference} />
                                        </div>
                                        <div className="LoginContent">
                                            <h3 className="Login-text">LOGIN</h3>
                                            <span className="welcome-text sm:mb-2 lg:pb-0">Enter your username and password below and click Sign In.</span>
                                            <div className="LoginForm">
                                                <table className="tabledesign" style={{ float: "left", textAlign: "left", paddingBottom: 0 }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ paddingBottom: "3px" }}>
                                                                <span style={{ fontWeight: 600, color: "#646464", fontSize: "14px", borderCollapse: "separate" }}>Username</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <InputText value={email || ""} className="w-8 h-4rem" style={{ fontSize: "1.3rem" }} type="text" onChange={(e) => setemail(e.target.value)} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ paddingBottom: "3px" }}>
                                                                <span style={{ fontWeight: 600, color: "#646464", fontSize: "14px" }}>Password</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Password className="w-8  h-4rem" inputClassName="w-full" inputid="password1" value={password || ""} toggleMask feedback={false} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => _handleKeyDown(e)}></Password>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ paddingTop: "10px" }}>
                                                                <input type="submit" name="btnSignIn" value="Sign In" onClick={(e) => GetLoginData(e)} id="btnSignIn" />
                                                                &nbsp;&nbsp;
                                                                <a id="fgrpwd" className="forgot cursor-pointer" onClick={() => setforgotPasswordDialog(true)}>
                                                                    Forgot your password?{" "}
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="notes flex justify-content-between align-items-center" style={{ marginTop: "16px", verticalAlign: "middle" }}>
                                            <p style={{ padding: 0, margin: 0, width: "690px", fontSize: "small" }}>
                                                Click on Register Now button to register yourself for TRICO: 2025.
                                            </p>
                                            <a className="cursor-pointer" title="Register Now">
                                                <img src={registernow} onClick={(e) => Register(e)} title="Register Now" style={{ border: "none", textDecoration: "none", margin: 0, padding: 0 }} />
                                            </a>
                                        </div>
                                        <div className="notes" style={{ backgroundColor: "#fff", fontSize: "small" }}>
                                            Note: If you are using a shared computer or a computer in a public place, like a library or community center, be sure you close all browser windows and tabs and log out when you are done. This will keep your information secure.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Dialog visible={forgotPassworDialog} draggable={false} style={{ width: "550px" }} header="FORGOT PASSWORD" modal className="p-fluid" footer={productDialogFooter} onHide={() => setforgotPasswordDialog(false)}>
                            <div className="field">
                                <label id="label" htmlFor="email">
                                    Email
                                </label>
                                <label id="Mandatoryfield">*</label>
                                <InputText maxLength="50" id="name" style={{ fontSize: "1.3rem" }} name="name" value={ResetEmail} onChange={(e) => setResetEmail(e.target.value)} required autoFocus placeholder="Enter Registered Email" />
                            </div>
                        </Dialog>
                    </div>
                </>
            )}
        </div>
    );
};
export default Login;
