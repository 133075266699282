import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import * as Designations from "../../enums/Designations";
import * as Accomodations from "../../enums/Accomodations";
import * as Travelmodes from "../../enums/Travelmodes";
import * as RegistrationStatus from "../../enums/RegistrationStatus";
import * as FlightBookingStatus from "../../enums/FlightBookingStatus";
import * as Mealprefrences from "../../enums/MealPrefrences";
import * as smokingprefrences from "../../enums/Smokingprefrences";
import * as Genders from "../../enums/Genders";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Tooltip } from "primereact/tooltip";
import { MultiSelect } from "primereact/multiselect";
import exportData from "../../services/exportToExcelService";
import { Toolbar } from "primereact/toolbar";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import userDetails from "../../services/userTokenDetails";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { ToggleButton } from "primereact/togglebutton";
import { array } from "yup";

const ApprovedAttendees = () => {
    let emptyparticipant = [
        {
            id: "",
            registrationstatus: "",
            travelmode_inbound: "",
            travelmode_outbound: "",
            needaccomodation: "",
            needaccomodationcode: null,
            airlinename: "",
            departuredt: "",
            arrivaldt: "",
            departingfrom: "",
            arrivingat: "",
            fromid: "",
            toid: "",
            mealprefrence: "",
            smokingprefrence: "",
            smokingprefrencecode: null,
            inbatchbooking: "", // For In Flight
            inbatchbookingcode: "", // contain uuid
            flightbookingstatus: "", // For In Flight
            Outbatchbooking: "", // For Out Flight
            Outbatchbookingcode: "", // Contain UUID
            Outflightbookingstatus: "", // For Out Flight
            travelremarks: "",
        },
    ];

    let emptyBatchData = {
            batchid: "",
            batchname: "",
        }

    let emptyParticipantTravel = [
        {
            id: "",
            participantId: "",
            airlineName: "",
            conflightid: "",
            flightNo: "",
            departFrom: "",
            arriveAt: "",
            departureName: "",
            arrivalName: "",
            departureDateTime: "",
            arrivalDateTime: "",
            in_Out: "",
            flightOrder: "",
        },
    ];

    let emptyParticipantTravelOut = [
        {
            id: "",
            participantId: "",
            airlineName: "",
            conflightid: "",
            flightNo: "",
            departFrom: "",
            arriveAt: "",
            departureName: "",
            arrivalName: "",
            departureDateTime: "",
            arrivalDateTime: "",
            in_Out: "",
            flightOrder: "",
        },
    ];

    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [participantdata, setparticipantdata] = useState(emptyparticipant);
    const [participantAssignBatch, setparticipantAssignBatch] = useState(emptyBatchData);
    const [participantTravelDataOut, setParticipantTravelDataOut] = useState(emptyParticipantTravelOut); // For Mapping Purpose
    const [participantTravelData, setParticipantTravelData] = useState(emptyParticipantTravel); // For Mapping Purpose
    const [id, setid] = useState(""); // Setting ParticipantId
    const [attendeeid, setattendeeid] = useState(new Array());
    const [Userid, setUserid] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);
    const [fullName, setfullName] = useState(null);
    const [flight, setflight] = useState([]);
    const [batch, setbatch] = useState([]);
    const [flightno, setflightno] = useState([]);
    const [flightnoOut, setflightnoOut] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setemail] = useState(null);
    const [flightdetails, setflightdetails] = useState([]);
    const [flightdetailsOut, setflightdetailsOut] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [expanddata, setexpanddata] = useState([]);
    const [departurearrivaldate, setdeparturearrivaldate] = useState(null);
    const [boardingcityfilter, setboardingcityfilter] = useState(null);
    const [Cityfilter, setCityfilter] = useState(null);
    const [Statefilter, setStatefilter] = useState(null);
    const [Countryfilter, setCountryfilter] = useState(null);
    const [hospitalNamefilter, sethospitalNamefilter] = useState(null);
    const [companyNameFilter, setcompanyNameFilter] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [checkinbound, setcheckinbound] = useState(false);
    const [checkoutbound, setcheckoutbound] = useState(false);
    const [checkboundboth, setcheckboundboth] = useState(false);
    const [checkneedaccomodation, setcheckneedaccomodation] = useState(false);
    const [filterCardNo, setFilterCardNo] = useState(null);
    const [SerialNumber, setSerialNumber] = useState(null);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        CompanyFilter: null,
        ParticipantNameFilter: null,
        EmailFilter: null,
        MobileNoFilter: null,
        CityFilter: null,
        StateFilter: null,
        CountryFilter: null,
        BoardingCityFilter: null,
        HospitalNameFilter: null,
        Designationfilter: null,
        Toeventcityfilter: null,
        filterCardNo: null,
        Fromeventcityfilter: null,
        Genderfilter: null,
        registeredby: null,
        registeredbynumber: null,
        serialnumber: null,
    }); // For Sorting And Pagination Purpose
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [isExportExcel, setIsExportExcel] = useState(false);
    const [RegisteredName, setRegisteredName] = useState(null);
    const [RegisteredByNumber, setRegisteredByNumber] = useState(null);
    const [AssignBatchDialog, setAssignBatchDialog] = useState(false);

    // Const
    const UserName = localStorage.getItem("Username");
    const date = new Date();
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    var company = localStorage.getItem("Company");
    const CompanyId = localStorage.getItem("CompanyId");
    let orderedSelectedColumns = [];
    let unSelectedColumns = [];
    var usrDetails = userDetails();
    const [NameFrozen, setNameFrozen] = useState(false);

    //Flags
    const [changestatusdialog, setchangestatusdialog] = useState(false); // On Change Dialog
    const [eventcitydialog, seteventcitydialog] = useState(false); // To event City Dialog
    const [submitted, setSubmitted] = useState(false); //Checking Submitted Or Not
    const [IsInFlightDialog, setIsInFlightDialog] = useState(false);
    const [IsOutFlightDialog, setIsOutFlightdialog] = useState(false); //Check dialog open for In or Out Flight

    // On Screen Load
    useEffect(() => {
        getparticipantlist();
        document.documentElement.style.setProperty("overflow", "hidden");
    }, []);

    // API
    // Get All Participants
    const getparticipantlist = (isExportExcel = false, event) => {
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios
            .post("/api/Participant/GetAll", {
                paginationRequired: !isExportExcel,
                SortDirection: lazyState.current.sortOrder,
                PageSize: lazyState.current.rows,
                CurrentPageNumber: event && event.rows ? event.first / event.rows + 1 : lazyState.current.page,
                SortField: event && event.sortField ? event.sortField : "Firstname",
                registrationStatus: "APPROVED",
                CompanyFilter: lazyState.current.CompanyFilter ? lazyState.current.CompanyFilter.trim() : null,
                CompanyWiseFilter: usrDetails.role === "MR" || usrDetails.role === "SUBMR" ? CompanyId : null, // For Mr & Sub Mr Login
                FullName: lazyState.current.ParticipantNameFilter ? lazyState.current.ParticipantNameFilter.trim() : null,
                Email: lazyState.current.EmailFilter ? lazyState.current.EmailFilter.trim() : null,
                MobileNo: lazyState.current.MobileNoFilter ? lazyState.current.MobileNoFilter.trim() : null,
                Designation: lazyState.current.Designationfilter ? lazyState.current.Designationfilter.name.trim() : null,
                CardNo: lazyState.current.filterCardNo ? lazyState.current.filterCardNo.trim() : null,
                Gender: lazyState.current.Genderfilter ? lazyState.current.Genderfilter.name.trim() : null,
                ToEventCity: lazyState.current.Toeventcityfilter ? lazyState.current.Toeventcityfilter.code.trim() : null,
                FromEventCity: lazyState.current.Fromeventcityfilter ? lazyState.current.Fromeventcityfilter.code.trim() : null,
                City: lazyState.current.CityFilter ? lazyState.current.CityFilter.trim() : null,
                State: lazyState.current.StateFilter ? lazyState.current.StateFilter.trim() : null,
                Country: lazyState.current.CountryFilter ? lazyState.current.CountryFilter.trim() : null,
                BoardingCity: lazyState.current.BoardingCityFilter ? lazyState.current.BoardingCityFilter.trim() : null,
                HospitalName: lazyState.current.HospitalNameFilter ? lazyState.current.HospitalNameFilter.trim() : null,
                RegisteredByPhone: lazyState.current.registeredbynumber ? lazyState.current.registeredbynumber.trim() : null,
                UserRegisteredBy: lazyState.current.registeredby ? lazyState.current.registeredby.trim() : null,
                SerialNumber: lazyState.current.serialnumber ? lazyState.current.serialnumber.trim() : null,
            })
            .then((Response) => {
                if (!isExportExcel) setParticipantlist([]);
                let tempData = [];
                for (let dataObj of Response.data.data) {
                    // Pass body data in excelsheets.
                    let allExcelData;
                    let excelcolumnData = {
                        Id: dataObj.serialNumber,
                        Name: dataObj.fullName,
                        Hospital: dataObj.hospitalName,
                        Company: dataObj.company,
                        Email: dataObj.email,
                        MobileNo: dataObj.mobileNo,
                        INF1FlightNo: dataObj.inFlightNo1,
                        INF1Airline: dataObj.inAirlineName1,
                        INF1DepartFrom: dataObj.inDepartFrom1,
                        INF1DepartureDateTime: dataObj.inDepartureDateTime1 ? moment(dataObj.inDepartureDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        INF1ArriveAt: dataObj.inArriveAt1,
                        INF1ArrivalDateTime: dataObj.inArrivalDateTime1 ? moment(dataObj.inArrivalDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        INF2FlightNo: dataObj.inFlightNo2,
                        INF2Airline: dataObj.inAirlineName2,
                        INF2DepartFrom: dataObj.inDepartFrom2,
                        INF2DepartureDateTime: dataObj.inDepartureDateTime2 ? moment(dataObj.inDepartureDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        INF2ArriveAt: dataObj.inArriveAt2,
                        INF2ArrivalDateTime: dataObj.inArrivalDateTime2 ? moment(dataObj.inArrivalDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        INF3FlightNo: dataObj.inFlightNo3,
                        INF3Airline: dataObj.inAirlineName3,
                        INF3DepartFrom: dataObj.inDepartFrom3,
                        INF3DepartureDateTime: dataObj.inDepartureDateTime3 ? moment(dataObj.inDepartureDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        INF3ArriveAt: dataObj.inArriveAt3,
                        INF3ArrivalDateTime: dataObj.inArrivalDateTime3 ? moment(dataObj.inArrivalDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        INF4FlightNo: dataObj.inFlightNo4,
                        INF4Airline: dataObj.inAirlineName4,
                        INF4DepartFrom: dataObj.inDepartFrom4,
                        INF4DepartureDateTime: dataObj.inDepartureDateTime4 ? moment(dataObj.inDepartureDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        INF4ArriveAt: dataObj.inArriveAt4,
                        INF4ArrivalDateTime: dataObj.inArrivalDateTime4 ? moment(dataObj.inArrivalDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        OutBatch: dataObj.outBatchBooking,
                        OutFlightBookingStatus: dataObj.outFlightBookingStatus,
                        OUTF1FlightNo: dataObj.outFlightNo1,
                        OUTF1Airline: dataObj.outAirlineName1,
                        OUTF1DepartFrom: dataObj.outDepartFrom1,
                        OUTF1DepartureDateTime: dataObj.outDepartureDateTime1 ? moment(dataObj.outDepartureDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        OUTF1ArriveAt: dataObj.outArriveAt1,
                        OUTF1ArrivalDateTime: dataObj.outArrivalDateTime1 ? moment(dataObj.outArrivalDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        OUTF2FlightNo: dataObj.outFlightNo2,
                        OUTF2Airline: dataObj.outAirlineName2,
                        OUTF2DepartFrom: dataObj.outDepartFrom2,
                        OUTF2DepartureDateTime: dataObj.outDepartureDateTime2 ? moment(dataObj.outDepartureDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        OUTF2ArriveAt: dataObj.outArriveAt2,
                        OUTF2ArrivalDateTime: dataObj.outArrivalDateTime2 ? moment(dataObj.outArrivalDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        OUTF3FlightNo: dataObj.outFlightNo3,
                        OUTF3Airline: dataObj.outAirlineName3,
                        OUTF3DepartFrom: dataObj.outDepartFrom3,
                        OUTF3DepartureDateTime: dataObj.outDepartureDateTime3 ? moment(dataObj.outDepartureDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        OUTF3ArriveAt: dataObj.outArriveAt3,
                        OUTF3ArrivalDateTime: dataObj.outArrivalDateTime3 ? moment(dataObj.outArrivalDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        OUTF4FlightNo: dataObj.outFlightNo4,
                        OUTF4Airline: dataObj.outAirlineName4,
                        OUTF4DepartFrom: dataObj.outDepartFrom4,
                        OUTF4DepartureDateTime: dataObj.outDepartureDateTime4 ? moment(dataObj.outDepartureDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        OUTF4ArriveAt: dataObj.outArriveAt4,
                        OUTF4ArrivalDateTime: dataObj.outArrivalDateTime4 ? moment(dataObj.outArrivalDateTime4).format("DD/MM/YYYY HH:mm") : "",
                    };
                    if (selectedColumns.length > 0) {
                        for (let selectedColumn of selectedColumns) {
                            let fieldName = selectedColumn.field;
                            let headerName = selectedColumn.header;
                            excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] };
                        }
                        tempData.push(excelcolumnData);
                    } else {
                        allExcelData = {
                            Id: dataObj.serialNumber,
                            Name: dataObj.fullName,
                            Hospital: dataObj.hospitalName,
                            Company: dataObj.company,
                            Email: dataObj.email,
                            MobileNo: dataObj.mobileNo,
                            INF1FlightNo: dataObj.inFlightNo1,
                            INF1Airline: dataObj.inAirlineName1,
                            INF1DepartFrom: dataObj.inDepartFrom1,
                            INF1DepartureDateTime: dataObj.inDepartureDateTime1 ? moment(dataObj.inDepartureDateTime1).format("DD/MM/YYYY HH:mm") : "",
                            INF1ArriveAt: dataObj.inArriveAt1,
                            INF1ArrivalDateTime: dataObj.inArrivalDateTime1 ? moment(dataObj.inArrivalDateTime1).format("DD/MM/YYYY HH:mm") : "",
                            INF2FlightNo: dataObj.inFlightNo2,
                            INF2Airline: dataObj.inAirlineName2,
                            INF2DepartFrom: dataObj.inDepartFrom2,
                            INF2DepartureDateTime: dataObj.inDepartureDateTime2 ? moment(dataObj.inDepartureDateTime2).format("DD/MM/YYYY HH:mm") : "",
                            INF2ArriveAt: dataObj.inArriveAt2,
                            INF2ArrivalDateTime: dataObj.inArrivalDateTime2 ? moment(dataObj.inArrivalDateTime2).format("DD/MM/YYYY HH:mm") : "",
                            INF3FlightNo: dataObj.inFlightNo3,
                            INF3Airline: dataObj.inAirlineName3,
                            INF3DepartFrom: dataObj.inDepartFrom3,
                            INF3DepartureDateTime: dataObj.inDepartureDateTime3 ? moment(dataObj.inDepartureDateTime3).format("DD/MM/YYYY HH:mm") : "",
                            INF3ArriveAt: dataObj.inArriveAt3,
                            INF3ArrivalDateTime: dataObj.inArrivalDateTime3 ? moment(dataObj.inArrivalDateTime3).format("DD/MM/YYYY HH:mm") : "",
                            INF4FlightNo: dataObj.inFlightNo4,
                            INF4Airline: dataObj.inAirlineName4,
                            INF4DepartFrom: dataObj.inDepartFrom4,
                            INF4DepartureDateTime: dataObj.inDepartureDateTime4 ? moment(dataObj.inDepartureDateTime4).format("DD/MM/YYYY HH:mm") : "",
                            INF4ArriveAt: dataObj.inArriveAt4,
                            INF4ArrivalDateTime: dataObj.inArrivalDateTime4 ? moment(dataObj.inArrivalDateTime4).format("DD/MM/YYYY HH:mm") : "",
                            OutBatch: dataObj.outBatchBooking,
                            OutFlightBookingStatus: dataObj.outFlightBookingStatus,
                            OUTF1FlightNo: dataObj.outFlightNo1,
                            OUTF1Airline: dataObj.outAirlineName1,
                            OUTF1DepartFrom: dataObj.outDepartFrom1,
                            OUTF1DepartureDateTime: dataObj.outDepartureDateTime1 ? moment(dataObj.outDepartureDateTime1).format("DD/MM/YYYY HH:mm") : "",
                            OUTF1ArriveAt: dataObj.outArriveAt1,
                            OUTF1ArrivalDateTime: dataObj.outArrivalDateTime1 ? moment(dataObj.outArrivalDateTime1).format("DD/MM/YYYY HH:mm") : "",
                            OUTF2FlightNo: dataObj.outFlightNo2,
                            OUTF2Airline: dataObj.outAirlineName2,
                            OUTF2DepartFrom: dataObj.outDepartFrom2,
                            OUTF2DepartureDateTime: dataObj.outDepartureDateTime2 ? moment(dataObj.outDepartureDateTime2).format("DD/MM/YYYY HH:mm") : "",
                            OUTF2ArriveAt: dataObj.outArriveAt2,
                            OUTF2ArrivalDateTime: dataObj.outArrivalDateTime2 ? moment(dataObj.outArrivalDateTime2).format("DD/MM/YYYY HH:mm") : "",
                            OUTF3FlightNo: dataObj.outFlightNo3,
                            OUTF3Airline: dataObj.outAirlineName3,
                            OUTF3DepartFrom: dataObj.outDepartFrom3,
                            OUTF3DepartureDateTime: dataObj.outDepartureDateTime3 ? moment(dataObj.outDepartureDateTime3).format("DD/MM/YYYY HH:mm") : "",
                            OUTF3ArriveAt: dataObj.outArriveAt3,
                            OUTF3ArrivalDateTime: dataObj.outArrivalDateTime3 ? moment(dataObj.outArrivalDateTime3).format("DD/MM/YYYY HH:mm") : "",
                            OUTF4FlightNo: dataObj.outFlightNo4,
                            OUTF4Airline: dataObj.outAirlineName4,
                            OUTF4DepartFrom: dataObj.outDepartFrom4,
                            OUTF4DepartureDateTime: dataObj.outDepartureDateTime4 ? moment(dataObj.outDepartureDateTime4).format("DD/MM/YYYY HH:mm") : "",
                            OUTF4ArriveAt: dataObj.outArriveAt4,
                            OUTF4ArrivalDateTime: dataObj.outArrivalDateTime4 ? moment(dataObj.outArrivalDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        };
                        tempData.push(allExcelData);
                    }
                }
                if (isExportExcel) {
                    exportData(tempData, "ApprovedAttendees");
                    setIsLoading(false);
                    return false;
                }
                setTotalRecords(Response.data.totalRecords);
                setParticipantlist(Response.data.data);
                getBatchlist();
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // get flight no in dropdown
    const getflight = (e, flightinout) => {
        axios
            .post("/api/flight/GetAll", {
                paginationRequired: false,
                In_Out: flightinout,
            })
            .then((Response) => {
                let data = [];
                for (const dataObj of Response.data.data) {
                    // data.push({ name: `${dataObj.flightNo} (${dataObj.fromCode} - ${dataObj.toCode})`, code: dataObj.flightNo });
                    // data.push({ name: `${dataObj.departcode} - ${dataObj.arrivalcode}`, code: dataObj.flightid });
                    data.push({ name: `${dataObj.flightNo} (${dataObj.boardingcity}(${moment(dataObj.departonTime).format("DD/MM/YYYY HH:mm")}) - ${dataObj.arrivecity}(${moment(dataObj.arriveAtTime).format("DD/MM/YYYY HH:mm")})) `, code: dataObj.flightid });
                }
                setflight(data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // Get All Batch In DropDown
    const getBatchlist = () => {
        axios
            .post("/api/Batch/GetAll", {
                paginationRequired: false,
            })
            .then((Response) => {
                let data = [];
                for (const dataObj of Response.data.data) {
                    data.push({ name: dataObj.name, code: dataObj.id });
                }
                setbatch(data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // get participant Record by id
    const getparticipantbyid = (id) => {
        setIsLoading(true);
        axios
            .get("/api/Participant/GetParticipantById/" + id)
            .then((Response) => {
                setid(id);
                let data = [];
                data.push(Response.data);
                let _participant = data;
                _participant["travelmode_inbound"] = Response.data.travelmode_Inbound || "";
                _participant["travelmode_outbound"] = Response.data.travelmode_Outbound || "";
                _participant["needaccomodation"] = Response.data.accomodation;
                _participant["needaccomodationcode"] = Response.data.needAccomodation;
                _participant["registrationstatus"] = Response.data.registrationStatus || "";
                setparticipantdata(_participant);

                let _batch = data;
                _batch["batchid"] = Response.data.inBatchBookingId;
                _batch["batchname"] = Response.data.inBatchBooking;
                setparticipantAssignBatch(_batch);

                // console.log(Response.data.inBatchBooking.inBatchBookingId);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // Get Participant Assigned Flight Record by Participantid For To Event City
    const GetParticipantFlightRecordByIdForToEventCity = (id) => {
        axios
            .post("/api/ParticipantFlight/GetParticipantFlightRecordById/" + id, {
                paginationRequired: false,
                in_Out: true,
            })
            .then((Response) => {
                console.log(Response);
                if (Response.data != null && Response.data.length > 0) {
                    Response.data.sort((a, b) => (a.flightOrder > b.flightOrder ? 1 : -1));
                }
                let _participant = { ...Response.data };
                if (Response.data.length > 0) {
                    _participant["smokingprefrence"] = Response.data[0].smokingPrefrence;
                    _participant["smokingprefrencecode"] = Response.data[0].smokingPrefrenceCode;
                    _participant["travelremarks"] = Response.data[0].travelRemarks;
                    _participant["inbatchbookingcode"] = Response.data[0].inBatchBooking;
                    _participant["inbatchbooking"] = Response.data[0].inBatchName;
                    _participant["flightbookingstatus"] = Response.data[0].inFlightBookingStatus;
                    _participant["Outbatchbookingcode"] = Response.data[0].outBatchBooking;
                    _participant["Outbatchbooking"] = Response.data[0].outBatchName;
                    _participant["Outflightbookingstatus"] = Response.data[0].outFlightBookingStatus;
                    _participant["mealprefrence"] = Response.data[0].mealPrefrence;
                    setparticipantdata(_participant);
                    setflightno(Response.data[0].flightNo);
                    let travelData = [];
                    let travelFlightData = [];
                    for (const dataObj of Response.data) {
                        travelData.push({
                            participantId: dataObj.participantId,
                            airlineName: dataObj.airlineName,
                            conflightid: dataObj.conflightid,
                            flightNo: dataObj.flightNo,
                            departId: dataObj.departId,
                            arriveId: dataObj.arriveId,
                            departureName: dataObj.departureName,
                            arrivalName: dataObj.arrivalName,
                            departureDateTime: dataObj.departureDateTime,
                            arrivalDateTime: dataObj.arrivalDateTime,
                            in_Out: dataObj.in_Out,
                            flightOrder: dataObj.flightOrder,
                            fromCode: dataObj.fromCode,
                            toCode: dataObj.toCode,
                        });

                        travelFlightData.push({
                            airlineName: dataObj.airlineName,
                            arriveId: dataObj.arriveId,
                            arriveName: dataObj.arrivalName,
                            arrivalDateTime: dataObj.arrivalDateTime,
                            departId: dataObj.departId,
                            departName: dataObj.departureName,
                            departureDateTime: dataObj.departureDateTime,
                            flightNo: dataObj.flightNo,
                            conflightid: dataObj.conflightid,
                            flightOrder: dataObj.flightOrder,
                            In_Out: dataObj.in_Out,
                            Created: date,
                            CreatedBy: UserName,
                            Modified: date,
                            ModifiedBy: UserName,
                        });
                    }
                    setParticipantTravelData(travelData);
                    setflightdetails(travelData);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    //assign batch=
    const Assignbatch = () => {
        setIsLoading(true);
        axios
            .put("/api/Participant/updateparticipantbymultiple", {
                id: attendeeid,
                BatchBooking: participantAssignBatch.batchid,
                OutBatchBooking: participantAssignBatch.batchid,
                ModifiedBy: UserName,
            })
            .then((res) => {
                setAssignBatchDialog(false);
                setparticipantAssignBatch(emptyBatchData);
                setattendeeid([]);
                getparticipantlist(isExportExcel, lazyState.current);
                setIsLoading(false);
                toast.current.show({ severity: "success", summary: "Successful", detail: "Batch Assigned", life: 3000 });
                // document.documentElement.style.setProperty('overflow', 'auto');
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // Get Participant Assigned Flight Record by Participantid For From Event City
    const GetParticipantFlightRecordByIdForFromEventCity = (id) => {
        axios
            .post("/api/ParticipantFlight/GetParticipantFlightRecordById/" + id, {
                paginationRequired: false,
                in_Out: false,
            })
            .then((Response) => {
                if (Response.data != null && Response.data.length > 0) {
                    Response.data.sort((a, b) => (a.flightOrder > b.flightOrder ? 1 : -1));
                }
                let _participant = { ...Response.data };
                if (Response.data.length > 0) {
                    _participant["smokingprefrence"] = Response.data[0].smokingPrefrence;
                    _participant["smokingprefrencecode"] = Response.data[0].smokingPrefrenceCode;
                    _participant["travelremarks"] = Response.data[0].travelRemarks;
                    _participant["inbatchbooking"] = Response.data[0].inBatchName;
                    _participant["inbatchbookingcode"] = Response.data[0].inBatchBooking;
                    _participant["flightbookingstatus"] = Response.data[0].inFlightBookingStatus;
                    _participant["Outbatchbookingcode"] = Response.data[0].outBatchBooking;
                    _participant["Outbatchbooking"] = Response.data[0].outBatchName;
                    _participant["Outflightbookingstatus"] = Response.data[0].outFlightBookingStatus;
                    _participant["mealprefrence"] = Response.data[0].mealPrefrence;
                    setparticipantdata(_participant);
                    setflightnoOut(Response.data[0].flightNo);
                    let travelDataOut = [];
                    let travelFlightDataOut = [];
                    for (const dataObj of Response.data) {
                        travelDataOut.push({
                            participantId: dataObj.participantId,
                            airlineName: dataObj.airlineName,
                            flightNo: dataObj.flightNo,
                            conflightid: dataObj.conflightid,
                            departId: dataObj.departId,
                            arriveId: dataObj.arriveId,
                            departureName: dataObj.departureName,
                            arrivalName: dataObj.arrivalName,
                            departureDateTime: dataObj.departureDateTime,
                            arrivalDateTime: dataObj.arrivalDateTime,
                            in_Out: dataObj.in_Out,
                            flightOrder: dataObj.flightOrder,
                            fromCode: dataObj.fromCode,
                            toCode: dataObj.toCode,
                            CreatedBy: UserName,
                            ModifiedBy: UserName,
                        });

                        travelFlightDataOut.push({
                            airlineName: dataObj.airlineName,
                            arriveId: dataObj.arriveId,
                            arriveName: dataObj.arrivalName,
                            arrivalDateTime: dataObj.arrivalDateTime,
                            departId: dataObj.departId,
                            departName: dataObj.departureName,
                            departureDateTime: dataObj.departureDateTime,
                            flightNo: dataObj.flightNo,
                            conflightid: dataObj.conflightid,
                            flightOrder: dataObj.flightOrder,
                            fromCode: dataObj.fromCode,
                            toCode: dataObj.toCode,
                            In_Out: dataObj.in_Out,
                            Created: date,
                            CreatedBy: UserName,
                            Modified: date,
                            ModifiedBy: UserName,
                        });
                    }
                    setParticipantTravelDataOut(travelDataOut);
                    setflightdetailsOut(travelDataOut);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // Getting Flight Details In Table For Selected Flight
    const getflightdetailsbyid = (flightid, index) => {
        axios
            .get("/api/Flight/GetFlightDetailById/" + flightid)
            .then((Response) => {
                console.log(Response.data);
                if (IsInFlightDialog) {
                    let data = [];
                    data.push(...flightdetails);
                    // data.push(Response.data);
                    if (index < flightdetails.length) {
                        data.splice(index, 1);
                        data.splice(index, 0, Response.data);
                        let _participantFlightdetails = data;
                        _participantFlightdetails[index] = Response.data;
                        // _participantFlightdetails[index].flightOrder = index + 1;
                        _participantFlightdetails[index].flightOrder = Response.data[index].flightOrder;
                        // _participantFlightdetails[index].In_Out = IsInFlightDialog;
                        _participantFlightdetails[index].In_Out = Response.data[index].in_Out;
                        _participantFlightdetails[index].Created = date;
                        _participantFlightdetails[index].CreatedBy = UserName;
                        _participantFlightdetails[index].Modified = date;
                        _participantFlightdetails[index].ModifiedBy = UserName;
                        // setflightdetails(_participantFlightdetails);
                        setflightdetails(Response.data);
                    } else {
                        let updatedResponseData = Response.data.map((item, i) => ({
                            ...item,
                            // flightOrder: i + 1,
                            // In_Out: IsInFlightDialog,
                            flightOrder: Response.data[i].flightOrder,
                            In_Out: Response.data[i].in_Out,
                            CreatedBy: UserName,
                        }));
                        setflightdetails([...updatedResponseData]);
                    }
                } else {
                    let data = [];
                    data.push(...flightdetailsOut);
                    if (index < flightdetailsOut.length) {
                        data.splice(index, 1);
                        data.splice(index, 0, Response.data);
                        let _participantFlightdetails = data;
                        _participantFlightdetails[index] = Response.data;
                        // _participantFlightdetails[index].flightOrder = index + 1;
                        _participantFlightdetails[index].flightOrder = Response.data[index].flightOrder;
                        // _participantFlightdetails[index].In_Out = IsOutFlightDialog;
                        _participantFlightdetails[index].In_Out = Response.data[index].in_Out;
                        _participantFlightdetails[index].Created = date;
                        _participantFlightdetails[index].CreatedBy = UserName;
                        _participantFlightdetails[index].Modified = date;
                        _participantFlightdetails[index].ModifiedBy = UserName;
                        //  setflightdetailsOut(_participantFlightdetails);
                        setflightdetailsOut(Response.data);
                    } else {
                        let updatedResponseData = Response.data.map((item, i) => ({
                            ...item,
                            // flightOrder: i + 1,
                            // In_Out: IsOutFlightDialog,
                            flightOrder: Response.data[i].flightOrder,
                            In_Out: Response.data[i].in_Out,
                            CreatedBy: UserName,
                        }));
                        setflightdetailsOut([...updatedResponseData]);
                    }
                }
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    //Update & Delete flight record after travel mode change
    const UpdateDeleteToeventCityFlightRecord = (id) => {
        if (participantdata.travelmode_inbound != "By Air") {
            if (participantdata[0].inFlightBookingStatus != null && participantdata[0].inBatchBooking != null) {
                axios
                    .put("/api/Participant/updateparticipantbymultiple", {
                        id: id,
                        FlightBookingStatus: "TO BE CANCEL",
                        InBatchBookingforflight: true,
                        ModifiedBy: UserName,
                    })
                    .then((response) => {
                        setchangestatusdialog(false);
                        getparticipantlist(isExportExcel, lazyState.current);
                    });
            }
            if (flightdetails.length > 0) {
                axios
                    .delete("/api/ParticipantFlight/DeleteFlightRecord/" + id + "?In_Out=true")
                    .then((response) => {
                        if ((response.data = true)) {
                            setchangestatusdialog(false);
                            getparticipantlist(isExportExcel, lazyState.current);
                        } else {
                            toast.current.show({ severity: "warn", summary: "Warning", detail: `Status of Travel Mode Is Changed but there is no assigned flights`, life: 3000 });
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
                    });
            }
        }
    };

    const UpdateDeleteFromeventCityFlightRecord = (id) => {
        if (participantdata.travelmode_outbound != "By Air") {
            if (participantdata[0].outFlightBookingStatus != null && participantdata[0].outBatchBooking != null) {
                axios
                    .put("/api/Participant/updateparticipantbymultiple", {
                        id: id,
                        OutFlightBookingStatus: "TO BE CANCEL",
                        OutBatchBookingforflight: true,
                        ModifiedBy: UserName,
                    })
                    .then((response) => {
                        setchangestatusdialog(false);
                        getparticipantlist(isExportExcel, lazyState.current);
                    });
            }
            if (flightdetailsOut.length > 0) {
                axios
                    .delete("/api/ParticipantFlight/DeleteFlightRecord/" + id + "?In_Out=false")
                    .then((response) => {
                        if ((response.data = true)) {
                            setchangestatusdialog(false);
                            getparticipantlist(isExportExcel, lazyState.current);
                        } else {
                            toast.current.show({ severity: "warn", summary: "Warning", detail: `Status of Travel Mode Is Changed but there is no assigned flights`, life: 3000 });
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
                    });
            }
        }
    };

    //Delete hotel record for particular attendee on change of need accomodation
    const DeleteHotelRecord = (id) => {
        if (checkneedaccomodation == true && participantdata.needaccomodationcode == false) {
            axios
                .delete("/api/ParticipantHotel/DeleteHotelRecord/" + id)
                .then((response) => {
                    if (response.data == true) {
                        setchangestatusdialog(false);
                        getparticipantlist(isExportExcel, lazyState.current);
                    } else {
                        toast.current.show({ severity: "warn", summary: "Warning", detail: `Status of Need Accomodation Is Changed to NO But No Hotel Is Assigned To Attendee`, life: 3000 });
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
                });
        }
    };

    //Update Registration Status of participant
    const updatestatus = (id) => {
        setIsLoading(true);
        if (participantdata.registrationstatus == "" && participantdata.travelmode_inbound == "" && participantdata.travelmode_outbound == "" && participantdata.needaccomodation == "") {
            toast.current.show({ severity: "error", summary: "Warning", detail: "Please Select At Least One Field", life: 3000 });
        } else {
            axios
                .put("/api/Participant/updateparticipantbymultiple", {
                    id: id,
                    registrationstatus: participantdata.registrationstatus,
                    Travelmode_Inbound: participantdata.travelmode_inbound,
                    Travelmode_Outbound: participantdata.travelmode_outbound,
                    NeedAccomodation: participantdata.needaccomodationcode,
                    ModifiedBy: UserName,
                })
                .then((res) => {
                    setchangestatusdialog(false);
                    getparticipantlist(isExportExcel, lazyState.current);
                    setIsLoading(false);
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Status Updated", life: 3000 });
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
                });
        }
    };

    // Insert Api For In Flights Record
    const InsertInFlightRecord = () => {
        setSubmitted(true);
        // if (flightno.length > 0) {
        if (flightdetails[0]?.flightNo != null && flightdetails[0]?.flightNo != "" && flightdetails[0]?.flightNo != undefined) {
            if (participantdata.inbatchbookingcode != undefined && participantdata.inbatchbookingcode != "00000000-0000-0000-0000-000000000000") {
                if (participantdata.flightbookingstatus != undefined) {
                    setIsLoading(true);
                    const url = "/api/ParticipantFlight/SavePArticipantFlightRecord";
                    const data = {
                        id: id,
                        travelRemarks: participantdata.travelremarks,
                        smokingPrefrence: participantdata.smokingprefrencecode,
                        mealPrefrence: participantdata.mealprefrence,
                        batchBooking: participantdata.inbatchbookingcode,
                        flightBookingStatus: participantdata.flightbookingstatus,
                        flightDetails: flightdetails,
                    };
                    axios
                        .post(url, data)
                        .then(() => {
                            seteventcitydialog(false);
                            getparticipantlist(isExportExcel, lazyState.current);
                            setIsInFlightDialog(false);
                            setIsLoading(false);
                            toast.current.show({ severity: "success", summary: "Successful", detail: "In Flight Assigned Sucessfully", life: 3000 });
                            // document.documentElement.style.setProperty('overflow', 'auto');
                            seteventcitydialog(false);
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
                        });
                } else {
                    toast.current.show({ severity: "error", summary: "Warning", detail: "Please Select Flight Booking Status", life: 3000 });
                }
            } else {
                toast.current.show({ severity: "error", summary: "Warning", detail: "Please Select Batch", life: 3000 });
            }
        } else {
            toast.current.show({ severity: "error", summary: "Warning", detail: "Please Select Flight", life: 3000 });
        }
    };

    // Insert Api For Out Flights Record
    const InsertOutFlightRecord = () => {
        setSubmitted(true);
        // if (flightdetailsOut.length > 0) {
        if (flightdetailsOut[0]?.flightNo != null && flightdetailsOut[0]?.flightNo != "" && flightdetailsOut[0]?.flightNo != undefined) {
            if (participantdata.Outbatchbookingcode != undefined && participantdata.Outbatchbookingcode != "00000000-0000-0000-0000-000000000000") {
                if (participantdata.Outflightbookingstatus != undefined) {
                    setIsLoading(true);
                    const url = "/api/ParticipantFlight/SavePArticipantFlightRecord";
                    const data = {
                        id: id,
                        travelRemarks: participantdata.travelremarks,
                        smokingPrefrence: participantdata.smokingprefrencecode,
                        mealPrefrence: participantdata.mealprefrence,
                        Outbatchbooking: participantdata.Outbatchbookingcode,
                        Outflightbookingstatus: participantdata.Outflightbookingstatus,
                        flightDetails: flightdetailsOut,
                    };
                    axios
                        .post(url, data)
                        .then(() => {
                            seteventcitydialog(false);
                            getparticipantlist(isExportExcel, lazyState.current);
                            setIsOutFlightdialog(false);
                            setIsLoading(false);
                            toast.current.show({ severity: "success", summary: "Successful", detail: "Out Flight Assigned Successfully", life: 3000 });
                            // document.documentElement.style.setProperty('overflow', 'auto');
                            seteventcitydialog(false);
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
                        });
                } else {
                    toast.current.show({ severity: "error", summary: "Warning", detail: "Please Select Flight Booking Status", life: 3000 });
                }
            } else {
                toast.current.show({ severity: "error", summary: "Warning", detail: "Please Select Batch", life: 3000 });
            }
        } else {
            toast.current.show({ severity: "error", summary: "Warning", detail: "Please Select Flight", life: 3000 });
        }
    };

    const UserFilterTemplate = () => {
        return <InputText id="fullName" value={fullName} placeholder="Enter First Name" onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const BoardingCityFilterTemplate = () => {
        return <InputText id="boardingcityname" value={boardingcityfilter} placeholder="Enter Boarding City" onChange={(e) => setboardingcityfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const CityFilterTemplate = () => {
        return <InputText id="city" value={Cityfilter} placeholder="Enter City" onChange={(e) => setCityfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const StateFilterTemplate = () => {
        return <InputText id="state" value={Statefilter} placeholder="Enter State" onChange={(e) => setStatefilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const RegisteredbyFilterTemplate = () => {
        return <InputText id="registeredby" value={RegisteredName} placeholder="Enter Registeredby" onChange={(e) => setRegisteredName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const RegisteredbyNumberFilterTemplate = () => {
        return <InputText id="registeredbynumber" value={RegisteredByNumber} placeholder="Registeredby Number" onChange={(e) => setRegisteredByNumber(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CountryFilterTemplate = () => {
        return <InputText id="country" value={Countryfilter} placeholder="Enter Country" onChange={(e) => setCountryfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const HospitalFilterTemplate = () => {
        return <InputText id="hospitalname" value={hospitalNamefilter} placeholder="Enter Hospital Name" onChange={(e) => sethospitalNamefilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const CompanyFilterTemplate = () => {
        return <InputText id="company" value={companyNameFilter} placeholder="Enter Company" onChange={(e) => setcompanyNameFilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const EmailFilterTemplate = () => {
        return <InputText id="Email" value={filterEmail} placeholder="Enter Email" onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const MobileFilterTemplate = () => {
        return <InputText id="Mobile" value={filterMobileNo} placeholder="Enter Mobile No." onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const DesignationFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Designationfilter}
                options={Designations.Designation}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Designationfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { Designation: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Designation"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const GenderFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Genderfilter}
                options={Genders.Gender}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Genderfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { Gender: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Gender"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const ToEventCityTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Toeventcityfilter}
                options={Travelmodes.Travelmode}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Toeventcityfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { ToEventCity: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Travel Mode "
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const FromEventCityTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Fromeventcityfilter}
                options={Travelmodes.Travelmode}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Fromeventcityfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { FromEventCity: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Travel Mode"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const CardNoFilterTemplate = () => {
        return <InputText id="CardNo" value={filterCardNo} placeholder="Enter Card No" onChange={(e) => setFilterCardNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const SerialNumberFilterTemplate = () => {
        return <InputText id="serialnumber" value={SerialNumber} placeholder="Sr No." onChange={(e) => setSerialNumber(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "5rem" }} />;
    };

    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.ParticipantNameFilter = e.target.value ? e.target.value : null;
                        break;
                    case "boardingcityname":
                        lazyState.current.BoardingCityFilter = e.target.value ? e.target.value : null;
                        break;
                    case "CardNo":
                        lazyState.current.filterCardNo = e.target.value ? e.target.value : null;
                        break;
                    case "hospitalname":
                        lazyState.current.HospitalNameFilter = e.target.value ? e.target.value : null;
                        break;
                    case "company":
                        lazyState.current.CompanyFilter = e.target.value ? e.target.value : null;
                        break;
                    case "Email":
                        lazyState.current.EmailFilter = e.target.value ? e.target.value : null;
                        break;
                    case "Mobile":
                        lazyState.current.MobileNoFilter = e.target.value ? e.target.value : null;
                        break;
                    case "city":
                        lazyState.current.CityFilter = e.target.value ? e.target.value : null;
                        break;
                    case "state":
                        lazyState.current.StateFilter = e.target.value ? e.target.value : null;
                        break;
                    case "country":
                        lazyState.current.CountryFilter = e.target.value ? e.target.value : null;
                        break;
                    case "registeredby":
                        lazyState.current.registeredby = e.target.value ? e.target.value : null;
                        break;
                    case "registeredbynumber":
                        lazyState.current.registeredbynumber = e.target.value ? e.target.value : null;
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = e.target.value ? e.target.value : null;
                        break;
                }
                getparticipantlist(isExportExcel, lazyState.current);
                setIsLoading(false);
            }
        }
    };

    // For Sorting
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
        setIsLoading(false);
        // };
    };

    //Template for pagination
    const template2 = {
        layout: " CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "19%", textAlign: "center" }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return (
                <span>
                    Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} />
                </span>
            );
        },
    };

    //mail and phone number
    const ButtonMailto = (rowData) => {
        // setViewEmail(rowData.email)
        return (
            <Link
                className="linkcolors"
                id="mailto"
                to="#"
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >
                {rowData.email}
            </Link>
        );
    };

    const ButtonPhoneto = (rowData) => {
        // setAttendeePhNumber(rowData.mobileNo)
        return (
            <Link
                className="linkcolors"
                id="callto"
                to="#"
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >
                {rowData.mobileNo}
            </Link>
        );
    };

    //functions
    // On Hide Dialog for change status
    const hideDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setchangestatusdialog(false);
    };

    // On Hide Dialog for event city
    const hideDialogtoeventcity = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        // seteventcitydialog(false);

        // document.documentElement.style.setProperty('overflow', 'auto')
        seteventcitydialog(false);
        setparticipantdata([]);
        if (IsInFlightDialog) {
            setIsInFlightDialog(false);
            setflightno([]);
            participantTravelData.map((i) => {
                return (i.flightNo = "Select Flight");
            });
            // RemoveFlightDropdown();
        } else {
            setIsOutFlightdialog(false);
            setflightnoOut([]);
            participantTravelDataOut.map((i) => {
                return (i.flightNo = "Select Flight");
            });
            // RemoveFlightDropdown();
        }

        // setflightdetails([]);
        // setflightdetailsOut([]);
        // setParticipantTravelData(emptyParticipantTravel);
        // setParticipantTravelDataOut(emptyParticipantTravelOut);
        // window.location.reload();
    };

    // Open Change status Dialog
    const opendialog = (participant) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setchangestatusdialog(true);
        getparticipantbyid(participant);
    };

    // Open  Event City Dialog
    const openeventcitydialog = (participant) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        seteventcitydialog(true);
    };

    // On Dropdown Change
    const onDropdownChange = (e, name, index = 0) => {
        let _participant = { ...participantdata };
        if (name === "needaccomodation" || name === "smokingprefrence" || name === "flightNo0" || name === "flightNo1" || name === "flightNo2" || name === "flightNo3" || name === "travelmode_inbound" || name === "travelmode_outbound" || name === "inbatchbooking" || name === "Outbatchbooking") {
            if (name === "needaccomodation") {
                _participant["needaccomodation"] = e.target && e.target.value.name;
                _participant["needaccomodationcode"] = e.target && e.target.value.code;
                setparticipantdata(_participant);
            }
            if (name === "smokingprefrence") {
                _participant["smokingprefrence"] = e.target && e.target.value.name;
                _participant["smokingprefrencecode"] = e.target && e.target.value.code;
                setparticipantdata(_participant);
            }
            if (name === "flightNo0" || name === "flightNo1" || name === "flightNo2" || name === "flightNo3") {
                var Inflight = participantTravelData.map((item) => {
                    return item.flightNo;
                });
                if (IsInFlightDialog) {
                    var flightnofiltered = Inflight.filter((x) => x == e.target.value.name);
                    if (flightnofiltered.length <= 0) {
                        let _participant = { ...participantTravelData };
                        const val = (e.target && e.target.value.name) || "";
                        _participant[`${name}`] = val;
                        participantTravelData[index].flightNo = val;
                        setParticipantTravelData(participantTravelData);
                        setflightno(e.target.value.code);
                        getflightdetailsbyid(e.target.value.code, index);
                    } else {
                        toast.current.show({ severity: "error", summary: "Error", detail: "You Cannot Select Same Connecting Flight", life: 3000 });
                    }
                } else {
                    var outflight = participantTravelDataOut.map((item) => {
                        return item.flightNo;
                    });
                    var flightnofiltered = outflight.filter((x) => x == e.target.value.name);
                    if (flightnofiltered.length <= 0) {
                        let _participant = { ...participantTravelDataOut };
                        const val = (e.target && e.target.value.name) || "";
                        _participant[`${name}`] = val;
                        participantTravelDataOut[index].flightNo = val;
                        setParticipantTravelDataOut(participantTravelDataOut);
                        setflightnoOut(e.target.value.code);
                        getflightdetailsbyid(e.target.value.code, index);
                    } else {
                        toast.current.show({ severity: "error", summary: "Error", detail: "You Cannot Select Same Connecting Flight", life: 3000 });
                    }
                }
            }
            if (name === "inbatchbooking") {
                _participant["inbatchbookingcode"] = (e.target && e.target.value.code) || "";
                _participant["inbatchbooking"] = (e.target && e.target.value.name) || "";
                setparticipantdata(_participant);
            }
            if (name === "Outbatchbooking") {
                _participant["Outbatchbookingcode"] = (e.target && e.target.value.code) || "";
                _participant["Outbatchbooking"] = (e.target && e.target.value.name) || "";
                setparticipantdata(_participant);
            }
            if (name === "travelmode_inbound") {
                _participant["travelmode_inbound"] = (e.target && e.target.value.code) || "";
                setparticipantdata(_participant);
            }
            if (name === "travelmode_outbound") {
                _participant["travelmode_outbound"] = (e.target && e.target.value.code) || "";
                setparticipantdata(_participant);
            }
            if (name === "travelmode_inbound") {
                setcheckinbound(true);
            }
            if (name === "travelmode_outbound") {
                setcheckoutbound(true);
            }
            if (name === "travelmode_inbound" && name === "travelmode_outbound") {
                setcheckboundboth(true);
            }
            if (name === "needaccomodation") {
                setcheckneedaccomodation(true);
            }
        } else {
            const val = (e.target && e.target.value.name) || "";
            let _participant = { ...participantdata };
            _participant[`${name}`] = val;
            setparticipantdata(_participant);
        }
    };

    // On Input Change
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _participant = { ...participantdata };
        _participant[`${name}`] = val;
        setparticipantdata(_participant);
    };

    const ToeventCity = (rowData) => {
        setid(rowData.id);
        GetParticipantFlightRecordByIdForToEventCity(rowData.id);
        getflight(rowData.boardingcityid, true);
        setIsInFlightDialog(true);
        setIsOutFlightdialog(false);
        openeventcitydialog(rowData);
    };

    const FromeventCity = (rowData) => {
        setid(rowData.id);
        setIsOutFlightdialog(true);
        setIsInFlightDialog(false);
        openeventcitydialog(rowData);
        getflight(rowData.boardingcityid, false);
        GetParticipantFlightRecordByIdForFromEventCity(rowData.id);
    };

    //Buttons
    const actionBodyTemplate = (rowData) => {
        // attendeeid.push(rowData.id);
        // setViewEmail(rowData.email);
        // setAttendeePhNumber(rowData.mobileNo);
        return (
            <div className="actions">
                <AccessControl allowedPermissions={["changestatus:approvedattendees"]}>
                    <Button
                        icon="fa fa-solid fa-retweet"
                        size="lg"
                        className="p-button-rounded mr-2"
                        tooltip="Change Status"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        onClick={() => {
                            opendialog(rowData.id);
                            setid(rowData.id);
                            setemail(rowData.email);
                            setUserid(rowData.userid);
                        }}
                        outlined
                    />
                </AccessControl>
                <AccessControl allowedPermissions={["add:approvedattendees"]}>
                    <Button
                        disabled={rowData.travelmode_Inbound.toLowerCase() == "by air" ? false : true}
                        icon="fa fa-solid  fa-plane fa-rotate-180"
                        className={rowData.travelmode_Inbound.toLowerCase() == "by air" ? "p-button-rounded mr-2" : "hidden"}
                        tooltip="To Event City"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        onClick={() => ToeventCity(rowData)}
                        outlined
                    />
                </AccessControl>
                <AccessControl allowedPermissions={["add:approvedattendees"]}>
                    <Button
                        disabled={rowData.travelmode_Outbound.toLowerCase() == "by air" ? false : true}
                        icon="fa fa-solid  fa-plane fa-rotate-110"
                        className={rowData.travelmode_Outbound.toLowerCase() == "by air" ? "p-button-rounded mr-2" : "hidden"}
                        tooltip="From Event City"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        onClick={() => FromeventCity(rowData)}
                        outlined
                    />
                </AccessControl>

                <AccessControl allowedPermissions={["add:approvedattendees"]}>
                    <Button
                        disabled={(rowData.travelmode_Inbound.toLowerCase() == "by car" && rowData.travelmode_Outbound.toLowerCase() == "by car") || (rowData.travelmode_Inbound.toLowerCase() == "by train" && rowData.travelmode_Outbound.toLowerCase() == "by train") ? false : true}
                        icon="fa fa-solid  fa-car"
                        className={(rowData.travelmode_Inbound.toLowerCase() == "by car" && rowData.travelmode_Outbound.toLowerCase() == "by car") || (rowData.travelmode_Inbound.toLowerCase() == "by train" && rowData.travelmode_Outbound.toLowerCase() == "by train") ? "p-button-rounded mr-2" : "hidden"}
                        tooltip="Assign Batch"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        onClick={(e) => {
                            getparticipantbyid(rowData.id);
                            setattendeeid(rowData.id);
                            setAssignBatchDialog(true);
                        }}
                        outlined
                    />
                </AccessControl>
            </div>
        );
    };

    //Call api on conditions
    const deleteflighthotel = () => {
        updatestatus(id);
        if (checkneedaccomodation == true && participantdata.needaccomodationcode == false) {
            DeleteHotelRecord(id);
        }
        if (participantdata.travelmode_inbound != "By Air" && checkinbound == true) {
            UpdateDeleteToeventCityFlightRecord(id);
        }
        if (participantdata.travelmode_outbound != "By Air" && checkoutbound == true) {
            UpdateDeleteFromeventCityFlightRecord(id);
        }
        if (participantdata.travelmode_inbound != "By Air" && participantdata.travelmode_outbound != "By Air" && checkboundboth == true) {
            UpdateDeleteToeventCityFlightRecord(id);
            UpdateDeleteFromeventCityFlightRecord(id);
        }
    };

    // Modals Button To Save Changes
    const changestatusfootet = (
        <>
            <Button
                label="Save"
                icon="fa fa-check"
                onClick={() => {
                    //  document.documentElement.style.setProperty('overflow', 'auto');
                    deleteflighthotel();
                }}
                outlined
            />
            <Button
                label="Cancel"
                icon="fa fa-times"
                onClick={() => {
                    // document.documentElement.style.setProperty('overflow', 'auto');
                    setchangestatusdialog(false);
                }}
                outlined
            />
        </>
    );

    // Modals Button To Save Changes
    const eventcityfooter = (
        <>
            <Button
                label="Save"
                icon="fa fa-check"
                onClick={() => {
                    //  document.documentElement.style.setProperty('overflow', 'auto');
                    SaveFlightDetail();
                }}
                outlined
            />
            <Button
                label="Cancel"
                icon="fa fa-times"
                onClick={() => {
                    // document.documentElement.style.setProperty('overflow', 'auto')
                    seteventcitydialog(false);
                    setparticipantdata([]);
                    if (IsInFlightDialog) {
                        setIsInFlightDialog(false);
                        setflightno([]);
                        participantTravelData.map((i) => {
                            return (i.flightNo = "Select Flight");
                        });
                        // RemoveFlightDropdown();
                    } else {
                        setIsOutFlightdialog(false);
                        setflightnoOut([]);
                        participantTravelDataOut.map((i) => {
                            return (i.flightNo = "Select Flight");
                        });
                        // RemoveFlightDropdown();
                    }
                    // window.location.reload();
                }}
                outlined
            />
        </>
    );

    // Check Wheather Saving Record Is For To Event City Or From Event City
    const SaveFlightDetail = () => {
        if (IsInFlightDialog) {
            InsertInFlightRecord();
            // setIsInFlightDialog(false);
        }
        if (IsOutFlightDialog) {
            InsertOutFlightRecord();
            // setIsOutFlightdialog(false);
        }
    };

    const Addflightdropdown = () => {
        if (IsInFlightDialog) {
            setParticipantTravelData([
                ...participantTravelData,
                {
                    id: "",
                    participantId: "",
                    airlineName: "",
                    flightNo: "",
                    departFrom: "",
                    arriveAt: "",
                    departureName: "",
                    arrivalName: "",
                    departureDateTime: "",
                    arrivalDateTime: "",
                    in_Out: "",
                    flightOrder: "",
                },
            ]);
        } else {
            setParticipantTravelDataOut([
                ...participantTravelDataOut,
                {
                    id: "",
                    participantId: "",
                    airlineName: "",
                    flightNo: "",
                    departFrom: "",
                    arriveAt: "",
                    departureName: "",
                    arrivalName: "",
                    departureDateTime: "",
                    arrivalDateTime: "",
                    in_Out: "",
                    flightOrder: "",
                },
            ]);
        }
    };

    const RemoveFlightDropdown = (i) => {
        if (IsInFlightDialog) {
            let formValues = [...participantTravelData];
            formValues.splice(i, formValues.length - 1);
            flightdetails.splice(i, flightdetails.length);
            // setParticipantTravelData(formValues);
            setParticipantTravelData(emptyParticipantTravel);
        } else {
            let formValues = [...participantTravelDataOut];
            formValues.splice(i, formValues.length - 1);
            flightdetailsOut.splice(i, flightdetailsOut.length);
            // setParticipantTravelDataOut(formValues);
            setParticipantTravelDataOut(emptyParticipantTravelOut);
        }
    };

    const GetRowExpand = (e) => {
        axios
            .get("/api/Flight/GetFlightDetailByFlightNo/" + e.data.conflightid)
            .then((Response) => {
                if (IsInFlightDialog) {
                    const Inindex = flightdetails.findIndex((emp) => emp.flightNo === e.data.flightNo);
                    var InFlights = [...flightdetails];
                    e.data.details = [Response.data];
                    InFlights[Inindex] = e.data;
                    setflightdetails(InFlights);
                } else {
                    const OutIndex = flightdetailsOut.findIndex((emp) => emp.flightNo === e.data.flightNo);
                    var OutFlights = [...flightdetailsOut];
                    e.data.details = [Response.data];
                    OutFlights[OutIndex] = e.data;
                    setflightdetailsOut(OutFlights);
                }
            })
            .catch((error) => {
                console.log(error);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    //Row Expansion Data
    const rowExpansionTemplate = (e) => {
        console.log(e);
        return (
            <div>
                <DataTable value={e.details} responsiveLayout="scroll" size="small">
                    <Column field="departureDateTime" body={DepartOnFormate} dateFormat="dd/mm/yyyy" header="Departing On"></Column>
                    <Column field="arrivalDateTime" body={ArriveAtFormate} dateFormat="dd/mm/yyyy" header="Arrival At"></Column>
                </DataTable>
            </div>
        );
    };

    const ViewAttendees = (rowData) => {
        return (
            <AccessControl allowedPermissions={["view:approvedattendees"]}>
                <Link
                    className="linkcolors"
                    id="viewattendee"
                    to="#"
                    onClick={(e) => {
                        history.push("/home/viewparticipant/" + rowData.id);
                    }}
                >
                    {rowData.fullName}
                </Link>
            </AccessControl>
        );
    };

    // //For Formating DateTime
    const DepartOnFormate = (selectedFlight) => {
        if (selectedFlight.departureDateTime == null) {
            return null;
        } else {
            const flight = moment(selectedFlight.departureDateTime).format("DD/MM/YYYY HH:mm");
            setdeparturearrivaldate(flight);
            return flight;
        }
    };

    const ArriveAtFormate = (selectedFlight) => {
        if (selectedFlight.arrivalDateTime == null) {
            return null;
        } else {
            const flight = moment(selectedFlight.arrivalDateTime).format("DD/MM/YYYY HH:mm");
            setdeparturearrivaldate(flight);
            return flight;
        }
    };

    const exportExcel = () => {
        getparticipantlist(true);
    };

    const removeFilter = () => {
        lazyState.current.CompanyFilter = null;
        setcompanyNameFilter(null);
        lazyState.current.ParticipantNameFilter = null;
        setfullName(null);
        lazyState.current.BoardingCityFilter = null;
        setboardingcityfilter(null);
        lazyState.current.CityFilter = null;
        setCityfilter(null);
        lazyState.current.CountryFilter = null;
        setCountryfilter(null);
        lazyState.current.Designationfilter = null;
        lazyState.current.HospitalNameFilter = null;
        sethospitalNamefilter(null);
        lazyState.current.EmailFilter = null;
        setFilterEmail(null);
        lazyState.current.MobileNoFilter = null;
        setFilterMobileNo(null);
        lazyState.current.Genderfilter = null;
        lazyState.current.StateFilter = null;
        setStatefilter(null);
        lazyState.current.Toeventcityfilter = null;
        lazyState.current.Fromeventcityfilter = null;
        lazyState.current.filterCardNo = null;
        setFilterCardNo(null);
        lazyState.current.registeredby = null;
        setRegisteredName(null);
        lazyState.current.registeredbynumber = null;
        setRegisteredByNumber(null);
        lazyState.current.serialnumber = null;
        setSerialNumber(null);
        getparticipantlist(isExportExcel, lazyState.current);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl allowedPermissions={["list:participant"]}>
                    <ToggleButton className="mr-2" checked={NameFrozen} onChange={(e) => setNameFrozen(e.value)} onIcon="pi pi-lock" offIcon="pi pi-lock-open" onLabel="Frozed Name" offLabel="Freeze Name" />
                    <Button
                        disabled={
                            lazyState.current.CompanyFilter == null &&
                            lazyState.current.ParticipantNameFilter == null &&
                            lazyState.current.BoardingCityFilter == null &&
                            lazyState.current.CityFilter == null &&
                            lazyState.current.CountryFilter == null &&
                            lazyState.current.Designationfilter == null &&
                            lazyState.current.HospitalNameFilter == null &&
                            lazyState.current.EmailFilter == null &&
                            lazyState.current.MobileNoFilter == null &&
                            lazyState.current.Genderfilter == null &&
                            lazyState.current.StateFilter == null &&
                            lazyState.current.Toeventcityfilter == null &&
                            lazyState.current.serialnumber == null &&
                            lazyState.current.Fromeventcityfilter == null &&
                            lazyState.current.filterCardNo == null &&
                            lazyState.current.registeredby == null &&
                            lazyState.current.registeredbynumber == null
                                ? true
                                : false
                        }
                        icon="pi pi-filter-slash"
                        className="mr-2"
                        onClick={() => removeFilter()}
                        tooltip="Reset Filter"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        outlined
                    ></Button>
                    {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
                </AccessControl>
                <div className="my-2">{header}</div>
                <AccessControl allowedPermissions={["export:participant"]}>
                    <Button
                        disabled={!(participantlist != null && participantlist.length > 0)}
                        tooltip="Export Excel"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        onClick={() => {
                            exportExcel();
                        }}
                        icon="pi pi-file-excel"
                        className="mr-2"
                        outlined
                    />
                </AccessControl>
            </div>
        );
    };

    //#region Method for Column toggle
    const hidecolumns = [
        { field: "userregisteredby", header: "Registered By" },
        { field: "registeredbynumber", header: "Registered By Contact" },
        { field: "designation", header: "Designation" },
        { field: "gender", header: "Gender" },
        { field: "boardingcityname", header: "BoardingCity" },
        { field: "cityname", header: "City" },
        { field: "statename", header: "State" },
        { field: "countryname", header: "Country" },
        { field: "travelmode_Inbound", header: "Travel In Bound" },
        { field: "travelmode_Outbound", header: "Travel Out Bound" },
        { field: "cardNo", header: "Card No" },
    ];

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        orderedSelectedColumns = hidecolumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        unSelectedColumns = hidecolumns.filter((o) => !selectedColumns.some((i) => i.field === o.field && i.header === o.header));
        unselectedColumnData();
        setSelectedColumns(orderedSelectedColumns);
    };

    const unselectedColumnData = () => {
        let isFiltered = false;
        if (unSelectedColumns) {
            unSelectedColumns.map((col) => {
                switch (col.header) {
                    case "Designation":
                        if (lazyState.current.Designationfilter != null) {
                            lazyState.current.Designationfilter = null;
                            isFiltered = true;
                            return lazyState.current.Designationfilter;
                        }
                        break;
                    case "Gender":
                        if (lazyState.current.Genderfilter != null) {
                            lazyState.current.Genderfilter = null;
                            isFiltered = true;
                            return lazyState.current.Genderfilter;
                        }
                        break;
                    case "BoardingCity":
                        if (lazyState.current.BoardingCityFilter != null) {
                            lazyState.current.BoardingCityFilter = null;
                            isFiltered = true;
                            return setboardingcityfilter(null);
                        }
                        break;
                    case "City":
                        if (lazyState.current.CityFilter != null) {
                            lazyState.current.CityFilter = null;
                            isFiltered = true;
                            return setCityfilter(null);
                        }
                        break;
                    case "State":
                        if (lazyState.current.StateFilter != null) {
                            lazyState.current.StateFilter = null;
                            isFiltered = true;
                            return setStatefilter(null);
                        }
                        break;
                    case "Country":
                        if (lazyState.current.CountryFilter != null) {
                            lazyState.current.CountryFilter = null;
                            isFiltered = true;
                            return setCountryfilter(null);
                        }
                        break;
                    case "Travel In Bound":
                        if (lazyState.current.Toeventcityfilter != null) {
                            lazyState.current.Toeventcityfilter = null;
                            isFiltered = true;
                            return lazyState.current.Toeventcityfilter;
                        }
                        break;
                    case "Travel Out Bound":
                        if (lazyState.current.Fromeventcityfilter != null) {
                            lazyState.current.Fromeventcityfilter = null;
                            isFiltered = true;
                            return lazyState.current.Fromeventcityfilter;
                        }
                        break;
                    case "Registered By":
                        if (lazyState.current.registeredby != null) {
                            lazyState.current.registeredby = null;
                            isFiltered = true;
                            return lazyState.current.registeredby;
                        }
                        break;
                    case "Registered By Contact":
                        if (lazyState.current.registeredbynumber != null) {
                            lazyState.current.registeredbynumber = null;
                            isFiltered = true;
                            return lazyState.current.registeredbynumber;
                        }
                        break;
                }
            });
            if (isFiltered) {
                getparticipantlist(isExportExcel, lazyState.current);
            }
        }
    };

    const header = (
        <div style={{ textAlign: "left", marginRight: "10px" }}>
            <AccessControl allowedPermissions={["list:approvedattendees"]}>
                <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: "20em" }} display="chip" />
            </AccessControl>
        </div>
    );

    const hideColumnsFilterTemplate = (column) => {
        switch (column) {
            case "Designation":
                return DesignationFilterTemplate;
            case "Gender":
                return GenderFilterTemplate;
            case "BoardingCity":
                return BoardingCityFilterTemplate;
            case "City":
                return CityFilterTemplate;
            case "State":
                return StateFilterTemplate;
            case "Travel In Bound":
                return ToEventCityTemplate;
            case "Travel Out Bound":
                return FromEventCityTemplate;
            case "Country":
                return CountryFilterTemplate;
            case "Gender":
                return GenderFilterTemplate;
            case "Card No":
                return CardNoFilterTemplate;
            case "Registered By":
                return RegisteredbyFilterTemplate;
            case "Registered By Contact":
                return RegisteredbyNumberFilterTemplate;
        }
    };

    const sortColumn = {
        Designation: "Designation",
        company: "company",
        boardingcityname: "boardingcityname",
        cityname: "cityname",
        Statename: "Statename",
        Countryname: "Countryname",
        "Travel In Bound": "Travelmode_Inbound",
        "Travel Out Bound": "Travelmode_Outbound",
        Gender: "Gender",
        "Card No": "CardNo",
        "Registered By": "userregisteredby",
        "Registered By Contact": "registeredbynumber",
    };

    const hidecolumnComponents = selectedColumns.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} sortable headerStyle={{ width: "15%", minWidth: "15rem" }} sortField={sortColumn[col.header]} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={hideColumnsFilterTemplate(col.header)} />;
    });

    // Modals Button To assign batch
    const AssignBatchDialogFooter = (
        <>
            <Button label="Save" icon="fa fa-check" onClick={(e) => Assignbatch()} outlined />
            <Button
                label="Cancel"
                icon="fa fa-times"
                onClick={(e) => {
                    setAssignBatchDialog(false);
                    setparticipantAssignBatch(emptyBatchData);
                    setattendeeid([]);
                }}
                outlined
            />
        </>
    );

    const onBatchDropdownChange = (e) => {
        let _batch = { ...participantAssignBatch };
        _batch['batchid'] = (e.target && e.target.value.code) || '';
        _batch['batchname'] = (e.target && e.target.value.name) || '';
        setparticipantAssignBatch(_batch);
    };

    //#endregion

    return (
        <AccessControl allowedPermissions={["list:approvedattendees"]} renderNoAccess={() => <NoAccess />}>
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="p-col">
                            <div className="card">
                                <h5 className="headerfont">
                                    <span>
                                        <i className="fa fa-solid fa-users mr-2"></i>Approved Attendees
                                    </span>
                                </h5>
                                <Toolbar right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                <Tooltip target="#viewattendee" content="View Attendee" />
                                <Tooltip target="#mailto" content="Mail To" />
                                <Tooltip target="#callto" content="Call To" />
                                <DataTable
                                    size="small"
                                    stripedRows
                                    ref={dt}
                                    value={participantlist}
                                    lazy
                                    rows={lazyState.current.rows}
                                    onSort={onSort}
                                    sortField={lazyState.current.sortField}
                                    sortOrder={lazyState.current.sortOrder}
                                    removableSort
                                    selection={participantlist}
                                    onSelectionChange={(e) => setParticipantlist(e.value)}
                                    responsiveLayout="scroll"
                                    scrollable
                                    scrollHeight="calc(100vh - 250px)"
                                    filterDisplay={"row"}
                                >
                                    <Column header="Action" alignFrozen="left" frozen={NameFrozen} body={actionBodyTemplate} exportable={false} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
                                    <Column alignFrozen="left" field="serialNumber" header="Id" sortField="SerialNumber" sortable frozen={NameFrozen} headerStyle={{ width: "3%", minWidth: "5rem" }} filter showFilterMenu={false} filterElement={SerialNumberFilterTemplate} showClearButton></Column>
                                    <Column
                                        body={ViewAttendees}
                                        alignFrozen="left"
                                        frozen={NameFrozen}
                                        sortField="FullName"
                                        field="fullName"
                                        header="Name"
                                        sortable
                                        headerStyle={{ width: "15%", minWidth: "15rem" }}
                                        filter
                                        showFilterMenu={false}
                                        filterElement={UserFilterTemplate}
                                        showClearButton
                                        style={{ overflowWrap: "anywhere" }}
                                    ></Column>
                                    <Column field="hospitalName" sortField="HospitalName" header="Hospital" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate} showClearButton style={{ overflowWrap: "anywhere" }}></Column>
                                    <Column field="company" header="Company" sortField="company" sortable headerStyle={{ width: "25%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} showClearButton style={{ overflowWrap: "anywhere" }}></Column>
                                    <Column body={ButtonMailto} sortField="Email" field="email" header="Email" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={EmailFilterTemplate} style={{ overflowWrap: "anywhere" }}></Column>
                                    <Column body={ButtonPhoneto} sortField="MobileNo" sortable field="mobileNo" header="Mobile No." headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={MobileFilterTemplate} style={{ overflowWrap: "anywhere" }}></Column>
                                    {/* <Column field="cardNo" header="Card No" sortField="CardNo" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={CardNoFilterTemplate}></Column> */}
                                    {hidecolumnComponents}

                                    <Column field="inFlightNo1" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF1 Flight No"></Column>
                                    <Column field="inAirlineName1" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF1 Airline"></Column>
                                    <Column field="inDepartFrom1" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF1 Depart From"></Column>
                                    <Column
                                        field="inDepartureDateTime1"
                                        body={(rowData) => <span>{rowData?.inDepartureDateTime1 ? moment(rowData.inDepartureDateTime1).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF1 Departure DateTime"
                                    ></Column>
                                    <Column field="inArriveAt1" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF1 Arrive At"></Column>
                                    <Column
                                        field="inArrivalDateTime1"
                                        body={(rowData) => <span>{rowData?.inArrivalDateTime1 ? moment(rowData.inArrivalDateTime1).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF1 Arrival DateTime"
                                    ></Column>

                                    <Column field="inFlightNo2" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF2 Flight No"></Column>
                                    <Column field="inAirlineName2" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF2 Airline"></Column>
                                    <Column field="inDepartFrom2" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF2 Depart From"></Column>
                                    <Column
                                        field="inDepartureDateTime2"
                                        body={(rowData) => <span>{rowData?.inDepartureDateTime2 ? moment(rowData.inDepartureDateTime2).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF2 Departure DateTime"
                                    ></Column>
                                    <Column field="inArriveAt2" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF2 Arrive At"></Column>
                                    <Column
                                        field="inArrivalDateTime2"
                                        body={(rowData) => <span>{rowData?.inArrivalDateTime2 ? moment(rowData.inArrivalDateTime2).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF2 Arrival DateTime"
                                    ></Column>

                                    <Column field="inFlightNo3" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF3 Flight No"></Column>
                                    <Column field="inAirlineName3" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF3 Airline"></Column>
                                    <Column field="inDepartFrom3" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF3 Depart From"></Column>
                                    <Column
                                        field="inDepartureDateTime3"
                                        body={(rowData) => <span>{rowData?.inDepartureDateTime3 ? moment(rowData.inDepartureDateTime3).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF3 Departure DateTime"
                                    ></Column>
                                    <Column field="inArriveAt3" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF3 Arrive At"></Column>
                                    <Column
                                        field="inArrivalDateTime3"
                                        body={(rowData) => <span>{rowData?.inArrivalDateTime3 ? moment(rowData.inArrivalDateTime3).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF3 Arrival DateTime"
                                    ></Column>

                                    <Column field="inFlightNo4" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF4 Flight No"></Column>
                                    <Column field="inAirlineName4" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF4 Airline"></Column>
                                    <Column field="inDepartFrom4" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF4 Depart From"></Column>
                                    <Column
                                        field="inDepartureDateTime4"
                                        body={(rowData) => <span>{rowData?.inDepartureDateTime4 ? moment(rowData.inDepartureDateTime4).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF4 Departure DateTime"
                                    ></Column>
                                    <Column field="inArriveAt4" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF4 Arrive At"></Column>
                                    <Column
                                        field="inArrivalDateTime4"
                                        body={(rowData) => <span>{rowData?.inArrivalDateTime4 ? moment(rowData.inArrivalDateTime4).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF4 Arrival DateTime"
                                    ></Column>

                                    <Column field="outFlightNo1" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF1 Flight No"></Column>
                                    <Column field="outAirlineName1" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF1 Airline"></Column>
                                    <Column field="outDepartFrom1" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF1 Depart From"></Column>
                                    <Column
                                        field="outDepartureDateTime1"
                                        body={(rowData) => <span>{rowData?.outDepartureDateTime1 ? moment(rowData.outDepartureDateTime1).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF1 Departure DateTime"
                                    ></Column>
                                    <Column field="outArriveAt1" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF1 Arrive At"></Column>
                                    <Column
                                        field="outArrivalDateTime1"
                                        body={(rowData) => <span>{rowData?.outArrivalDateTime1 ? moment(rowData.outArrivalDateTime1).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF1 Arrival DateTime"
                                    ></Column>

                                    <Column field="outFlightNo2" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF2 Flight No"></Column>
                                    <Column field="outAirlineName2" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF2 Airline"></Column>
                                    <Column field="outDepartFrom2" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF2 Depart From"></Column>
                                    <Column
                                        field="outDepartureDateTime2"
                                        body={(rowData) => <span>{rowData?.outDepartureDateTime2 ? moment(rowData.outDepartureDateTime2).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF2 Departure DateTime"
                                    ></Column>
                                    <Column field="outArriveAt2" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF2 Arrive At"></Column>
                                    <Column
                                        field="outArrivalDateTime2"
                                        body={(rowData) => <span>{rowData?.outArrivalDateTime2 ? moment(rowData.outArrivalDateTime2).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF2 Arrival DateTime"
                                    ></Column>

                                    <Column field="outFlightNo3" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF3 Flight No"></Column>
                                    <Column field="outAirlineName3" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF3 Airline"></Column>
                                    <Column field="outDepartFrom3" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF3 Depart From"></Column>
                                    <Column
                                        field="outDepartureDateTime3"
                                        body={(rowData) => <span>{rowData?.outDepartureDateTime3 ? moment(rowData.outDepartureDateTime3).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF3 Departure DateTime"
                                    ></Column>
                                    <Column field="outArriveAt3" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF3 Arrive At"></Column>
                                    <Column
                                        field="outArrivalDateTime3"
                                        body={(rowData) => <span>{rowData?.outArrivalDateTime3 ? moment(rowData.outArrivalDateTime3).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF3 Arrival DateTime"
                                    ></Column>

                                    <Column field="outFlightNo4" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF4 Flight No"></Column>
                                    <Column field="outAirlineName4" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF4 Airline"></Column>
                                    <Column field="outDepartFrom4" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF4 Depart From"></Column>
                                    <Column
                                        field="outDepartureDateTime4"
                                        body={(rowData) => <span>{rowData?.outDepartureDateTime4 ? moment(rowData.outDepartureDateTime4).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF4 Departure DateTime"
                                    ></Column>
                                    <Column field="outArriveAt4" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF4 Arrive At"></Column>
                                    <Column
                                        field="outArrivalDateTime4"
                                        body={(rowData) => <span>{rowData?.outArrivalDateTime4 ? moment(rowData.outArrivalDateTime4).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF4 Arrival DateTime"
                                    ></Column>
                                </DataTable>
                                <Paginator
                                    className="justify-content-end"
                                    style={{ marginRight: "15px" }}
                                    first={lazyState.current.first}
                                    rows={lazyState.current.rows}
                                    totalRecords={totalRecords}
                                    template={template2}
                                    rowsPerPageOptions={RowsPerPageDropdown}
                                    onPageChange={onBasicPageChange}
                                ></Paginator>

                                <Dialog visible={changestatusdialog} draggable={false} style={{ width: "450px" }} header="Change Status" modal className="p-fluid" footer={changestatusfootet} onHide={hideDialog}>
                                    <div className="field">
                                        <label id="label" htmlFor="registrationstatus">
                                            Registration Status
                                        </label>
                                        <Dropdown
                                            value={participantdata.registrationstatus}
                                            placeholder={participantdata.registrationstatus == "" ? "Select Status" : participantdata.registrationstatus}
                                            required
                                            autoFocus
                                            onChange={(e) => onDropdownChange(e, "registrationstatus")}
                                            options={RegistrationStatus.RegistrationStatus}
                                            optionLabel="name"
                                            className="w-full "
                                        />
                                    </div>
                                    <div className="field">
                                        <label id="label" htmlFor="traveltoeventcity">
                                            Travel Mode (To Event City)
                                        </label>
                                        <Dropdown
                                            value={participantdata.travelmode_inbound}
                                            placeholder={participantdata.travelmode_inbound == "" ? "Select Travel Mode (To Event City)" : participantdata.travelmode_inbound}
                                            required
                                            onChange={(e) => onDropdownChange(e, "travelmode_inbound")}
                                            options={Travelmodes.Travelmode}
                                            optionLabel="name"
                                            className="w-full"
                                        />
                                    </div>
                                    <div className="field">
                                        <label id="label" htmlFor="travelfromeventcity">
                                            Travel Mode (From Event City)
                                        </label>
                                        <Dropdown
                                            value={participantdata.travelmode_outbound}
                                            placeholder={participantdata.travelmode_outbound == "" ? "Select Travel Mode (From Event City)" : participantdata.travelmode_outbound}
                                            required
                                            onChange={(e) => onDropdownChange(e, "travelmode_outbound")}
                                            options={Travelmodes.Travelmode}
                                            optionLabel="name"
                                            className="w-full"
                                        />
                                    </div>
                                    <div className="field">
                                        <label id="label" htmlFor="needaccomodation">
                                            Need Accomodation
                                        </label>
                                        <Dropdown
                                            value={participantdata.needaccomodation}
                                            placeholder={participantdata.needaccomodation == "" ? "Select Accomodation Status" : participantdata.needaccomodation}
                                            required
                                            onChange={(e) => onDropdownChange(e, "needaccomodation")}
                                            options={Accomodations.Accomodation}
                                            optionLabel="name"
                                            className="w-full"
                                        />
                                    </div>
                                </Dialog>
                                <Dialog visible={eventcitydialog} draggable={false} style={{ width: "850px" }} header={IsInFlightDialog ? "To Event City" : "From Event City"} modal className="p-fluid" footer={eventcityfooter} onHide={hideDialogtoeventcity}>
                                    <div className="p-fluid formgrid grid">
                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="Flightbookingstatus">
                                                Flight Booking Status
                                            </label>
                                            <label id="Mandatoryfield">*</label>
                                            <Dropdown
                                                autoFocus
                                                value={IsInFlightDialog ? participantdata.flightbookingstatus : participantdata.Outflightbookingstatus}
                                                placeholder={
                                                    IsInFlightDialog ? (participantdata.flightbookingstatus ? participantdata.flightbookingstatus : "Select Flight Booking Status") : participantdata.Outflightbookingstatus ? participantdata.Outflightbookingstatus : "Select Flight Booking Status"
                                                }
                                                // placeholder={IsInFlightDialog ? participantdata.flightbookingstatus : participantdata.Outflightbookingstatus == '' ? "Select Flight Booking Status" : IsInFlightDialog ? participantdata.flightbookingstatus : participantdata.Outflightbookingstatus}
                                                required
                                                onChange={(e) => onDropdownChange(e, IsInFlightDialog ? "flightbookingstatus" : "Outflightbookingstatus")}
                                                options={FlightBookingStatus.FlightBookingStatus}
                                                optionLabel="name"
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="batchbooking">
                                                Batch Booking
                                            </label>
                                            <label id="Mandatoryfield">*</label>
                                            <Dropdown
                                                value={IsInFlightDialog ? participantdata.inbatchbooking : participantdata.Outbatchbooking}
                                                placeholder={IsInFlightDialog ? (participantdata.inbatchbooking ? participantdata.inbatchbooking : "Select Batch") : participantdata.Outbatchbooking ? participantdata.Outbatchbooking : "Select Batch"}
                                                // placeholder={IsInFlightDialog ? participantdata.inbatchbooking : participantdata.Outbatchbooking == '' ? "Select Batch" : IsInFlightDialog ? participantdata.inbatchbooking : participantdata.Outbatchbooking}
                                                required
                                                onChange={(e) => onDropdownChange(e, IsInFlightDialog ? "inbatchbooking" : "Outbatchbooking")}
                                                options={batch}
                                                optionLabel="name"
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="smokingprefrence">
                                                Smoking Prefrence
                                            </label>
                                            <Dropdown
                                                value={participantdata.smokingprefrence}
                                                placeholder={participantdata.smokingprefrence == null ? "Select Smoking Prefrence" : participantdata.smokingprefrence}
                                                required
                                                onChange={(e) => onDropdownChange(e, "smokingprefrence")}
                                                options={smokingprefrences.smokingprefrence}
                                                optionLabel="name"
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="mealprefrence">
                                                Meal Prefrence
                                            </label>
                                            <Dropdown
                                                value={participantdata.mealprefrence}
                                                placeholder={participantdata.mealprefrence == null ? "Select Meal Prefrence" : participantdata.mealprefrence}
                                                required
                                                onChange={(e) => onDropdownChange(e, "mealprefrence")}
                                                options={Mealprefrences.Mealprefrences}
                                                optionLabel="name"
                                                className="w-full"
                                            />
                                        </div>
                                        <div className="field col-12 md:col-12 sm:col-12">
                                            <label id="label" htmlFor="travelremarks">
                                                Travel Remarks
                                            </label>
                                            <InputTextarea maxLength="400" autoResize value={participantdata.travelremarks} placeholder="Travel Remarks" required onChange={(e) => onInputChange(e, "travelremarks")} rows={5} cols={30} />
                                        </div>
                                    </div>
                                    <div className="p-fluid formgrid grid">
                                        <div className="field col-12 md:col-12 sm:col-12">
                                            <label id="label" htmlFor="SelectFlight">
                                                Select Flight
                                            </label>
                                            <label id="Mandatoryfield">*</label>
                                            <div className={IsInFlightDialog ? "" : "hidden"}>
                                                {/* Flight Dropdown */}
                                                {participantTravelData.map((element, index) => (
                                                    <div className="p-fluid formgrid grid">
                                                        <div className="field col-12 md:col-11 sm:col-12" key={index}>
                                                            <Dropdown
                                                                filter
                                                                value={element.flightNo}
                                                                // disabled={flightdetails[0]?.flightNo ? true : false}
                                                                placeholder={element.flightNo == "" ? "Select Flight" : element.flightNo}
                                                                options={flight}
                                                                required
                                                                onChange={(e) => onDropdownChange(e, "flightNo" + index, index)}
                                                                optionLabel="name"
                                                                className="w-full "
                                                            />
                                                        </div>
                                                        {index === 0 && element.flightNo ? (
                                                            <div className="field col-12 md:col-1 sm:col-12">
                                                                {" "}
                                                                <Button type="button" icon="fa fa-solid fa-times" onClick={() => RemoveFlightDropdown(index)} outlined></Button>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ))}
                                            </div>

                                            <div className={IsOutFlightDialog ? "" : "hidden"}>
                                                {/* Flight Dropdown */}
                                                {participantTravelDataOut.map((element, index) => (
                                                    <div className="p-fluid formgrid grid">
                                                        <div className="field col-12 md:col-11 sm:col-12" key={index}>
                                                            <Dropdown
                                                                filter
                                                                value={element.flightNo}
                                                                placeholder={element.flightNo == "" ? "Select Flight" : element.flightNo}
                                                                options={flight}
                                                                required
                                                                onChange={(e) => onDropdownChange(e, "flightNo" + index, index)}
                                                                optionLabel="name"
                                                                className="w-full "
                                                            />
                                                        </div>
                                                        {index === 0 && element.flightNo ? (
                                                            <div className="field col-12 md:col-1 sm:col-12">
                                                                {" "}
                                                                <Button type="button" icon="fa fa-solid fa-times" onClick={() => RemoveFlightDropdown(index)} outlined></Button>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-fluid formgrid grid">
                                        <div className="field col-12 md:col-12 sm:col-12">
                                            {/* <Button className={IsInFlightDialog ? participantTravelData.length == 4 ? "hidden" : "w-full justify-content-center" : participantTravelDataOut.length == 4 ? "hidden" : "w-full justify-content-center"}
                                                    disabled={IsInFlightDialog ? flightdetails.length == 0 : flightdetailsOut.length == 0}
                                                    icon="fa fa-sharp fa-solid fa-plus" label="Add Connecting Flight" onClick={() => Addflightdropdown()} outlined></Button> */}
                                        </div>
                                    </div>

                                    {/* IsInFlightDialog ? flightno == '' ? "hidden" : "" : flightOutno == '' ? "hidden" : "" */}
                                    <div className={IsInFlightDialog ? (flightno == "" ? "hidden" : "") : flightnoOut == "" ? "hidden" : ""}>
                                        <DataTable value={IsInFlightDialog ? flightdetails : flightdetailsOut} expandedRows={expandedRows} rowExpansionTemplate={(e) => rowExpansionTemplate(e)} onRowExpand={(e) => GetRowExpand(e)} onRowToggle={(e) => setExpandedRows(e.data)} stripedRows>
                                            <Column expander />
                                            <Column header="Flight" body={(data, options) => "Flight - " + (options.rowIndex + 1)} style={{ width: "6rem" }}></Column>
                                            <Column field="flightNo" header="Flight No" style={{ width: "7rem" }}></Column>
                                            <Column field="airlineName" header="Airline"></Column>
                                            <Column field="departureName" header="Departing From"></Column>
                                            <Column field="arrivalName" header="Arriving At"></Column>
                                        </DataTable>
                                    </div>
                                </Dialog>

                                <Dialog
                                    visible={AssignBatchDialog}
                                    draggable={false}
                                    style={{ width: "450px" }}
                                    header="Assign Batch"
                                    modal
                                    className="p-fluid"
                                    footer={AssignBatchDialogFooter}
                                    onHide={() => {
                                        setAssignBatchDialog(false);
                                        setparticipantAssignBatch(emptyBatchData);
                                        setattendeeid([]);
                                    }}
                                >
                                    <div className="field">
                                        <label id="label" htmlFor="batch">
                                            Batch
                                        </label>
                                        <label id="Mandatoryfield">*</label>
                                        <Dropdown value={participantAssignBatch.batchname}
                                         placeholder={participantAssignBatch.batchname != "" ? participantAssignBatch.batchname : "Select Batch"}
                                          required onChange={(e) => onBatchDropdownChange(e)} options={batch} optionLabel="name" className="w-full" />
                                    </div>
                                </Dialog>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </AccessControl>
    );
};

export default ApprovedAttendees;
