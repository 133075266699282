import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import * as Designations from "../../enums/Designations";
import * as FlightBookingStatus from "../../enums/BookingStatus";
import * as Genders from "../../enums/Genders";
import * as Travelmodes from "../../enums/Travelmodes";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import userDetails from "../../services/userTokenDetails";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Tooltip } from "primereact/tooltip";

const CancelledSummary = () => {
    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [id, setid] = useState(new Array());
    const [ActionDisable, setActionDisable] = useState(true);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [fullName, setfullName] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [filterBoardingCity, setFilterBoardingCity] = useState(null);
    const [filterHospitalName, setFilterHospitalName] = useState(null);
    const [filterCompany, setFilterCompany] = useState(null);
    const [filterCity, setFilterCity] = useState(null);
    const [filterState, setFilterState] = useState(null);
    const [filterCountry, setFilterCountry] = useState(null);
    const [filterInBatchBooking, setFilterInBatchBooking] = useState(null);
    const [filterOutBatchBooking, setFilterOutBatchBooking] = useState(null);

    // Const
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    var company = localStorage.getItem("Company");
    const CompanyId = localStorage.getItem("CompanyId");
    var usrDetails = userDetails();

    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: null,
        filterDesignation: null,
        filterGender: null,
        filterToEventCity: null,
        filterFromEventCity: null,
        filterCity: null,
        filterState: null,
        filterCountry: null,
        fullName: null,
        filterHospitalName: null,
        filterEmail: null,
        filterMobileNo: null,
        filterCompany: null,
        filterInFlightBookingStatus: null,
        filterOutFlightBookingStatus: null,
        filterInBatchBooking: null,
        filterOutBatchBooking: null,
        filterTravelRemarks: null,
    }); // For Sorting And Pagination Purpose

    // On Screen Load
    useEffect(() => {
        getparticipantlist();
        document.documentElement.style.setProperty("overflow", "hidden");
    }, []);

    // Get All Participants
    const getparticipantlist = (isExportExcel = false, event) => {
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios
            .post("/api/Participant/GetAll", {
                PaginationRequired: !isExportExcel,
                SortDirection: lazyState.current.sortOrder,
                PageSize: lazyState.current.rows,
                CurrentPageNumber: event && event.rows ? event.first / event.rows + 1 : lazyState.current.page,
                SortField: event && event.sortField ? event.sortField : "Firstname",
                // registrationStatus: "APPROVED",
                CompanyFilter: lazyState.current.filterCompany ? lazyState.current.filterCompany.trim() : null,
                CompanyWiseFilter: usrDetails.role === "MR" || usrDetails.role === "SUBMR" ? CompanyId : null, // For Mr & Sub Mr Login
                FullName: lazyState.current.fullName ? lazyState.current.fullName.trim() : null,
                HospitalName: lazyState.current.filterHospitalName ? lazyState.current.filterHospitalName.trim() : null,
                Email: lazyState.current.filterEmail ? lazyState.current.filterEmail.trim() : null,
                MobileNo: lazyState.current.filterMobileNo ? lazyState.current.filterMobileNo.trim() : null,
                // CompanyFilter: lazyState.current.filterCompany ? lazyState.current.filterCompany : '',
                Designation: lazyState.current.filterDesignation ? lazyState.current.filterDesignation.code.trim() : null,
                Gender: lazyState.current.filterGender ? lazyState.current.filterGender.code.trim() : null,
                BoardingCity: lazyState.current.filterBoardingCity ? lazyState.current.filterBoardingCity.trim() : null,
                ToEventCity: lazyState.current.filterToEventCity ? lazyState.current.filterToEventCity.code.trim() : null,
                FromEventCity: lazyState.current.filterFromEventCity ? lazyState.current.filterFromEventCity.code.trim() : null,
                City: lazyState.current.filterCity ? lazyState.current.filterCity.trim() : null,
                State: lazyState.current.filterState ? lazyState.current.filterState.trim() : null,
                Country: lazyState.current.filterCountry ? lazyState.current.filterCountry.trim() : null,
                TicketOutFlightBookingStatus: lazyState.current.filterOutFlightBookingStatus ? lazyState.current.filterOutFlightBookingStatus.code.trim() : "TO BE CANCEL",
                TicketInFlightBookingStatus: lazyState.current.filterInFlightBookingStatus ? lazyState.current.filterInFlightBookingStatus.code.trim() : "TO BE CANCEL",
                OutBatchBooking: lazyState.current.filterOutBatchBooking ? lazyState.current.filterOutBatchBooking.trim() : null,
                InBatchBooking: lazyState.current.filterInBatchBooking ? lazyState.current.filterInBatchBooking.trim() : null,
                Ticket_Travel_mode_Inbound: "By Air",
                Ticket_Travel_mode_Outbound: "By Air",
            })
            .then((Response) => {
                if (!isExportExcel) setParticipantlist([]);
                let tempData = [];
                if (Response.data.data != null) {
                    for (let dataObj of Response.data.data) {
                        // Pass body data in excelsheets.
                        let allExcelData;
                        let excelcolumnData = {
                            Id: dataObj.serialNumber,
                            Name: dataObj.fullName,
                            InFlightStatus: dataObj.inFlightBookingStatus,
                            OutFlightStatus: dataObj.outFlightBookingStatus,
                            InBatch: dataObj.inBatchBooking,
                            OutBatch: dataObj.outBatchBooking,
                            // Email: dataObj.email,
                            // Company: dataObj.company,
                            // Hospital: dataObj.hospitalName,
                            // "MobileNo": dataObj.mobileNo,
                            // "To Event City": dataObj.travelmode_Inbound,
                            // "From Event City": dataObj.travelmode_Outbound,
                        };
                        if (selectedColumns.length > 0) {
                            for (let selectedColumn of selectedColumns) {
                                let fieldName = selectedColumn.field;
                                let headerName = selectedColumn.header;
                                excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] };
                            }
                            tempData.push(excelcolumnData);
                        } else {
                            allExcelData = {
                                // Email: dataObj.email,
                                Id: dataObj.serialNumber,
                                Name: dataObj.fullName,
                                InFlightStatus: dataObj.inFlightBookingStatus,
                                OutFlightStatus: dataObj.outFlightBookingStatus,
                                InBatch: dataObj.inBatchBooking,
                                OutBatch: dataObj.outBatchBooking,
                                // Company: dataObj.company,
                                // Hospital: dataObj.hospitalName,
                                // "MobileNo": dataObj.mobileNo,
                                // "To Event City": dataObj.travelmode_Inbound,
                                // "From Event City": dataObj.travelmode_Outbound,
                            };
                            tempData.push(allExcelData);
                        }
                    }
                    if (isExportExcel == true) {
                        exportData(tempData, "Cancelled Summary");
                        setIsLoading(false);
                        return false;
                    }
                    setTotalRecords(Response.data.totalRecords);
                    setParticipantlist(Response.data.data);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // For Sorting
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(false, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(false, lazyState.current);
        setIsLoading(false);
        // };
    };

    //Template for pagination
    const template2 = {
        layout: " CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "19%", textAlign: "center" }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return (
                <span>
                    Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} />
                </span>
            );
        },
    };

    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.fullName = e.target.value ? e.target.value : null;
                        break;
                    case "hospital":
                        lazyState.current.filterHospitalName = e.target.value ? e.target.value : null;
                        break;
                    case "company":
                        lazyState.current.filterCompany = e.target.value ? e.target.value : null;
                        break;
                    case "Designation":
                        lazyState.current.filterDesignation = e.target.value ? e.target.value : null;
                        break;
                    case "Email":
                        lazyState.current.filterEmail = e.target.value ? e.target.value : null;
                        break;
                    case "MobileNo":
                        lazyState.current.filterMobileNo = e.target.value ? e.target.value : null;
                        break;
                    // case "city":
                    //     lazyState.current.filterCity = e.target.value ? e.target.value : null
                    //     break;
                    // case "state":
                    //     lazyState.current.filterState = e.target.value ? e.target.value : null
                    //     break;
                    // case "country":
                    //     lazyState.current.filterCountry = e.target.value ? e.target.value : null
                    //     break;
                    // case "ToEventCity":
                    //     lazyState.current.filterToEventCity = e.target.value ? e.target.value : null
                    //     break;
                    // case "FromEventCity":
                    //     lazyState.current.filterFromEventCity = e.target.value ? e.target.value : null
                    //     break;
                    // case "boardingcity":
                    //     lazyState.current.filterBoardingCity = e.target.value ? e.target.value : null
                    //     break;
                    case "outFlightBookingStatus":
                        lazyState.current.filterOutFlightBookingStatus = e.target.value ? e.target.value : null;
                        break;
                    case "inFlightBookingStatus":
                        lazyState.current.filterInFlightBookingStatus = e.target.value ? e.target.value : null;
                        break;
                    case "inBatchBooking":
                        lazyState.current.filterInBatchBooking = e.target.value ? e.target.value : null;
                        break;
                    case "outBatchBooking":
                        lazyState.current.filterOutBatchBooking = e.target.value ? e.target.value : null;
                        break;
                }
                getparticipantlist(false, lazyState.current);
                setIsLoading(false);
            }
        }
    };

    const UserFilterTemplate = () => {
        return <InputText id="fullName" autoFocus value={fullName} placeholder="Enter FirstName" onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const InFlightBookingFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.filterInFlightBookingStatus}
                options={FlightBookingStatus.BookingStatus}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.filterInFlightBookingStatus = e.target.value ? e.target.value : null;
                    getparticipantlist({ InFlightBookingStatus: e.value });
                    e.preventDefault();
                }}
                placeholder="InFlight Status"
                className="p-column-filter"
                style={{ width: "200px" }}
            />
        );
    };

    const OutFlightBookingFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.filterOutFlightBookingStatus}
                options={FlightBookingStatus.BookingStatus}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.filterOutFlightBookingStatus = e.target.value ? e.target.value : null;
                    getparticipantlist({ OutFlightBookingStatus: e.value });
                    e.preventDefault();
                }}
                placeholder="OutFlight Status"
                className="p-column-filter"
                style={{ width: "200px" }}
            />
        );
    };

    const InBatchBookingFilterTemplate = () => {
        return <InputText id="inBatchBooking" value={filterInBatchBooking} placeholder="Enter InBatch Booking" onChange={(e) => setFilterInBatchBooking(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ width: "200px" }} />;
    };

    const OutBatchBookingFilterTemplate = () => {
        return <InputText id="outBatchBooking" value={filterOutBatchBooking} placeholder="Enter OutBatch Booking" onChange={(e) => setFilterOutBatchBooking(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ width: "200px" }} />;
    };

    const DesignationFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.filterDesignation}
                options={Designations.Designation}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.filterDesignation = e.target.value ? e.target.value : null;
                    getparticipantlist();
                    e.preventDefault();
                }}
                placeholder="Select Designation"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const GenderFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.filterGender}
                options={Genders.Gender}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.filterGender = e.target.value ? e.target.value : null;
                    getparticipantlist();
                    e.preventDefault();
                }}
                placeholder="Select Gender"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const ToEventCityTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.filterToEventCity}
                id="ToEventCity"
                options={Travelmodes.Travelmode}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.filterToEventCity = e.target.value ? e.target.value : null;
                    getparticipantlist();
                    e.preventDefault();
                }}
                placeholder="Select TravelMode "
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const FromEventCityTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.filterFromEventCity}
                id="FromEventCity"
                options={Travelmodes.Travelmode}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.filterFromEventCity = e.target.value ? e.target.value : null;
                    getparticipantlist();
                    e.preventDefault();
                }}
                placeholder="Select TravelMode"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const CityTemplate = () => {
        return <InputText id="city" value={filterCity} placeholder="Enter City" onChange={(e) => setFilterCity(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const StateTemplate = () => {
        return <InputText id="state" value={filterState} placeholder="Enter City" onChange={(e) => setFilterState(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CountryTemplate = () => {
        return <InputText id="country" value={filterCountry} placeholder="Enter City" onChange={(e) => setFilterCountry(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const HospitalFilterTemplate = () => {
        return <InputText id="hospital" value={filterHospitalName} placeholder="Enter Hospital" onChange={(e) => setFilterHospitalName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CompanyFilterTemplate = () => {
        return <InputText id="company" value={filterCompany} placeholder="Enter Company" onChange={(e) => setFilterCompany(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const EmailFilterTemplate = () => {
        return <InputText id="Email" value={filterEmail} placeholder="Enter Email" onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const MobileFilterTemplate = () => {
        return <InputText id="MobileNo" value={filterMobileNo} placeholder="Enter Mobile No." onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const BoardingCityFilterTemplate = () => {
        return <InputText id="boardingcity" value={filterBoardingCity} placeholder="Enter Boarding City" onChange={(e) => setFilterBoardingCity(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    //  TICKET CANCELLATION FOR IN, OUT, BOTH OR FULL
    const TicketCancellation = (cancellationFor) => {
        setIsLoading(true);
        axios
            .put("/api/Participant/UpdateMultipleParticipantForCancellationById", {
                id: id.toString(),
                CancellationValue: cancellationFor,
            })
            .then((res) => {
                setIsLoading(false);
                setSelectedParticipant([]);
                setid([]);
                setActionDisable(true);
                toast.current.show({ severity: "success", summary: "Cancelled", detail: "Ticket Cancellation Request Sent To Travel Agent", life: 5000 });
                getparticipantlist(false, lazyState.current);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const ButtonMailto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="mailto"
                to="#"
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >
                {rowData.email}
            </Link>
        );
    };

    const ButtonPhoneto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="callto"
                to="#"
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >
                {rowData.mobileNo}
            </Link>
        );
    };

    const ViewAttendees = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="viewattendee"
                to="#"
                onClick={(e) => {
                    history.push("/home/viewparticipant/" + rowData.id);
                }}
            >
                {rowData.fullName}
            </Link>
        );
    };

    const removeFilter = () => {
        lazyState.current.fullName = null;
        setfullName(null);
        lazyState.current.filterHospitalName = null;
        setFilterHospitalName(null);
        lazyState.current.filterCompany = null;
        setFilterCompany(null);
        lazyState.current.filterDesignation = null;
        lazyState.current.filterGender = null;
        lazyState.current.filterEmail = null;
        setFilterEmail(null);
        lazyState.current.filterMobileNo = null;
        setFilterMobileNo(null);
        lazyState.current.filterCity = null;
        setFilterCity(null);
        lazyState.current.filterState = null;
        setFilterState(null);
        lazyState.current.filterCountry = null;
        setFilterCountry(null);
        lazyState.current.filterBoardingCity = null;
        setFilterBoardingCity(null);
        lazyState.current.filterInBatchBooking = null;
        setFilterInBatchBooking(null);
        lazyState.current.filterOutBatchBooking = null;
        setFilterOutBatchBooking(null);
        lazyState.current.filterToEventCity = null;
        lazyState.current.filterFromEventCity = null;
        lazyState.current.filterOutFlightBookingStatus = null;
        lazyState.current.filterInFlightBookingStatus = null;
        getparticipantlist(false, lazyState.current);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl allowedPermissions={["list:participant"]}>
                    <Button
                        disabled={
                            lazyState.current.fullName == null &&
                            lazyState.current.filterEmail == null &&
                            lazyState.current.filterMobileNo == null &&
                            lazyState.current.filterCompany == null &&
                            lazyState.current.filterDesignation == null &&
                            lazyState.current.filterInFlightBookingStatus == null &&
                            lazyState.current.filterInBatchBooking == null &&
                            lazyState.current.filterOutFlightBookingStatus == null &&
                            lazyState.current.filterOutBatchBooking == null
                                ? true
                                : false
                        }
                        icon="pi pi-filter-slash"
                        className="p-button-outlined mr-2"
                        onClick={() => removeFilter()}
                        tooltip="Reset Filter"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        outlined
                    ></Button>
                    {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
                    <div className="my-2">{header}</div>
                </AccessControl>
                <AccessControl allowedPermissions={["export:participant"]}>
                    <Button
                        disabled={!(participantlist != null && participantlist.length > 0)}
                        tooltip="Export Excel"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        onClick={() => {
                            getparticipantlist(true);
                        }}
                        icon="pi pi-file-excel"
                        className="mr-2"
                        outlined
                    />
                </AccessControl>
            </div>
        );
    };

    //#region Method for Column toggle
    const hidecolumns = [
        // { field: "gender", header: "Gender" },
        // { field: "boardingcityname", header: "Boarding City" },
        // { field: "travelRemarks", header: "Travel Remarks" },
        { field: "company", header: "Company" },
        { field: "hospitalName", header: "HospitalName" },
        { field: "email", header: "Email" },
        { field: "mobileNo", header: "Mobile No." },
        { field: "designation", header: "Designation" },
        // { field: "inFlightBookingStatus", header: "In Flight Booking Status" },
        // { field: "outFlightBookingStatus", header: "Out Flight Booking Status" },
        // { field: "inBatchBooking", header: "In Batch Booking" },
        // { field: "outBatchBooking", header: "Out Batch Booking" },
        // { field: "cityname", header: "City" },
        // { field: "statename", header: "State" },
        // { field: "countryname", header: "Country"},
    ];

    let unSelectedColumns = [];
    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = hidecolumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        unSelectedColumns = hidecolumns.filter((o) => !selectedColumns.some((i) => i.field === o.field && i.header === o.header));
        unselectedColumnData();
        setSelectedColumns(orderedSelectedColumns);
    };

    const unselectedColumnData = () => {
        let isFiltered = false;
        if (unSelectedColumns) {
            unSelectedColumns.map((col) => {
                switch (col.header) {
                    case "Designation":
                        if (lazyState.current.filterDesignation != null) {
                            lazyState.current.filterDesignation = null;
                            isFiltered = true;
                            return lazyState.current.filterDesignation;
                        }
                        break;
                    case "Gender":
                        if (lazyState.current.filterGender != null) {
                            lazyState.current.filterGender = null;
                            isFiltered = true;
                            return lazyState.current.filterGender;
                        }
                        break;
                    // case "Boarding City":
                    //     if(lazyState.current.filterBoardingCity != null){
                    //         lazyState.current.filterBoardingCity = null;
                    //         isFiltered = true;
                    //         return setFilterBoardingCity(null);
                    //     }
                    //     break;
                    // case "City":
                    //     if(lazyState.current.filterCity != null){
                    //         lazyState.current.filterCity = null;
                    //         isFiltered = true;
                    //         return setFilterCity(null);
                    //     }
                    //     break;
                    // case "State":
                    //     if(lazyState.current.filterState != null){
                    //         lazyState.current.filterState = null;
                    //         isFiltered = true;
                    //         return setFilterState(null);
                    //     }
                    //     break;
                    // case "Country":
                    //     if(lazyState.current.filterCountry != null){
                    //         lazyState.current.filterCountry = null;
                    //         isFiltered = true;
                    //         return setFilterCountry(null);
                    //     }
                    //     break;

                    case "Out Flight Booking Status":
                        if (lazyState.current.filterOutFlightBookingStatus != null) {
                            lazyState.current.filterOutFlightBookingStatus = null;
                            isFiltered = true;
                            return lazyState.current.filterOutFlightBookingStatus;
                        }
                        break;
                    case "In Flight Booking Status":
                        if (lazyState.current.filterInFlightBookingStatus != null) {
                            lazyState.current.filterInFlightBookingStatus = null;
                            isFiltered = true;
                            return lazyState.current.filterInFlightBookingStatus;
                        }
                        break;
                    case "In Batch Booking":
                        if (lazyState.current.filterInBatchBooking != null) {
                            lazyState.current.filterInBatchBooking = null;
                            isFiltered = true;
                            return setFilterInBatchBooking(null);
                        }
                        break;
                    case "Out Batch Booking":
                        if (lazyState.current.filterOutBatchBooking != null) {
                            lazyState.current.filterOutBatchBooking = null;
                            isFiltered = true;
                            return setFilterOutBatchBooking(null);
                        }
                        break;
                }
            });
            if (isFiltered) {
                getparticipantlist(false, lazyState.current);
            }
        }
    };

    const header = (
        <div style={{ textAlign: "left", marginRight: "10px" }}>
            <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: "20em" }} display="chip" />
        </div>
    );

    const hideColumnsFilterTemplate = (column) => {
        switch (column) {
            case "Designation":
                return DesignationFilterTemplate;
            case "Company":
                return CompanyFilterTemplate;
            case "HospitalName":
                return HospitalFilterTemplate;
            case "Email":
                return EmailFilterTemplate;
            case "Mobile No.":
                return MobileFilterTemplate;
            // case "Gender":
            //     return GenderFilterTemplate;
            // case "City":
            //     return CityTemplate;
            // case "Boarding City":
            //     return BoardingCityFilterTemplate;
            // case "State":
            //     return StateTemplate;
            // case "Country":
            //     return CountryTemplate;
            // case "Out Flight Booking Status":
            //     return OutFlightBookingFilterTemplate;
            // case "In Flight Booking Status":
            //     return InFlightBookingFilterTemplate;
            // case "In Batch Booking":
            //     return InBatchBookingFilterTemplate;
            // case "Out Batch Booking":
            //     return OutBatchBookingFilterTemplate;
        }
    };

    const sortColumn = {
        // "City" : 'CityId',
        // "Boarding City": 'Boardingcity',
        // "Gender": 'Gender',
        Designation: "Designation",
        Company: "Company",
        HospitalName: "HospitalName",
        Email: "Email",
        "Mobile No.": "MobileNo",
    };

    const hidecolumnComponents = selectedColumns.map((col) => {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                sortable
                headerStyle={{ width: "15%", minWidth: "16rem" }}
                sortField={sortColumn[col.header]}
                filter
                showFilterMenu={false}
                filterElement={hideColumnsFilterTemplate(col.header)}
                body={col.header == "Email" ? ButtonMailto : col.header == "Mobile No." ? ButtonPhoneto : null}
            />
        );
    });

    const ViewAttendeeIcon = (rowData) => {
        return (
            <div className="actions">
                <Link to="#" onClick={(e) => history.push({ pathname: "/home/viewattendeeRecord/" + rowData.id, state: { detail: "TravelSummary" } })}>
                    {" "}
                    <span className={usrDetails?.role == "ADMIN" || usrDetails?.role == "ITADMIN" ? "fa fa-solid fa-eye" : "hidden"} id="viewattendeerecord" style={{ color: " rgb(42, 102, 151)" }}></span>
                </Link>
            </div>
        );
    };
    //#endregion

    return (
        <AccessControl allowedPermissions={["list:participant"]} renderNoAccess={() => <NoAccess />}>
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="p-col">
                            <div className="card">
                                <h5 className="headerfont">
                                    <span>
                                        <i className="fa fa-solid  fa-plane fa-rotate-110 mr-2"></i>Cancelled Summary
                                    </span>
                                </h5>
                                <Toolbar right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                <Tooltip target="#viewattendee" content="View / Edit Attendee" />
                                <Tooltip target="#mailto" content="Mail To" />
                                <Tooltip target="#callto" content="Call To" />
                                <Tooltip target="#viewattendeerecord" content="View Attendee Record" />
                                <DataTable
                                    size="small"
                                    stripedRows
                                    ref={dt}
                                    value={participantlist}
                                    lazy
                                    rows={lazyState.current.rows}
                                    onSort={onSort}
                                    sortField={lazyState.current.sortField}
                                    sortOrder={lazyState.current.sortOrder}
                                    removableSort
                                    scrollable
                                    scrollHeight="calc(100vh - 250px)"
                                    selection={selectedParticipant}
                                    onSelectionChange={(e) => setSelectedParticipant(e.value)}
                                    dataKey="id"
                                    className="datatable-responsive"
                                    responsiveLayout="scroll"
                                    filterDisplay={"row"}
                                >
                                    <Column body={ViewAttendeeIcon} frozen exportable={true} headerStyle={{ width: "0%", minWidth: "2rem" }}></Column>
                                    <Column body={ViewAttendees} frozen sortField="FullName" field="fullName" header="Name" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={UserFilterTemplate} showClearButton></Column>
                                    <Column
                                        field="inFlightBookingStatus"
                                        sortField="InFlightBookingStatus"
                                        header="In Flight Booking Status"
                                        sortable
                                        headerStyle={{ width: "15%", minWidth: "15rem" }}
                                        filter
                                        showFilterMenu={false}
                                        filterElement={InFlightBookingFilterTemplate}
                                        showClearButton
                                    ></Column>
                                    <Column
                                        field="outFlightBookingStatus"
                                        sortField="OutFlightBookingStatus"
                                        header="Out Flight Booking Status"
                                        sortable
                                        headerStyle={{ width: "15%", minWidth: "15rem" }}
                                        filter
                                        showFilterMenu={false}
                                        filterElement={OutFlightBookingFilterTemplate}
                                        showClearButton
                                    ></Column>
                                    <Column field="inBatchBooking" sortField="InBatchBooking" header="In Batch Booking" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={InBatchBookingFilterTemplate} showClearButton></Column>
                                    <Column field="outBatchBooking" sortField="OutBatchBooking" header="Out Batch Booking" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={OutBatchBookingFilterTemplate} showClearButton></Column>
                                    {/* <Column field="company" sortField="Company" header="Company" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate}></Column> */}
                                    {/* <Column field="hospitalName" sortField="HospitalName" header="Hospital" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate}></Column> */}
                                    {/* <Column body={ButtonMailto} sortField="Email" field="email" header="Email" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={EmailFilterTemplate}></Column> */}
                                    {/* <Column body={ButtonPhoneto} sortField="MobileNo" field="mobileNo" header="Mobile No" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={MobileFilterTemplate}></Column> */}
                                    {/* <Column field="travelmode_Inbound" sortField="Travelmode_Inbound" header="To Event City" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={ToEventCityTemplate}></Column> */}
                                    {/* <Column field="travelmode_Outbound" sortField="Travelmode_Outbound" header="From Event City" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={FromEventCityTemplate}></Column>  */}
                                    {hidecolumnComponents}
                                </DataTable>
                                <Paginator
                                    className="justify-content-end"
                                    style={{ marginRight: "15px" }}
                                    first={lazyState.current.first}
                                    rows={lazyState.current.rows}
                                    totalRecords={totalRecords}
                                    template={template2}
                                    rowsPerPageOptions={RowsPerPageDropdown}
                                    onPageChange={onBasicPageChange}
                                ></Paginator>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </AccessControl>
    );
};

export default CancelledSummary;
