import React from 'react';
import '../../src/assets/layout/certificate.css';


const PrintCertificate = (props) => {
    const capitalizeWords = (str) => {
        if (str.length === 0) {
            return ''; // Return empty string for empty input
        }

        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    return (      
        <body>
            <div className={ props.designation == 'DELEGATE' ? 'bgimgdelegate' : 'bgimgfaculty'}>
                <h1 className='bgimgH1'>{capitalizeWords(props.name)}</h1>
            </div>
        </body>
    )
}

export default PrintCertificate;