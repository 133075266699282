import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Paginator } from 'primereact/paginator';
import { Link } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { Tooltip } from 'primereact/tooltip';
import '../../assets/layout/styling.css';
import * as Designations from '../../enums/Designations';
import * as Travelmodes from '../../enums/Travelmodes';
import { MultiSelect } from 'primereact/multiselect';
import exportData from "../../services/exportToExcelService";
import * as Genders from '../../enums/Genders';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { useHistory } from 'react-router-dom';
import userDetails from "../../services/userTokenDetails";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import moment from 'moment';

const SentTickets = () => {

    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [ticketpath, setTicketpath] = useState([])
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    var company = localStorage.getItem('Company');
    var usrDetails = userDetails();
    var token = localStorage.getItem('access_token');
    const [boardingcityfilter, setboardingcityfilter] = useState(null);
    const [fullName, setfullName] = useState(null)
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [filterHospitalName, setFilterHospitalName] = useState(null);
    const [filterCompany, setFilterCompany] = useState(null);
    const UserName = localStorage.getItem("Username");


    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        Designationfilter: null,
        Toeventcityfilter: null,
        Fromeventcityfilter: null,
        Genderfilter: null,
        name: null,
        city: null,
        boardingCity: null,
        state: null,
        country: null,
        email: null,
        filterHospitalName: null,
        filterCompany: null,
        filterMobileNo: null,
    }); // For Sorting And Pagination Purpose
    const [enableFilter, setEnableFilter] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [isExportExcel, setIsExportExcel] = useState(false);
    const [Cityfilter, setCityfilter] = useState(null);
    const [Statefilter, setStatefilter] = useState(null);
    const [Countryfilter, setCountryfilter] = useState(null);
    const [WhatsAppUrlData, setWhatsAppUrlData] = useState(null);
    const [ApiHeaders, setApiHeaders] = useState(null);
    const [WhatsappStatus, setWhatsAppStatus] = useState(null);
    const history = useHistory();
    let orderedSelectedColumns = [];
    let unSelectedColumns = [];

    useEffect(() => {
        getparticipantlist();
        document.documentElement.style.setProperty('overflow', 'hidden');
        getData();
    }, [])

    // Get All Participants
    const getparticipantlist = (isExportExcel = false, event) => {
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios.post('/api/Participant/GetTicketPath', {
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event && event.sortField ? event.sortField : "Firstname",
            HospitalName: lazyState.current.filterHospitalName ? lazyState.current.filterHospitalName.trim() : null,
            CompanyFilter: usrDetails.role === 'MR' ? company : lazyState.current.filterCompany?.trim(),
            FullName: lazyState.current.name ? lazyState.current.name.trim() : null,
            City: lazyState.current.city ? lazyState.current.city.trim() : null,
            BoardingCity: lazyState.current.boardingCity ? lazyState.current.boardingCity.trim() : null,
            State: lazyState.current.state ? lazyState.current.state.trim() : null,
            country: lazyState.current.country ? lazyState.current.country.trim() : null,
            Email: lazyState.current.email ? lazyState.current.email.trim() : null,
            MobileNo: lazyState.current.MobileNo ? lazyState.current.MobileNo.trim() : null,
            Gender: lazyState.current.genderfilter ? lazyState.current.genderfilter.name.trim() : null,
            ToEventCity: lazyState.current.toEventCityFilter ? lazyState.current.toEventCityFilter.code.trim() : null,
            FromEventCity: lazyState.current.fromEventCityFilter ? lazyState.current.fromEventCityFilter.code.trim() : null,
            registrationStatus: 'APPROVED',
            IsTicketBooked: true,
            ApproveToSendParticipant: 'APPROVED',
        }).then(Response => {
            if (!isExportExcel) setParticipantlist([]);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                // Pass body data in excelsheets.
                let allExcelData;
                let excelcolumnData = {
                    Id: dataObj.serialNumber,
                    Name: dataObj.fullName,
                    Company: dataObj.company,
                    Email: dataObj.email,
                    MobileNo: dataObj.mobileNo,
                    BoardingCity: dataObj.boardingcityname,
                    inFlightNo1: dataObj.inFlightNo1,
                    inAirlineName1: dataObj.inAirlineName1,
                    inDepartFrom1: dataObj.inDepartFrom1,
                    inDepartureDateTime1: dataObj.inDepartureDateTime1,
                    inArriveAt1: dataObj.inArriveAt1,
                    inArrivalDateTime1: dataObj.inArrivalDateTime1,
                    inFlightNo2: dataObj.inFlightNo2,
                    inAirlineName2: dataObj.inAirlineName2,
                    inDepartFrom2: dataObj.inDepartFrom2,
                    inDepartureDateTime2: dataObj.inDepartureDateTime2,
                    inArriveAt2: dataObj.inArriveAt2,
                    inArrivalDateTime2: dataObj.inArrivalDateTime2,
                    inFlightNo3: dataObj.inFlightNo3,
                    inAirlineName3: dataObj.inAirlineName3,
                    inDepartFrom3: dataObj.inDepartFrom3,
                    inDepartureDateTime3: dataObj.inDepartureDateTime3,
                    inArriveAt3: dataObj.inArriveAt3,
                    inArrivalDateTime3: dataObj.inArrivalDateTime3,
                    inFlightNo4: dataObj.inFlightNo4,
                    inAirlineName4: dataObj.inAirlineName4,
                    inDepartFrom4: dataObj.inDepartFrom4,
                    inDepartureDateTime4: dataObj.inDepartureDateTime4,
                    inArriveAt4: dataObj.inArriveAt4,
                    inArrivalDateTime4: dataObj.inArrivalDateTime4,
                    outFlightNo1: dataObj.outFlightNo1,
                    outAirlineName1: dataObj.outAirlineName1,
                    outDepartFrom1: dataObj.outDepartFrom1,
                    outDepartureDateTime1: dataObj.outDepartureDateTime1,
                    outArriveAt1: dataObj.outArriveAt1,
                    outArrivalDateTime1: dataObj.outArrivalDateTime1,
                    outFlightNo2: dataObj.outFlightNo2,
                    outAirlineName2: dataObj.outAirlineName2,
                    outDepartFrom2: dataObj.outDepartFrom2,
                    outDepartureDateTime2: dataObj.outDepartureDateTime2,
                    outArriveAt2: dataObj.outArriveAt2,
                    outArrivalDateTime2: dataObj.outArrivalDateTime2,
                    outFlightNo3: dataObj.outFlightNo3,
                    outAirlineName3: dataObj.outAirlineName3,
                    outDepartFrom3: dataObj.outDepartFrom3,
                    outDepartureDateTime3: dataObj.outDepartureDateTime3,
                    outArriveAt3: dataObj.outArriveAt3,
                    outArrivalDateTime3: dataObj.outArrivalDateTime3,
                    outFlightNo4: dataObj.outFlightNo4,
                    outAirlineName4: dataObj.outAirlineName4,
                    outDepartFrom4: dataObj.outDepartFrom4,
                    outDepartureDateTime4: dataObj.outDepartureDateTime4,
                    outArriveAt4: dataObj.outArriveAt4,
                    outArrivalDateTime4: dataObj.outArrivalDateTime4

                    // Hospital: dataObj.hospitalName,
                };
                if (selectedColumns.length > 0) {
                    for (let selectedColumn of selectedColumns) {
                        let fieldName = selectedColumn.field;
                        let headerName = selectedColumn.header;
                        excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                    };
                    tempData.push(excelcolumnData);
                } else {
                    allExcelData = {
                        Id: dataObj.serialNumber,
                        Name: dataObj.fullName,
                        Company: dataObj.company,
                        Email: dataObj.email,
                        MobileNo: dataObj.mobileNo,
                        BoardingCity: dataObj.boardingcityname,
                        inFlightNo1: dataObj.inFlightNo1,
                        inAirlineName1: dataObj.inAirlineName1,
                        inDepartFrom1: dataObj.inDepartFrom1,
                        inDepartureDateTime1: dataObj.inDepartureDateTime1,
                        inArriveAt1: dataObj.inArriveAt1,
                        inArrivalDateTime1: dataObj.inArrivalDateTime1,
                        inFlightNo2: dataObj.inFlightNo2,
                        inAirlineName2: dataObj.inAirlineName2,
                        inDepartFrom2: dataObj.inDepartFrom2,
                        inDepartureDateTime2: dataObj.inDepartureDateTime2,
                        inArriveAt2: dataObj.inArriveAt2,
                        inArrivalDateTime2: dataObj.inArrivalDateTime2,
                        inFlightNo3: dataObj.inFlightNo3,
                        inAirlineName3: dataObj.inAirlineName3,
                        inDepartFrom3: dataObj.inDepartFrom3,
                        inDepartureDateTime3: dataObj.inDepartureDateTime3,
                        inArriveAt3: dataObj.inArriveAt3,
                        inArrivalDateTime3: dataObj.inArrivalDateTime3,
                        inFlightNo4: dataObj.inFlightNo4,
                        inAirlineName4: dataObj.inAirlineName4,
                        inDepartFrom4: dataObj.inDepartFrom4,
                        inDepartureDateTime4: dataObj.inDepartureDateTime4,
                        inArriveAt4: dataObj.inArriveAt4,
                        inArrivalDateTime4: dataObj.inArrivalDateTime4,
                        outFlightNo1: dataObj.outFlightNo1,
                        outAirlineName1: dataObj.outAirlineName1,
                        outDepartFrom1: dataObj.outDepartFrom1,
                        outDepartureDateTime1: dataObj.outDepartureDateTime1,
                        outArriveAt1: dataObj.outArriveAt1,
                        outArrivalDateTime1: dataObj.outArrivalDateTime1,
                        outFlightNo2: dataObj.outFlightNo2,
                        outAirlineName2: dataObj.outAirlineName2,
                        outDepartFrom2: dataObj.outDepartFrom2,
                        outDepartureDateTime2: dataObj.outDepartureDateTime2,
                        outArriveAt2: dataObj.outArriveAt2,
                        outArrivalDateTime2: dataObj.outArrivalDateTime2,
                        outFlightNo3: dataObj.outFlightNo3,
                        outAirlineName3: dataObj.outAirlineName3,
                        outDepartFrom3: dataObj.outDepartFrom3,
                        outDepartureDateTime3: dataObj.outDepartureDateTime3,
                        outArriveAt3: dataObj.outArriveAt3,
                        outArrivalDateTime3: dataObj.outArrivalDateTime3,
                        outFlightNo4: dataObj.outFlightNo4,
                        outAirlineName4: dataObj.outAirlineName4,
                        outDepartFrom4: dataObj.outDepartFrom4,
                        outDepartureDateTime4: dataObj.outDepartureDateTime4,
                        outArriveAt4: dataObj.outArriveAt4,
                        outArrivalDateTime4: dataObj.outArrivalDateTime4

                        // Hospital: dataObj.hospitalName,
                    };
                    tempData.push(allExcelData);
                };
            };
            if (isExportExcel) {
                exportData(tempData, 'BookedTickets');
                setIsLoading(false);
                return false;
            };
            setTotalRecords(Response.data.totalRecords);
            setParticipantlist(Response.data.data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };


    //Resend mail to participant
    const ResendMail = (id, name) => {
        setIsLoading(true);
        axios.get('/api/Participant/Getfilename?id=' + id.toString()).then(Response => {
            setIsLoading(false);
            toast.current.show({ severity: 'success', summary: 'Success', detail: `Mail Sent To ${name}`, life: 3000 });
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    const UserFilterTemplate = () => {
        return (
            <InputText id="fullName" value={fullName} placeholder="Enter FirstName"
                onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const BoardingCityFilterTemplate = () => {
        return (
            <InputText id="boardingcity" value={boardingcityfilter} placeholder="Enter BoardingCity"
                onChange={(e) => setboardingcityfilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const EmailFilterTemplate = () => {
        return (
            <InputText id="Email" value={filterEmail} placeholder="Enter Email"
                onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const HospitalFilterTemplate = () => {
        return (
            <InputText id="hospital" value={filterHospitalName} placeholder="Enter Hospital"
                onChange={(e) => setFilterHospitalName(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const CompanyFilterTemplate = () => {
        return (
            <InputText id="company" value={filterCompany} placeholder="Enter Company"
                onChange={(e) => setFilterCompany(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const MobileFilterTemplate = () => {
        return (
            <InputText id="MobileNo" value={filterMobileNo} placeholder="Enter Mobile No."
                onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />
        );
    };

    const FilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.name = e.target.value ? e.target.value : null
                        break;
                    case "city":
                        lazyState.current.city = e.target.value ? e.target.value : null
                        break;
                    case "state":
                        lazyState.current.state = e.target.value ? e.target.value : null
                        break;
                    case "country":
                        lazyState.current.country = e.target.value ? e.target.value : null
                        break;
                    case "Email":
                        lazyState.current.email = e.target.value ? e.target.value : null
                        break;
                    case "boardingcity":
                        lazyState.current.boardingCity = e.target.value ? e.target.value : null
                        break;
                    case "hospital":
                        lazyState.current.filterHospitalName = e.target.value ? e.target.value : null
                        break;
                    case "company":
                        lazyState.current.filterCompany = e.target.value ? e.target.value : null
                        break;
                    case "MobileNo":
                        lazyState.current.filterMobileNo = e.target.value ? e.target.value : null
                        break;
                };
                getparticipantlist(isExportExcel, lazyState.current);
                setIsLoading(false);
            };
        };
    };

    const DesignationFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Designationfilter} options={Designations.Designation} optionLabel="name" showClear onChange={(e) => { lazyState.current.Designationfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { Designation: e.value }); e.preventDefault() }} placeholder="Select Designation" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const GenderFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Genderfilter} options={Genders.Gender} optionLabel="name" showClear onChange={(e) => { lazyState.current.Genderfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { Gender: e.value }); e.preventDefault() }} placeholder="Select Gender" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const ToEventCityTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Toeventcityfilter} options={Travelmodes.Travelmode} optionLabel="name" showClear onChange={(e) => { lazyState.current.Toeventcityfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { ToEventCity: e.value }); e.preventDefault() }} placeholder="Select TravelMode " className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };


    const FromEventCityTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Fromeventcityfilter} options={Travelmodes.Travelmode} optionLabel="name" showClear onChange={(e) => { lazyState.current.Fromeventcityfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { FromEventCity: e.value }); e.preventDefault() }} placeholder="Select TravelMode" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const CityFilterTemplate = () => {
        return (
            <InputText id="city" value={Cityfilter} placeholder="Enter City"
                onChange={(e) => setCityfilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const StateFilterTemplate = () => {
        return (
            <InputText id="state" value={Statefilter} placeholder="Enter State"
                onChange={(e) => setStatefilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const CountryFilterTemplate = () => {
        return (
            <InputText id="country" value={Countryfilter} placeholder="Enter Country"
                onChange={(e) => setCountryfilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {

        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
        setIsLoading(false);
        // };
    };

    //Template for pagination
    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            )
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    const exportExcel = () => {
        getparticipantlist(true);
    };

    const removeFilter = () => {
        lazyState.current.name = null;
        setfullName(null);
        lazyState.current.email = null;
        setFilterEmail(null);
        lazyState.current.filterMobileNo = null;
        setFilterMobileNo(null);
        lazyState.current.filterHospitalName = null;
        setFilterHospitalName(null);
        lazyState.current.filterCompany = null;
        setFilterCompany(null);
        lazyState.current.boardingCity = null;
        setboardingcityfilter(null);
        lazyState.current.city = null;
        setCityfilter(null);
        lazyState.current.state = null;
        setStatefilter(null);
        lazyState.current.country = null;
        setCountryfilter(null);
        lazyState.current.Genderfilter = null;
        lazyState.current.Toeventcityfilter = null;
        lazyState.current.Fromeventcityfilter = null;
        lazyState.current.Designationfilter = null;
        getparticipantlist(isExportExcel, lazyState.current);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl allowedPermissions={["route:bookedtickets"]}>
                    <Button disabled={lazyState.current.name == null && lazyState.current.email == null && lazyState.current.filterHospitalName == null &&
                        lazyState.current.filterCompany == null && lazyState.current.filterMobileNo == null && lazyState.current.boardingCity == null &&
                        lazyState.current.country == null && lazyState.current.state == null && lazyState.current.city == null && lazyState.current.Designationfilter == null &&
                        lazyState.current.Genderfilter == null && lazyState.current.Toeventcityfilter == null && lazyState.current.Fromeventcityfilter == null ? true : false}
                        icon="pi pi-filter-slash" className="mr-2" onClick={() => removeFilter()} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>
                    {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
                </AccessControl>
                <div className="my-2">{header}</div>
                <AccessControl allowedPermissions={["export:bookedtickets"]}>
                    <Button disabled={!(participantlist != null && participantlist.length > 0)} tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} onClick={() => { exportExcel(); }} icon="pi pi-file-excel" className="mr-2" outlined />
                </AccessControl>
            </div>
        )
    }

    // //Left Toolbar template
    const leftToolbarTemplate = () => {
        return (
                <div className="my-2">
                <h4 className="headerfont m-auto mr-2">Count : {totalRecords}</h4>
                </div>
        )
    };
    //#region Method for Column toggle
    const hidecolumns = [
        { field: "designation", header: "Designation" },
        { field: "gender", header: "Gender" },
        { field: "cityname", header: "City" },
        { field: "statename", header: "State" },
        { field: "countryname", header: "Country" },
        { field: "travelmode_Inbound", header: "Travel In Bound" },
        { field: "travelmode_Outbound", header: "Travel Out Bound" }
    ];

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        orderedSelectedColumns = hidecolumns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        unSelectedColumns = hidecolumns.filter(o => !selectedColumns.some(i => i.field === o.field && i.header === o.header));
        unselectedColumnData();
        setSelectedColumns(orderedSelectedColumns);
    };

    const unselectedColumnData = () => {
        let isFiltered = false;
        if (unSelectedColumns) {
            unSelectedColumns.map(col => {
                switch (col.header) {
                    case "City":
                        if (lazyState.current.city != null) {
                            lazyState.current.city = null;
                            isFiltered = true;
                            return setCityfilter(null);
                        };
                        break;
                    case "State":
                        if (lazyState.current.state != null) {
                            lazyState.current.state = null;
                            isFiltered = true;
                            return setStatefilter(null);
                        };
                        break;
                    case "Country":
                        if (lazyState.current.country != null) {
                            lazyState.current.country = null;
                            isFiltered = true;
                            return setCountryfilter(null);
                        };
                        break;
                    case "To Event City":
                        if (lazyState.current.Toeventcityfilter != null) {
                            lazyState.current.Toeventcityfilter = null;
                            isFiltered = true;
                            return lazyState.current.Toeventcityfilter;
                        }
                        break;
                    case "From Event City":
                        if (lazyState.current.Fromeventcityfilter != null) {
                            lazyState.current.Fromeventcityfilter = null;
                            isFiltered = true;
                            return lazyState.current.Fromeventcityfilter;
                        };
                        break;
                    case "Designation":
                        if (lazyState.current.Designationfilter != null) {
                            lazyState.current.Designationfilter = null;
                            isFiltered = true;
                            return lazyState.current.Designationfilter;
                        };
                        break;
                    case "Gender":
                        if (lazyState.current.Genderfilter != null) {
                            lazyState.current.Genderfilter = null;
                            isFiltered = true;
                            return lazyState.current.Genderfilter;
                        };
                        break;
                }
            });
            if (isFiltered) {
                getparticipantlist(isExportExcel, lazyState.current);
            };
        };
    };

    const header = (
        <AccessControl allowedPermissions={["route:bookedtickets"]}>
            <div style={{ textAlign: 'left', marginRight: '10px' }}>
                <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: '20em' }} display="chip" />
            </div>
        </AccessControl>
    );

    const hideColumnsFilterTemplate = (column) => {
        switch (column) {
            case "Designation":
                return DesignationFilterTemplate;
            case "Gender":
                return GenderFilterTemplate;
            case "City":
                return CityFilterTemplate;
            case "State":
                return StateFilterTemplate;
            case "Travel In Bound":
                return ToEventCityTemplate;
            case "Travel Out Bound":
                return FromEventCityTemplate;
            case "Country":
                return CountryFilterTemplate;
        };
    };

    const sortColumn = {
        "Designation": 'Designation',
        "cityname": 'cityname',
        "Statename": 'Statename',
        "Countryname": 'Countryname',
        "Travel In Bound": 'Travelmode_Inbound',
        "Travel Out Bound": 'Travelmode_Outbound',
        "Gender": 'Gender',
    };

    const hidecolumnComponents = selectedColumns.map(col => {
        return <Column key={col.field} field={col.field} header={col.header} sortable headerStyle={{ width: '15%', minWidth: '15rem' }} sortField={sortColumn[col.header]}
            filter showFilterMenu={false} filterElement={hideColumnsFilterTemplate(col.header)} />;
    });
    //#endregion

    const ButtonMailto = (rowData) => {
        return (
            <Link className='linkcolors' id='mailto'
                to='#'
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >{rowData.email}
            </Link>
        );
    };

    const ButtonPhoneto = (rowData) => {
        return (
            <Link className='linkcolors' id='callto'
                to='#'
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >{rowData.mobileNo}
            </Link>
        );
    };

    const getData = () => {
        fetch("config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setApiHeaders(data[1]);
                setWhatsAppUrlData(data[6].TicketWhatsAppUrl);
                setWhatsAppStatus(data[7].EnableWhatsApp);
            });
    };

    const attachfiletemplate = (rowData) => {
        return (<>
            <i className={'fa fa-envelope p-button-rounded p-button-outlined p-button-secondary justify-content-center p-button-outlined-attachment  tooltip-button-' + rowData.id} onClick={(e) => {ResendMail(rowData.id, rowData.email); SendQrWhatsApp(rowData)}} data-pr-tooltip="Resend Mail" data-pr-position="bottom" style={{ cursor: 'pointer', fontSize: '1.5rem' }}></i>
            <i className={'fa fa-brands fa-whatsapp p-button-rounded p-button-outlined p-button-secondary justify-content-center p-button-outlined-attachment  tooltip-button-' + rowData.id} onClick={(e) => {SendFinalTicket(rowData)}} data-pr-tooltip="Final Whats App Ticket" data-pr-position="bottom" style={{ cursor: 'pointer', fontSize: '1.5rem' }}></i>
            <Tooltip target={".tooltip-button-" + rowData.id} position="bottom">
                <div className="flex align-items-start" style={{ flexDirection: 'column' }}>
                    <span style={{ cursor: 'pointer', marginBottom: '0.2rem' }}>Resend Mail</span>
                </div>
            </Tooltip>
        </>);
    };

    const ViewAttendeeIcon = (rowData) => {
        return (
            <div className="actions">
                <Link to='#' onClick={(e) => history.push({ pathname: '/home/viewattendeeRecord/' + rowData.id, state: { detail: "SentTickets" } })}> <span className="fa fa-solid fa-eye" id="viewattendeerecord" style={{ color: ' rgb(42, 102, 151)' }}></span></Link>
            </div>
        );
    };


     // #region Whats App Qr Send api
     const SendQrWhatsApp = (rowData) => {
            var data = {
                messaging_product: "whatsapp",
                recipient_type: "individual",
                to: rowData.mobileNo,
                type: "template",
                template: {
                    name: "trico_ticket_template",
                    language: {
                        code: "en",
                    },
                    components: [
                        {
                            type: "header",
                            parameters: [
                                {
                                    type: "document",
                                    document: {
                                        link: WhatsAppUrlData + rowData.participantFileData[0].ticketPath,
                                        filename: rowData.participantFileData[0].ticketPath.split("/").pop(),
                                    },
                                },
                            ],
                        },
                        {
                            type: "body",
                            parameters: [
                                {
                                    type: "text",
                                    text: rowData.fullName,
                                },
                            ],
                        },
                    ],
                },
            };
            axios
                .post("https://graph.facebook.com/v17.0/177696368765013/messages", data, ApiHeaders)
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Successfully Sent The Tickets On WhatsApp", life: 3000 });
                    WhatsAppLog(data, rowData?.id);
                })
                .catch((error) => {
                    console.log(error);
                });
    };

    const WhatsAppLog = (Apidata, id) => {
        const url = "/api/Participant/WhatsAppLog";
        const data = {
            Number: Apidata?.to?.toString(),
            Template: Apidata.template.name,
            SendBy: UserName,
            AttendeeId: id.toString(),
            Screen: "SENT-TICKETS",
        };
        axios
            .post(url, data)
            .then((res) => {})
            .catch((error) => {
                console.log(error);
            });
    };

    // #region Whats App Qr Send api
    const SendFinalTicket = (rowData) => {
        setIsLoading(true);
        var data = {
            messaging_product: "whatsapp",
            recipient_type: "individual",
            to: rowData.mobileNo,
            type: "template",
            template: {
                name: "trico_final_ticket",
                language: {
                    code: "en",
                },
                components: [
                    {
                        type: "header",
                        parameters: [
                            {
                                type: "document",
                                document: {
                                    link: WhatsAppUrlData + rowData.participantFileData[0].ticketPath,
                                    filename: rowData.participantFileData[0].ticketPath.split("/").pop(),
                                },
                            },
                        ],
                    },
                    {
                        type: "body",
                        parameters: [
                            {
                                type: "text",
                                text: rowData.fullName,
                            },
                        ],
                    },
                ],
            },
        };
        axios
            .post("https://graph.facebook.com/v17.0/177696368765013/messages", data, ApiHeaders)
            .then((res) => {
                setIsLoading(false);
                toast.current.show({ severity: "success", summary: "Successful", detail: "Successfully Sent The Tickets On WhatsApp", life: 3000 });
                WhatsAppLog(data, rowData?.id);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
};


    return (
        <AccessControl allowedPermissions={["route:bookedtickets"]} renderNoAccess={() => <NoAccess />}>
            <div className="trico-Grid">
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <Toast ref={toast} />
                                    <h5 className="headerfont"><span><i className="fa fa-solid fa-ticket mr-2"></i>Sent Tickets</span></h5>
                                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <Tooltip target="#mailto" content="Mail To" />
                                    <Tooltip target="#callto" content="Call To" />
                                    <Tooltip target="#viewattendeerecord" content="View Attendee Record" />
                                    <DataTable scrollable scrollHeight="calc(100vh - 250px)" size="small" lazy rows={lazyState.current.rows}
                                        onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                        removableSort stripedRows ref={dt} value={participantlist}
                                        dataKey="id" responsiveLayout="scroll" filterDisplay={"row"}>
                                        {/* <Column frozen selectionMode="multiple" headerStyle={{ width: '0%', minWidth: '1rem' }}></Column> */}
                                        <Column body={ViewAttendeeIcon} frozen exportable={true} headerStyle={{ width: '0%', minWidth: '2rem' }} ></Column>
                                        <Column body={attachfiletemplate} frozen exportable={true} headerStyle={{ width: '0%', minWidth: '2rem' }} ></Column>
                                        <Column frozen field="fullName" sortField="FullName" header="Name" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={UserFilterTemplate} showClearButton></Column>
                                        <Column field="company" header="Company" sortField="Companyname" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} showClearButton ></Column>
                                        <Column body={ButtonMailto} sortField="Email" header="Email" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={EmailFilterTemplate} showClearButton></Column>
                                        <Column body={ButtonPhoneto} sortField="MobileNo" header="Mobile No." sortable headerStyle={{ width: '15%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} filter showFilterMenu={false} filterElement={MobileFilterTemplate}></Column>
                                        <Column field="boardingcityname" sortField="boardingcityname" header="Boarding City" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={BoardingCityFilterTemplate} showClearButton></Column>
                                        {/* <Column field="hospitalName" sortField="HospitalName" header="Hospital" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate} showClearButton></Column> */}
                                        {hidecolumnComponents}

                                        <Column field="inFlightNo1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF1 Flight No"></Column>
                                        <Column field="inAirlineName1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF1 Airline"></Column>
                                        <Column field="inDepartFrom1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF1 Depart From"></Column>
                                        <Column field="inDepartureDateTime1" body={(rowData) => (<span>{rowData?.inDepartureDateTime1? moment(rowData.inDepartureDateTime1).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF1 Departure DateTime"></Column>
                                        <Column field="inArriveAt1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF1 Arrive At"></Column>
                                        <Column field="inArrivalDateTime1" body={(rowData) => (<span>{rowData?.inArrivalDateTime1? moment(rowData.inArrivalDateTime1).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF1 Arrival DateTime"></Column>

                                        <Column field="inFlightNo2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF2 Flight No"></Column>
                                        <Column field="inAirlineName2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF2 Airline"></Column>
                                        <Column field="inDepartFrom2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF2 Depart From"></Column>
                                        <Column field="inDepartureDateTime2" body={(rowData) => (<span>{rowData?.inDepartureDateTime2? moment(rowData.inDepartureDateTime2).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF2 Departure DateTime"></Column>
                                        <Column field="inArriveAt2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF2 Arrive At"></Column>
                                        <Column field="inArrivalDateTime2" body={(rowData) => (<span>{rowData?.inArrivalDateTime2? moment(rowData.inArrivalDateTime2).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF2 Arrival DateTime"></Column>

                                        <Column field="inFlightNo3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF3 Flight No"></Column>
                                        <Column field="inAirlineName3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF3 Airline"></Column>
                                        <Column field="inDepartFrom3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF3 Depart From"></Column>
                                        <Column field="inDepartureDateTime3" body={(rowData) => (<span>{rowData?.inDepartureDateTime3? moment(rowData.inDepartureDateTime3).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF3 Departure DateTime"></Column>
                                        <Column field="inArriveAt3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF3 Arrive At"></Column>
                                        <Column field="inArrivalDateTime3" body={(rowData) => (<span>{rowData?.inArrivalDateTime3? moment(rowData.inArrivalDateTime3).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF3 Arrival DateTime"></Column>

                                        <Column field="inFlightNo4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF4 Flight No"></Column>
                                        <Column field="inAirlineName4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF4 Airline"></Column>
                                        <Column field="inDepartFrom4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF4 Depart From"></Column>
                                        <Column field="inDepartureDateTime4" body={(rowData) => (<span>{rowData?.inDepartureDateTime4? moment(rowData.inDepartureDateTime4).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF4 Departure DateTime"></Column>
                                        <Column field="inArriveAt4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF4 Arrive At"></Column>
                                        <Column field="inArrivalDateTime4" body={(rowData) => (<span>{rowData?.inArrivalDateTime4? moment(rowData.inArrivalDateTime4).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="INF4 Arrival DateTime"></Column>

                                        <Column field="outFlightNo1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF1 Flight No"></Column>
                                        <Column field="outAirlineName1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF1 Airline"></Column>
                                        <Column field="outDepartFrom1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF1 Depart From"></Column>
                                        <Column field="outDepartureDateTime1" body={(rowData) => (<span>{rowData?.outDepartureDateTime1? moment(rowData.outDepartureDateTime1).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF1 Departure DateTime"></Column>
                                        <Column field="outArriveAt1" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF1 Arrive At"></Column>
                                        <Column field="outArrivalDateTime1" body={(rowData) => (<span>{rowData?.outArrivalDateTime1? moment(rowData.outArrivalDateTime1).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF1 Arrival DateTime"></Column>

                                        <Column field="outFlightNo2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF2 Flight No"></Column>
                                        <Column field="outAirlineName2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF2 Airline"></Column>
                                        <Column field="outDepartFrom2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF2 Depart From"></Column>
                                        <Column field="outDepartureDateTime2" body={(rowData) => (<span>{rowData?.outDepartureDateTime2? moment(rowData.outDepartureDateTime2).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF2 Departure DateTime"></Column>
                                        <Column field="outArriveAt2" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF2 Arrive At"></Column>
                                        <Column field="outArrivalDateTime2" body={(rowData) => (<span>{rowData?.outArrivalDateTime2? moment(rowData.outArrivalDateTime2).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF2 Arrival DateTime"></Column>

                                        <Column field="outFlightNo3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF3 Flight No"></Column>
                                        <Column field="outAirlineName3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF3 Airline"></Column>
                                        <Column field="outDepartFrom3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF3 Depart From"></Column>
                                        <Column field="outDepartureDateTime3" body={(rowData) => (<span>{rowData?.outDepartureDateTime3? moment(rowData.outDepartureDateTime3).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF3 Departure DateTime"></Column>
                                        <Column field="outArriveAt3" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF3 Arrive At"></Column>
                                        <Column field="outArrivalDateTime3" body={(rowData) => (<span>{rowData?.outArrivalDateTime3? moment(rowData.outArrivalDateTime3).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF3 Arrival DateTime"></Column>

                                        <Column field="outFlightNo4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF4 Flight No"></Column>
                                        <Column field="outAirlineName4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF4 Airline"></Column>
                                        <Column field="outDepartFrom4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF4 Depart From"></Column>
                                        <Column field="outDepartureDateTime4" body={(rowData) => (<span>{rowData?.outDepartureDateTime4? moment(rowData.outDepartureDateTime4).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF4 Departure DateTime"></Column>
                                        <Column field="outArriveAt4" headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF4 Arrive At"></Column>
                                        <Column field="outArrivalDateTime4" body={(rowData) => (<span>{rowData?.outArrivalDateTime4? moment(rowData.outArrivalDateTime4).format('DD/MM/YYYY HH:mm'): ''}</span>)} headerStyle={{ width: '10%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} header="OUTF4 Arrival DateTime"></Column>
                                        {/* <Column header="Action" headerStyle={{ width: '18%', minWidth: '18rem' }}></Column> */}
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </AccessControl>
    )
};

export default SentTickets;