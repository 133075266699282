import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Paginator } from 'primereact/paginator';
import { Link } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory } from 'react-router-dom';
import * as Designations from '../../enums/Designations';
import * as Travelmodes from '../../enums/Travelmodes';
import { MultiSelect } from 'primereact/multiselect';
import exportData from "../../services/exportToExcelService";
import { Toolbar } from "primereact/toolbar";
import * as Genders from '../../enums/Genders';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
 import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";

const TicketsToBeRebook = () => {

    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false); const [rowdataid, setRowDataId] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    const [boardingcityfilter, setboardingcityfilter] = useState(null);
    const [fullName, setfullName] = useState(null)
    const [hospitalNamefilter, sethospitalNamefilter] = useState(null);
    const [companyNameFilter, setcompanyNameFilter] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        Designationfilter: null,
        Toeventcityfilter: null,
        Fromeventcityfilter: null,
        Genderfilter: null,
    }); // For Sorting And Pagination Purpose
    const [enableFilter, setEnableFilter] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [isExportExcel, setIsExportExcel] = useState(false);
    const [Cityfilter, setCityfilter] = useState(null);
    const [Statefilter, setStatefilter] = useState(null);
    const [Countryfilter, setCountryfilter] = useState(null);

    useEffect(() => {
        getparticipantlist();
    }, [])

    // Get All Participants
    const getparticipantlist = (isExportExcel = false, event) => {
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios.post('/api/Participant/GetAll', {
            CompanyFilter: '',
            registrationStatus: 'APPROVED',
            InFlightBookingStatus: 'TO BE REBOOK',
            OutFlightBookingStatus: 'TO BE REBOOK',
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event && event.sortField ? event.sortField : "Firstname",
            FullName: fullName ? fullName : null,
            Email: filterEmail ? filterEmail : null,
            MobileNo: filterMobileNo ? filterMobileNo : null,
            Designation: lazyState.current.Designationfilter ? lazyState.current.Designationfilter.name : null,
            Gender: lazyState.current.Genderfilter ? lazyState.current.Genderfilter.name : null,
            ToEventCity: lazyState.current.Toeventcityfilter ? lazyState.current.Toeventcityfilter.code : null,
            FromEventCity: lazyState.current.Fromeventcityfilter ? lazyState.current.Fromeventcityfilter.code : null,
            City: Cityfilter ? Cityfilter : null,
            State: Statefilter ? Statefilter : null,
            Country: Countryfilter ? Countryfilter : null,
            BoardingCity: boardingcityfilter ? boardingcityfilter : null,
            HospitalName: hospitalNamefilter ? hospitalNamefilter : null
        }).then(Response => {
            if (!isExportExcel) setParticipantlist([]);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                // Pass body data in excelsheets.
                let allExcelData;
                let excelcolumnData = {
                    Id: dataObj.id,
                    Name: dataObj.fullName,
                    Email: dataObj.email,
                    MobileNo: dataObj.mobileNo,
                    BoardingCity: dataObj.boardingcityname,
                }
                if (selectedColumns.length > 0) {
                    for (let selectedColumn of selectedColumns) {
                        let fieldName = selectedColumn.field;
                        let headerName = selectedColumn.header;
                        excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                    }
                    tempData.push(excelcolumnData);
                } else {
                    allExcelData = {
                        Id: dataObj.id,
                        Name: dataObj.fullName,
                        Email: dataObj.email,
                        MobileNo: dataObj.mobileNo,
                        BoardingCity: dataObj.boardingcityname,
                    }
                    tempData.push(allExcelData);
                }
            }
            if (isExportExcel) {
                exportData(tempData, 'TicketsToBeRebook');
                setIsLoading(false);
                return false;
            }
            setTotalRecords(Response.data.totalRecords);
            setParticipantlist(Response.data.data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
        });
    };

    const UserFilterTemplate = () => {
        return (
            <InputText id="fullName" required value={fullName} placeholder="Enter FirstName"
                onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    const BoardingCityFilterTemplate = () => {
        return (
            <InputText id="boardingcityname" required value={boardingcityfilter} placeholder="Enter BoardingCity"
                onChange={(e) => setboardingcityfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const EmailFilterTemplate = () => {
        return (
            <InputText id="Email" required value={filterEmail} placeholder="Enter Email"
                onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const MobileFilterTemplate = () => {
        return (
            <InputText id="Email" required value={filterMobileNo} placeholder="Enter Mobile No."
                onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            setIsLoading(true);
            getparticipantlist(isExportExcel);
            setIsLoading(false);
        }
    };


    const DesignationFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Designationfilter} options={Designations.Designation} optionLabel="name" showClear onChange={(e) => { lazyState.current.Designationfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { Designation: e.value }); e.preventDefault() }} placeholder="Select Designation" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const GenderFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Genderfilter} options={Genders.Gender} optionLabel="name" showClear onChange={(e) => { lazyState.current.Genderfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { Gender: e.value }); e.preventDefault() }} placeholder="Select Gender" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const ToEventCityTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Toeventcityfilter} options={Travelmodes.Travelmode} optionLabel="name" showClear onChange={(e) => { lazyState.current.Toeventcityfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { ToEventCity: e.value }); e.preventDefault() }} placeholder="Select TravelMode " className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };


    const FromEventCityTemplate = () => {
        return (
            <Dropdown value={lazyState.current.Fromeventcityfilter} options={Travelmodes.Travelmode} optionLabel="name" showClear onChange={(e) => { lazyState.current.Fromeventcityfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { FromEventCity: e.value }); e.preventDefault() }} placeholder="Select TravelMode" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const CityFilterTemplate = () => {
        return (
            <InputText id="city" required value={Cityfilter} placeholder="Enter City"
                onChange={(e) => setCityfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const StateFilterTemplate = () => {
        return (
            <InputText id="state" required value={Statefilter} placeholder="Enter State"
                onChange={(e) => setStatefilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const CountryFilterTemplate = () => {
        return (
            <InputText id="country" required value={Countryfilter} placeholder="Enter Country"
                onChange={(e) => setCountryfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };


    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
            setIsLoading(true);
            lazyState.current.sortField = lazyState.current.sortField;
            lazyState.current.sortOrder = lazyState.current.sortOrder;
            lazyState.current.first = event.first;
            lazyState.current.rows = event.rows;
            getparticipantlist(isExportExcel, lazyState.current);
            setIsLoading(false);
        // };
    }

    //Template for pagination
    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            )
        }
    };

    const exportExcel = () => {
        getparticipantlist(true);
    }

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                {enableFilter ? <Button type="button" icon="pi pi-filter-slash" tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />}
                <div className="my-2">{header}</div>
                <Button disabled={!(participantlist != null && participantlist.length > 0)} tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} onClick={() => { exportExcel(); }} icon="pi pi-file-excel" className="p-button-success mr-2" />
            </div>
        )
    }

    //#region Method for Column toggle
    const hidecolumns = [
        { field: "designation", header: "Designation" },
        { field: "gender", header: "Gender" },
        { field: "cityname", header: "City" },
        { field: "statename", header: "State" },
        { field: "countryname", header: "Country" },
        { field: "travelmode_Inbound", header: "Travel In Bound" },
        { field: "travelmode_Outbound", header: "Travel Out Bound" }
    ];

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = hidecolumns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
    }

    const header = (
        <div style={{ textAlign: 'left', marginRight: '10px' }}>
            <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: '20em' }} display="chip" />
        </div>
    );

    const hideColumnsFilterTemplate = (column) => {
        switch (column) {
            case "Designation":
                return DesignationFilterTemplate;
            case "Gender":
                return GenderFilterTemplate;
            case "City":
                return CityFilterTemplate;
            case "State":
                return StateFilterTemplate;
            case "Travel In Bound":
                return ToEventCityTemplate;
            case "Travel Out Bound":
                return FromEventCityTemplate;
            case "Country":
                return CountryFilterTemplate;
        }
    }

    const sortColumn = {
        "Designation": 'Designation',
        "cityname": 'cityname',
        "Statename": 'Statename',
        "Countryname": 'Countryname',
        "Travel In Bound": 'Travelmode_Inbound',
        "Travel Out Bound": 'Travelmode_Outbound',
        "Gender": 'Gender',
    };

    const hidecolumnComponents = selectedColumns.map(col => {
        return <Column key={col.field} field={col.field} header={col.header} sortable headerStyle={{ width: '14%', minWidth: '10rem' }} sortField={sortColumn[col.header]}
            filter showFilterMenu={false} filterElement={hideColumnsFilterTemplate(col.header)} />;
    });
    //#endregion

    const ButtonMailto = (rowData) => {
        return (
            <Link
                to='#'
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >{rowData.email}
            </Link>
        );
    };

    const ButtonPhoneto = (rowData) => {
        return (
            <Link
                to='#'
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >{rowData.mobileNo}
            </Link>
        );
    }

    return (
        <div className="trico-Grid">
            {isLoading ? <LoadingSpinner /> :
                (
                    <>
                        <div className="p-col">
                            <div className="card">
                                <Toast ref={toast} />
                                <h5><span><i className="fa fa-solid fa-ticket mr-2"></i>Tickets to be Rebook</span></h5>
                                <Toolbar right={rightToolbarTemplate}></Toolbar>
                                {/* <Toolbar className="mb-4" ></Toolbar> */}
                                <DataTable size="small" lazy rows={lazyState.current.rows}
                                    onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                    removableSort stripedRows ref={dt} value={participantlist} selection={participantlist} onSelectionChange={(e) => setParticipantlist(e.value)}
                                    dataKey="Id" responsiveLayout="scroll" filterDisplay={enableFilter ? "row" : null}>
                                    <Column frozen className="font-bold" sortField="Firstname" field="fullName" header="Name of Certificate/Ticket" sortable headerStyle={{ width: '18%', minWidth: '18rem' }} filter showFilterMenu={false} filterElement={UserFilterTemplate} showClearButton></Column>
                                    <Column body={ButtonMailto} sortField="Email" header="Email" sortable headerStyle={{ width: '18%', minWidth: '18rem' }} filter showFilterMenu={false} filterElement={EmailFilterTemplate} showClearButton></Column>
                                    <Column body={ButtonPhoneto} sortField="MobileNo" header="Mobile No" sortable headerStyle={{ width: '18%', minWidth: '18rem' }} filter showFilterMenu={false} filterElement={MobileFilterTemplate} showClearButton></Column>
                                    <Column field="boardingcityname"  sortField="boardingcityname" header="Boarding City" sortable headerStyle={{ width: '18%', minWidth: '18rem' }} filter showFilterMenu={false} filterElement={BoardingCityFilterTemplate} showClearButton></Column>
                                    {hidecolumnComponents}
                                </DataTable>
                                <Paginator className='justify-content-start'
                                    style={{ display: 'block' }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}
export default TicketsToBeRebook;