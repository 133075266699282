import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import exportData from "../../services/exportToExcelService";
import { Toolbar } from "primereact/toolbar";
import * as Designations from "../../enums/Designations";
import * as Travelmodes from "../../enums/Travelmodes";
import * as Genders from "../../enums/Genders";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import userDetails from "../../services/userTokenDetails";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import QRCode from "qrcode.react";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { ToggleButton } from "primereact/togglebutton";

const ViewNewAttendees = (props) => {
    let emptyParticipantQRCode = [
        {
            id: "",
            ImageUrl: "",
            ImageName: "",
            MobileNo: "",
            UserName: "",
            Url: "",
            Registeredby: "",
            companyContactPerson: "",
        },
    ];

    let participantQRCode = {
        qrId: "",
        url: "",
    };

    // let emptyUser = [];
    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [fullName, setfullName] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [User, setUser] = useState([]);
    const [boardingcityfilter, setboardingcityfilter] = useState(null);
    const [hospitalNamefilter, sethospitalNamefilter] = useState(null);
    const [companyNameFilter, setcompanyNameFilter] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [RegisteredName, setRegisteredName] = useState(null);
    const [RegisteredByNumber, setRegisteredByNumber] = useState(null);
    const [NameFrozen, setNameFrozen] = useState(false);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        CompanyFilter: null,
        ParticipantNameFilter: null,
        EmailFilter: null,
        MobileNoFilter: null,
        CityFilter: null,
        StateFilter: null,
        CountryFilter: null,
        BoardingCityFilter: null,
        HospitalNameFilter: null,
        Designationfilter: null,
        Toeventcityfilter: null,
        Fromeventcityfilter: null,
        Genderfilter: null,
        registeredby: null,
        registeredbynumber: null,
        serialnumber: null,
    }); // For Sorting And Pagination Purpose

    const WhatsAppMessageStatus = useRef({
        Doctor: null,
        FieldPerson: null,
        Admin: null,
    });

    const [enableFilter, setEnableFilter] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [isExportExcel, setIsExportExcel] = useState(false);
    const [Cityfilter, setCityfilter] = useState(null);
    const [Statefilter, setStatefilter] = useState(null);
    const [Countryfilter, setCountryfilter] = useState(null);
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [selecteddata, setselecteddata] = useState(null);
    const [id, setid] = useState(new Array());
    const [userObj, setuserObj] = useState(new Array());

    const [Userid, setUserid] = useState(new Array());
    const [ActionDisable, setActionDisable] = useState(true);
    const [QrCodes, setQRCodes] = useState(new Array());
    const [UrlData, setUrlData] = useState(null);
    let qrData = emptyParticipantQRCode;
    const [SerialNumber, setSerialNumber] = useState(null);
    const [WhatsAppUrlData, setWhatsAppUrlData] = useState(null);
    const [ApiHeaders, setApiHeaders] = useState(null);
    const [WhatsappStatus, setWhatsAppStatus] = useState(null);
    const [SeemamamNo, setSeemamamNo] = useState(null);

    // Const
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    var company = localStorage.getItem("Company");
    const CompanyId = localStorage.getItem("CompanyId");
    const UserName = localStorage.getItem('Username');
    var usrDetails = userDetails();
    let orderedSelectedColumns = [];
    let unSelectedColumns = [];
    var Error = ""; // For Duplicate User Exist Error Message

    // On Screen Load
    useEffect(() => {
        document.documentElement.style.setProperty("overflow", "hidden");
        if (props?.location.state?.detail == "AddAttendee") {
            toast.current.show({ severity: "success", summary: "Successful", detail: "New Attendee Added", life: 3000 });
        }
        getparticipantlist();
        getData();
    }, []);

    // Get All Participants
    const getparticipantlist = (isExportExcel = false, event) => {
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios
            .post("/api/Participant/GetAll", {
                paginationRequired: !isExportExcel,
                SortDirection: lazyState.current.sortOrder,
                PageSize: lazyState.current.rows,
                CurrentPageNumber: event && event.rows ? event.first / event.rows + 1 : lazyState.current.page,
                SortField: event && event.sortField ? event.sortField : "",
                registrationStatus: "NEW",
                CompanyFilter: lazyState.current.CompanyFilter ? lazyState.current.CompanyFilter.trim() : null,
                CompanyWiseFilter: usrDetails.role === "MR" || usrDetails.role === "SUBMR" ? CompanyId : null, // For Mr & Sub Mr Login
                FullName: lazyState.current.ParticipantNameFilter ? lazyState.current.ParticipantNameFilter.trim() : null,
                Email: lazyState.current.EmailFilter ? lazyState.current.EmailFilter.trim() : null,
                MobileNo: lazyState.current.MobileNoFilter ? lazyState.current.MobileNoFilter.trim() : null,
                Designation: lazyState.current.Designationfilter ? lazyState.current.Designationfilter.name.trim() : null,
                Gender: lazyState.current.Genderfilter ? lazyState.current.Genderfilter.name.trim() : null,
                ToEventCity: lazyState.current.Toeventcityfilter ? lazyState.current.Toeventcityfilter.code.trim() : null,
                FromEventCity: lazyState.current.Fromeventcityfilter ? lazyState.current.Fromeventcityfilter.code.trim() : null,
                City: lazyState.current.CityFilter ? lazyState.current.CityFilter.trim() : null,
                State: lazyState.current.StateFilter ? lazyState.current.StateFilter.trim() : null,
                Country: lazyState.current.CountryFilter ? lazyState.current.CountryFilter.trim() : null,
                BoardingCity: lazyState.current.BoardingCityFilter ? lazyState.current.BoardingCityFilter.trim() : null,
                HospitalName: lazyState.current.HospitalNameFilter ? lazyState.current.HospitalNameFilter.trim() : null,
                RegisteredByPhone: lazyState.current.registeredbynumber ? lazyState.current.registeredbynumber.trim() : null,
                UserRegisteredBy: lazyState.current.registeredby ? lazyState.current.registeredby.trim() : null,
                SerialNumber: lazyState.current.serialnumber ? lazyState.current.serialnumber.trim() : null,
            })
            .then((Response) => {
                if (!isExportExcel) setParticipantlist([]);
                let tempData = [];
                for (let dataObj of Response.data.data) {
                    // Pass body data in excelsheets.
                    let allExcelData;
                    let excelcolumnData = {
                        Id: dataObj.serialNumber,
                        Name: dataObj.fullName,
                        Hospital: dataObj.hospitalName,
                        Company: dataObj.company,
                        Email: dataObj.email,
                        MobileNo: dataObj.mobileNo,
                    };
                    if (selectedColumns.length > 0) {
                        for (let selectedColumn of selectedColumns) {
                            let fieldName = selectedColumn.field;
                            let headerName = selectedColumn.header;
                            excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] };
                        }
                        tempData.push(excelcolumnData);
                    } else {
                        allExcelData = {
                            Id: dataObj.serialNumber,
                            Name: dataObj.fullName,
                            Hospital: dataObj.hospitalName,
                            Company: dataObj.company,
                            Email: dataObj.email,
                            MobileNo: dataObj.mobileNo,
                        };
                        tempData.push(allExcelData);
                    }
                }
                if (isExportExcel) {
                    exportData(tempData, "NewAttendees");
                    setIsLoading(false);
                    return false;
                }
                setTotalRecords(Response.data.totalRecords);
                setParticipantlist(Response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    //Update Registration Status of participant

    const UpdateRegistrationStatus = (id, status, enable) => {
        setIsLoading(true);
        const url = "/api/Participant/UpdateregistrationStatus";
        const data = {
            id: id.toString(),
            registrationstatus: status,
            IsEnabled: enable,
            UserId: Userid.toString(),
            ModifiedBy:UserName
        };
        axios
            .put(url, data)
            .then((res) => {
                setIsLoading(false);
                getparticipantlist(isExportExcel, lazyState.current);
                if (status == "APPROVED") {
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Attendee Approved", life: 3000 });
                } else {
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Attendee Rejected", life: 3000 });
                }
                setid([]);
                setuserObj([]);
                setActionDisable(true);
                setselecteddata(null);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };
    // const UpdateRegistrationStatus = (id, status) => {
    //     setIsLoading(true);
    //     if (status == "APPROVED") {
    //         axios.put(`/api/Participant/UpdateregistrationStatus/${id}?registrationstatus=${status}&IsEnabled=${true}&UserId=${Userid}`)
    //             .then(res => {
    //                 setIsLoading(false);
    //                 getparticipantlist(isExportExcel, lazyState.current);
    //                 toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Attendee Approved', life: 3000 });
    //                 setid([]);
    //                 setActionDisable(true);
    //                 setselecteddata(null);
    //             }).catch((error) => {
    //                 setIsLoading(false);
    //                 toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
    //             });
    //     }
    //     else {
    //         axios.put(`/api/Participant/UpdateregistrationStatus/${id}?registrationstatus=${status}&IsEnabled=${false}&UserId=${Userid}`)
    //             .then(res => {
    //                 setIsLoading(false);
    //                 getparticipantlist(isExportExcel, lazyState.current);
    //                 toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Attendee Rejected', life: 3000 });
    //                 setid([]);
    //                 setActionDisable(true);
    //                 setselecteddata(null);
    //             }).catch((error) => {
    //                 setIsLoading(false);
    //                 toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
    //             });
    //     }
    // };

    //Template for pagination
    const template2 = {
        layout: " CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "19%", textAlign: "center" }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return (
                <span>
                    Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} />
                </span>
            );
        },
    };

    const UserFilterTemplate = () => {
        return <InputText id="fullName" value={fullName} placeholder="Enter First Name" onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const BoardingCityFilterTemplate = () => {
        return <InputText id="boardingcityname" value={boardingcityfilter} placeholder="Enter Boarding City" onChange={(e) => setboardingcityfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const HospitalFilterTemplate = () => {
        return <InputText id="hospitalname" value={hospitalNamefilter} placeholder="Enter Hospital Name" onChange={(e) => sethospitalNamefilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const CompanyFilterTemplate = () => {
        return <InputText id="company" value={companyNameFilter} placeholder="Enter Company" onChange={(e) => setcompanyNameFilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const EmailFilterTemplate = () => {
        return <InputText id="Email" value={filterEmail} placeholder="Enter Email" onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const MobileFilterTemplate = () => {
        return <InputText id="Mobile" value={filterMobileNo} placeholder="Enter Mobile No." onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const DesignationFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Designationfilter}
                options={Designations.Designation}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Designationfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { Designation: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Designation"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const SerialNumberFilterTemplate = () => {
        return <InputText id="serialnumber" value={SerialNumber} placeholder="Sr No." onChange={(e) => setSerialNumber(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "5rem" }} />;
    };

    const GenderFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Genderfilter}
                options={Genders.Gender}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Genderfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { Gender: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Gender"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const ToEventCityTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Toeventcityfilter}
                options={Travelmodes.Travelmode}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Toeventcityfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { ToEventCity: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Travel Mode "
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const FromEventCityTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Fromeventcityfilter}
                options={Travelmodes.Travelmode}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Fromeventcityfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { FromEventCity: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Travel Mode"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const CityFilterTemplate = () => {
        return <InputText id="city" value={Cityfilter} placeholder="Enter City" onChange={(e) => setCityfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const StateFilterTemplate = () => {
        return <InputText id="state" value={Statefilter} placeholder="Enter State" onChange={(e) => setStatefilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const CountryFilterTemplate = () => {
        return <InputText id="country" value={Countryfilter} placeholder="Enter Country" onChange={(e) => setCountryfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const RegisteredbyFilterTemplate = () => {
        return <InputText id="registeredby" value={RegisteredName} placeholder="Enter Registeredby" onChange={(e) => setRegisteredName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const RegisteredbyNumberFilterTemplate = () => {
        return <InputText id="registeredbynumber" value={RegisteredByNumber} placeholder="Registeredby Number" onChange={(e) => setRegisteredByNumber(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.ParticipantNameFilter = e.target.value ? e.target.value : null;
                        break;
                    case "boardingcityname":
                        lazyState.current.BoardingCityFilter = e.target.value ? e.target.value : null;
                        break;
                    case "hospitalname":
                        lazyState.current.HospitalNameFilter = e.target.value ? e.target.value : null;
                        break;
                    case "company":
                        lazyState.current.CompanyFilter = e.target.value ? e.target.value : null;
                        break;
                    case "Email":
                        lazyState.current.EmailFilter = e.target.value ? e.target.value : null;
                        break;
                    case "Mobile":
                        lazyState.current.MobileNoFilter = e.target.value ? e.target.value : null;
                        break;
                    case "city":
                        lazyState.current.CityFilter = e.target.value ? e.target.value : null;
                        break;
                    case "state":
                        lazyState.current.StateFilter = e.target.value ? e.target.value : null;
                        break;
                    case "country":
                        lazyState.current.CountryFilter = e.target.value ? e.target.value : null;
                        break;
                    case "registeredby":
                        lazyState.current.registeredby = e.target.value ? e.target.value : null;
                        break;
                    case "registeredbynumber":
                        lazyState.current.registeredbynumber = e.target.value ? e.target.value : null;
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = e.target.value ? e.target.value : null;
                        break;
                }
                getparticipantlist(isExportExcel, lazyState.current);
                setIsLoading(false);
            }
        }
    };

    // For Sorting
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
        setIsLoading(false);
        // };
    };

    const ButtonMailto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="mailto"
                to="#"
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >
                {rowData.email}
            </Link>
        );
    };

    const ButtonPhoneto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="callto"
                to="#"
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >
                {rowData.mobileNo}
            </Link>
        );
    };

    const ViewAttendees = (rowData) => {
        return (
            <AccessControl allowedPermissions={["view:viewnewattendees"]}>
                <Link
                    className="linkcolors"
                    id="viewattendee"
                    to="#"
                    onClick={(e) => {
                        history.push("/home/viewparticipant/" + rowData.id);
                    }}
                >
                    {rowData.fullName}
                </Link>
            </AccessControl>
        );
    };

    const exportExcel = () => {
        getparticipantlist(true);
    };

    const removeFilter = () => {
        lazyState.current.CompanyFilter = null;
        setcompanyNameFilter(null);
        lazyState.current.ParticipantNameFilter = null;
        setfullName(null);
        lazyState.current.BoardingCityFilter = null;
        setboardingcityfilter(null);
        lazyState.current.CityFilter = null;
        setCityfilter(null);
        lazyState.current.CountryFilter = null;
        setCountryfilter(null);
        lazyState.current.Designationfilter = null;
        lazyState.current.HospitalNameFilter = null;
        sethospitalNamefilter(null);
        lazyState.current.EmailFilter = null;
        setFilterEmail(null);
        lazyState.current.MobileNoFilter = null;
        setFilterMobileNo(null);
        lazyState.current.Genderfilter = null;
        lazyState.current.StateFilter = null;
        setStatefilter(null);
        lazyState.current.Toeventcityfilter = null;
        lazyState.current.Fromeventcityfilter = null;
        lazyState.current.registeredby = null;
        setRegisteredName(null);
        lazyState.current.registeredbynumber = null;
        setRegisteredByNumber(null);
        lazyState.current.serialnumber = null;
        setSerialNumber(null);
        getparticipantlist(isExportExcel, lazyState.current);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl allowedPermissions={["route:viewnewattendees"]}>
                    <ToggleButton className="mr-2" checked={NameFrozen} onChange={(e) => setNameFrozen(e.value)} onIcon="pi pi-lock" offIcon="pi pi-lock-open" onLabel="Frozed Name" offLabel="Freeze Name" />
                    <Button
                        disabled={
                            lazyState.current.CompanyFilter == null &&
                            lazyState.current.ParticipantNameFilter == null &&
                            lazyState.current.BoardingCityFilter == null &&
                            lazyState.current.CityFilter == null &&
                            lazyState.current.CountryFilter == null &&
                            lazyState.current.Designationfilter == null &&
                            lazyState.current.HospitalNameFilter == null &&
                            lazyState.current.EmailFilter == null &&
                            lazyState.current.MobileNoFilter == null &&
                            lazyState.current.serialnumber == null &&
                            lazyState.current.Genderfilter == null &&
                            lazyState.current.StateFilter == null &&
                            lazyState.current.Toeventcityfilter == null &&
                            lazyState.current.Fromeventcityfilter == null &&
                            lazyState.current.registeredby == null &&
                            lazyState.current.registeredbynumber == null
                                ? true
                                : false
                        }
                        icon="pi pi-filter-slash"
                        className="mr-2"
                        onClick={() => removeFilter()}
                        tooltip="Reset Filter"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        outlined
                    ></Button>
                    {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
                </AccessControl>
                <div className="my-2">{header}</div>
                <AccessControl allowedPermissions={["export:viewnewattendees"]}>
                    <Button
                        disabled={!(participantlist != null && participantlist.length > 0)}
                        onClick={() => {
                            exportExcel();
                        }}
                        icon="pi pi-file-excel"
                        tooltip="Export Excel"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        className="mr-2"
                        outlined
                    />
                </AccessControl>
            </div>
        );
    };

    //#region Method for Column toggle
    const hidecolumns = [
        { field: "userregisteredby", header: "Registered By" },
        { field: "registeredbynumber", header: "Registered By Contact" },
        { field: "designation", header: "Designation" },
        { field: "gender", header: "Gender" },
        { field: "boardingcityname", header: "BoardingCity" },
        { field: "cityname", header: "City" },
        { field: "statename", header: "State" },
        { field: "countryname", header: "Country" },
        { field: "travelmode_Inbound", header: "Travel In Bound" },
        { field: "travelmode_Outbound", header: "Travel Out Bound" },
    ];

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        orderedSelectedColumns = hidecolumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        unSelectedColumns = hidecolumns.filter((o) => !selectedColumns.some((i) => i.field === o.field && i.header === o.header));
        unselectedColumnData();
        setSelectedColumns(orderedSelectedColumns);
    };

    const unselectedColumnData = () => {
        let isFiltered = false;
        if (unSelectedColumns) {
            unSelectedColumns.map((col) => {
                switch (col.header) {
                    case "Designation":
                        if (lazyState.current.Designationfilter != null) {
                            lazyState.current.Designationfilter = null;
                            isFiltered = true;
                            return lazyState.current.Designationfilter;
                        }
                        break;
                    case "Gender":
                        if (lazyState.current.Genderfilter != null) {
                            lazyState.current.Genderfilter = null;
                            isFiltered = true;
                            return lazyState.current.Genderfilter;
                        }
                        break;
                    case "BoardingCity":
                        if (lazyState.current.BoardingCityFilter != null) {
                            lazyState.current.BoardingCityFilter = null;
                            isFiltered = true;
                            return setboardingcityfilter(null);
                        }
                        break;
                    case "City":
                        if (lazyState.current.CityFilter != null) {
                            lazyState.current.CityFilter = null;
                            isFiltered = true;
                            return setCityfilter(null);
                        }
                        break;
                    case "State":
                        if (lazyState.current.StateFilter != null) {
                            lazyState.current.StateFilter = null;
                            isFiltered = true;
                            return setStatefilter(null);
                        }
                        break;
                    case "Country":
                        if (lazyState.current.CountryFilter != null) {
                            lazyState.current.CountryFilter = null;
                            isFiltered = true;
                            return setCountryfilter(null);
                        }
                        break;
                    case "Travel In Bound":
                        if (lazyState.current.Toeventcityfilter != null) {
                            lazyState.current.Toeventcityfilter = null;
                            isFiltered = true;
                            return lazyState.current.Toeventcityfilter;
                        }
                        break;
                    case "Travel Out Bound":
                        if (lazyState.current.Fromeventcityfilter != null) {
                            lazyState.current.Fromeventcityfilter = null;
                            isFiltered = true;
                            return lazyState.current.Fromeventcityfilter;
                        }
                        break;
                    case "Registered By":
                        if (lazyState.current.registeredby != null) {
                            lazyState.current.registeredby = null;
                            isFiltered = true;
                            return lazyState.current.registeredby;
                        }
                        break;
                    case "Registered By Contact":
                        if (lazyState.current.registeredbynumber != null) {
                            lazyState.current.registeredbynumber = null;
                            isFiltered = true;
                            return lazyState.current.registeredbynumber;
                        }
                        break;
                }
            });
            if (isFiltered) {
                getparticipantlist(isExportExcel, lazyState.current);
            }
        }
    };

    const header = (
        <div style={{ textAlign: "left", marginRight: "10px" }}>
            <AccessControl allowedPermissions={["route:viewnewattendees"]}>
                <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: "20em" }} display="chip" />
            </AccessControl>
        </div>
    );

    const hideColumnsFilterTemplate = (column) => {
        switch (column) {
            case "Designation":
                return DesignationFilterTemplate;
            case "Gender":
                return GenderFilterTemplate;
            case "BoardingCity":
                return BoardingCityFilterTemplate;
            case "City":
                return CityFilterTemplate;
            case "State":
                return StateFilterTemplate;
            case "Travel In Bound":
                return ToEventCityTemplate;
            case "Travel Out Bound":
                return FromEventCityTemplate;
            case "Country":
                return CountryFilterTemplate;
            case "Gender":
                return GenderFilterTemplate;
            case "Registered By":
                return RegisteredbyFilterTemplate;
            case "Registered By Contact":
                return RegisteredbyNumberFilterTemplate;
        }
    };

    const sortColumn = {
        Designation: "Designation",
        company: "company",
        boardingcityname: "boardingcityname",
        cityname: "cityname",
        Statename: "Statename",
        Countryname: "Countryname",
        "Travel In Bound": "Travelmode_Inbound",
        "Travel Out Bound": "Travelmode_Outbound",
        Gender: "Gender",
        "Registered By": "userregisteredby",
        "Registered By Contact": "registeredbynumber",
    };

    const hidecolumnComponents = selectedColumns.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} sortable headerStyle={{ width: "15%", minWidth: "15rem" }} sortField={sortColumn[col.header]} filter showFilterMenu={false} filterElement={hideColumnsFilterTemplate(col.header)} />;
    });
    //#endregion

    // Row Selection / Unselect Functions
    const onAllRowSelect = (e) => {
        setActionDisable(false);
        setid(
            e.data.map((i, k) => {
                // generateQRCode(i.id);
                return i.id;
            })
        );
        setuserObj(
            e.data.map((i, k) => {
                generateQRCode(i);
                return i;
            })
        );
        setUserid(
            e.data.map((i, k) => {
                return i.userid;
            })
        );
        for (const dataobj of e.data) {
            // User.push(dataobj);
            if (User.filter((x) => x.firstname == dataobj.firstname).length == 0) {
                User.push(dataobj.userid);
            }
        }
    };

    const onRowUnselect = (event) => {
        if (id != undefined) {
            var removeidfromdata = id.indexOf(event.data.id);
            var removeuseridfromdata = Userid.indexOf(event.data.userid);
            var removedatafromobjbyid = userObj.indexOf(event.data.id);
            id.splice(removeidfromdata, 1);
            Userid.splice(removeuseridfromdata, 1);
            userObj.splice(removedatafromobjbyid, 1);
            QrCodes.splice(removeidfromdata, 1);
            User.splice(removeidfromdata, 1);
            if (id.length == 0) {
                setActionDisable(true);
            }
        } else {
            var removefromarray = id.indexOf(event.data.id);
            var removeuseridfromarray = Userid.indexOf(event.data.userid);
            var removedatafromobjarray = userObj.indexOf(event.data.id);
            id.splice(removefromarray, 1);
            Userid.splice(removeuseridfromarray, 1);
            userObj.splice(removedatafromobjarray, 1);
            User.splice(removefromarray, 1);
        }
    };

    const onAllRowUnSelect = (e) => {
        setActionDisable(true);
        setid(
            e.data.map((i, k) => {
                return i.id;
            })
        );
        setuserObj(
            e.data.map((i, k) => {
                return i;
            })
        );
        setUserid(
            e.data.map((i, k) => {
                return i.userid;
            })
        );
        setQRCodes([]);
        id.splice(0, id.length);
        Userid.splice(0, Userid.length);
        userObj.splice(0, userObj.length);
        User.splice(0, User.length);
    };

    const onRowSelect = (event) => {
        User.push(event.data.userid);
        setActionDisable(false);
        setuserObj((ing) => [...ing, event.data]);
        setid((ing) => [...ing, event.data.id]);
        setUserid((ing) => [...ing, event.data.userid]);
        generateQRCode(event.data);
    };

    // generate QR code
    const getData = () => {
        fetch("config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setUrlData(data[0].QrCodeUrl);
                setApiHeaders(data[1]);
                setWhatsAppUrlData(data[5].QrCodeWhatsAppUrl);
                setWhatsAppStatus(data[7].EnableWhatsApp);
                setSeemamamNo(data[8].SeemaMam);
            });
    };

    const generateQRCode = (event) => {
        participantQRCode.qrId = event.id;
        participantQRCode.url = UrlData + event.id;
        // participantQRCode.url = 'http://localhost:3000/#/home/QrCodeScan';
        QrCodes.push(participantQRCode);
    };

    //#region Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl allowedPermissions={["approve:viewnewattendees"]}>
                    <Button
                        disabled={ActionDisable}
                        label="Approve"
                        icon="fa fa-check"
                        className="mr-2"
                        tooltip="Approve"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        onClick={(e) => {
                            QrGenerationLoop();
                            UpdateRegistrationStatus(id, "APPROVED", true);
                            // sendwhatsapp();
                        }}
                        outlined
                    />
                </AccessControl>
                <AccessControl allowedPermissions={["reject:viewnewattendees"]}>
                    <Button disabled={ActionDisable} label="Reject" icon="fa fa-times" tooltip="Reject" tooltipOptions={{ position: "bottom", className: "showtooltip" }} onClick={(e) => UpdateRegistrationStatus(id, "REJECTED", false)} outlined />
                </AccessControl>
            </div>
        );
    };
    //#endregion

    const QrGenerationLoop = () => {
        qrData = [];
        Object.values(userObj).forEach((value) => {
            let participantQRCode = {
                id: "",
                ImageUrl: "",
                ImageName: "",
                MobileNo: "",
                UserName: "",
                Url: "",
                Registeredby: "",
                companyContactPerson: "",
            };
            // participantQRCode.id = value;
            const qrCodeURL = document.getElementById(`qrCodeEl${value.id}`).toDataURL("image/png").replace("image/png", "image/octet-stream");
            participantQRCode.id = value.id;
            participantQRCode.ImageUrl = qrCodeURL.substring(31);
            participantQRCode.ImageName = "QRCODE_" + value.id + ".jpg";
            participantQRCode.MobileNo = value.mobileNo;
            participantQRCode.UserName = value.fullName;
            participantQRCode.Url = UrlData + value.id;
            participantQRCode.Registeredby = value.companyphone;
            participantQRCode.companyContactPerson = value.companyContactPerson;
            qrData.push(participantQRCode);
        });
        SaveQRCode();
        if (WhatsappStatus == true) {
            SendQrWhatsApp();
            SendQrWhatsAppToSeemaMam();
            SendQrWhatsAppToRegisteredBy();

            const interval = setInterval(() => {
                if (WhatsAppMessageStatus.current.Doctor != null && WhatsAppMessageStatus.current.FieldPerson != null && WhatsAppMessageStatus.current.Admin != null) {
                    if (WhatsAppMessageStatus.current.Doctor == 200 && WhatsAppMessageStatus.current.FieldPerson == 200 && WhatsAppMessageStatus.current.Admin == 200) {
                        toast.current.show({ severity: "success", summary: "Successful", detail: "Successfully Sent The QR Code On WhatsApp", life: 3000 });
                    } else {
                        toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong In Sending WhatsApp!", life: 3000 });
                    }
                    clearInterval(interval);
                }
            }, 100);
            WhatsAppMessageStatus.current.Doctor = null;
            WhatsAppMessageStatus.current.FieldPerson = null;
            WhatsAppMessageStatus.current.Admin = null;
        }
    };

    const SaveQRCode = () => {
        const url = "/api/Participant/SaveQrCode";
        const data = qrData;
        axios
            .post(url, data)
            .then(() => {
                toast.current.show({ severity: "success", summary: "Successful", detail: "QR GENERATED SUCCESSFULLY", life: 3000 });
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };
    //#endregion

    //#region Whats App Qr Send api
    const SendQrWhatsApp = () => {
        debugger;
        qrData.forEach((element) => {
            var data = {
                messaging_product: "whatsapp",
                recipient_type: "individual",
                to: element.MobileNo,
                type: "template",
                template: {
                    name: "trico_qr_template",
                    language: {
                        code: "en",
                    },
                    components: [
                        {
                            type: "header",
                            parameters: [
                                {
                                    type: "image",
                                    image: {
                                        link: WhatsAppUrlData + element.ImageName,
                                    },
                                },
                            ],
                        },
                        {
                            type: "body",
                            parameters: [
                                {
                                    type: "text",
                                    text: element.UserName,
                                },
                            ],
                        },
                    ],
                },
            };
            axios
                .post("https://graph.facebook.com/v17.0/177696368765013/messages", data, ApiHeaders)
                .then((res) => {
                    WhatsAppMessageStatus.current.Doctor = res.status;
                    WhatsAppLog(data, element?.id);
                })
                .catch((error) => {
                    WhatsAppMessageStatus.current.Doctor = error?.response?.status;
                });
        });
    };

    const SendQrWhatsAppToRegisteredBy = () => {
        debugger;
        qrData.forEach((element) => {
            if (element.Registeredby != null) {
                var data = {
                    messaging_product: "whatsapp",
                    recipient_type: "individual",
                    to: element.Registeredby,
                    type: "template",
                    template: {
                        name: "trico_qr_template_mrcompany",
                        language: {
                            code: "en",
                        },
                        components: [
                            {
                                type: "header",
                                parameters: [
                                    {
                                        type: "image",
                                        image: {
                                            link: WhatsAppUrlData + element.ImageName,
                                        },
                                    },
                                ],
                            },
                            {
                                type: "body",
                                parameters: [
                                    {
                                        type: "text",
                                        text: element.companyContactPerson,
                                    },
                                    {
                                        type: "text",
                                        text: element.UserName,
                                    },
                                ],
                            },
                        ],
                    },
                };
                axios
                    .post("https://graph.facebook.com/v17.0/177696368765013/messages", data, ApiHeaders)
                    .then((res) => {
                        WhatsAppMessageStatus.current.FieldPerson = res.status;
                        WhatsAppLog(data, element?.id);
                    })
                    .catch((error) => {
                        WhatsAppMessageStatus.current.FieldPerson = error?.response?.status;
                    });
            }
        });
    };

    const SendQrWhatsAppToSeemaMam = () => {
        qrData.forEach((element) => {
            var data = {
                messaging_product: "whatsapp",
                recipient_type: "individual",
                to: SeemamamNo,
                type: "template",
                template: {
                    name: "trico_qr_template_apexadmin",
                    language: {
                        code: "en",
                    },
                    components: [
                        {
                            type: "header",
                            parameters: [
                                {
                                    type: "image",
                                    image: {
                                        link: WhatsAppUrlData + element.ImageName,
                                    },
                                },
                            ],
                        },
                        {
                            type: "body",
                            parameters: [
                                {
                                    type: "text",
                                    text: element.UserName,
                                },
                            ],
                        },
                    ],
                },
            };
            axios
                .post("https://graph.facebook.com/v17.0/177696368765013/messages", data, ApiHeaders)
                .then((res) => {
                    WhatsAppMessageStatus.current.Admin = res.status;
                    WhatsAppLog(data, element?.id);
                })
                .catch((error) => {
                    WhatsAppMessageStatus.current.Admin = error?.response?.status;
                });
        });
    };

    const WhatsAppLog = (Apidata, id) => {
        const url = "/api/Participant/WhatsAppLog";
        const data = {
            Number: Apidata?.to?.toString(),
            Template: Apidata.template.name,
            SendBy: UserName,
            AttendeeId: id.toString(),
            Screen: "VIEW-NEW-ATTENDEES",
        };
        axios
            .post(url, data)
            .then((res) => {})
            .catch((error) => {
                console.log(error);
            });
    };
    //#endregion

    return (
        <AccessControl allowedPermissions={["route:viewnewattendees"]} renderNoAccess={() => <NoAccess />}>
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="p-col">
                            <div className="card">
                                <h5 className="headerfont">
                                    <span>
                                        <i className="fa fa-solid fa-users mr-2"></i>View New Attendees
                                    </span>
                                </h5>
                                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                <Tooltip target="#viewattendee" content="View Attendee" />
                                <Tooltip target="#mailto" content="Mail To" />
                                <Tooltip target="#callto" content="Call To" />
                                <DataTable
                                    selectionMode={"checkbox"}
                                    onAllRowsSelect={onAllRowSelect}
                                    scrollable
                                    scrollHeight="calc(100vh - 250px)"
                                    selection={selecteddata}
                                    onSelectionChange={(e) => {
                                        setselecteddata(e.value);
                                    }}
                                    onRowSelect={onRowSelect}
                                    onRowUnselect={onRowUnselect}
                                    onAllRowsUnselect={onAllRowUnSelect}
                                    size="small"
                                    lazy
                                    rows={lazyState.current.rows}
                                    onSort={onSort}
                                    sortField={lazyState.current.sortField}
                                    sortOrder={lazyState.current.sortOrder}
                                    removableSort
                                    stripedRows
                                    ref={dt}
                                    value={participantlist}
                                    //  selection={participantlist} onSelectionChange={(e) => setParticipantlist(e.value)}
                                    responsiveLayout="scroll"
                                    filterDisplay={"row"}
                                >
                                    <Column selectionMode="multiple" frozen={NameFrozen} className={usrDetails.role == "MR" ? "hidden" : ""} headerStyle={{ width: "1%", minWidth: "1rem" }}></Column>
                                    <Column alignFrozen="left" field="serialNumber" header="Id" sortField="SerialNumber" sortable frozen={NameFrozen} headerStyle={{ width: "3%", minWidth: "5rem" }} filter showFilterMenu={false} filterElement={SerialNumberFilterTemplate} showClearButton></Column>
                                    <Column body={ViewAttendees} frozen={NameFrozen} sortField="FullName" field="fullName" header="Name" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={UserFilterTemplate} showClearButton></Column>
                                    <Column field="hospitalName" sortField="HospitalName" header="Hospital" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate} showClearButton></Column>
                                    <Column field="company" header="Company" sortField="company" sortable headerStyle={{ width: "25%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} showClearButton></Column>
                                    <Column body={ButtonMailto} sortField="Email" field="email" header="Email" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={EmailFilterTemplate}></Column>
                                    <Column body={ButtonPhoneto} sortField="MobileNo" field="mobileNo" header="Mobile No." sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={MobileFilterTemplate}></Column>
                                    {hidecolumnComponents}
                                    {/* <Column header="Action" body={actionBodyTemplate} exportable={false} headerStyle={{ width: '10%', minWidth: '12rem' }}></Column> */}
                                </DataTable>
                                <Paginator
                                    className="justify-content-end"
                                    style={{ marginRight: "15px" }}
                                    first={lazyState.current.first}
                                    rows={lazyState.current.rows}
                                    totalRecords={totalRecords}
                                    template={template2}
                                    rowsPerPageOptions={RowsPerPageDropdown}
                                    onPageChange={onBasicPageChange}
                                ></Paginator>
                                <div className="hidden">
                                    {QrCodes.map((element, index) => (
                                        <QRCode
                                            id={`qrCodeEl${element.qrId}`}
                                            size={150}
                                            // value={qrCodeText}
                                            value={element.url}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </AccessControl>
    );
};

export default ViewNewAttendees;
