import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { Toolbar } from 'primereact/toolbar';
import { Paginator } from 'primereact/paginator';
import { Link } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory } from 'react-router-dom';
import { FileUpload } from 'primereact/fileupload';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import * as GlobalEnumData from "./../../enums/GlobalEnum"
import exportData from "../../services/exportToExcelService";
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Tooltip } from 'primereact/tooltip';
import userDetails from "../../services/userTokenDetails";

const AttendanceSummary = () => {
    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [dayOfEventScreen, setDayOfEventScreen] = useState();
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [totalSize, setTotalSize] = useState(0);
    const [ticketDialog, setTicketdialog] = useState(false);
    const [materialFile, setmatierialfile] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [makePresetParticipant, setMakePresentParticipant] = useState([]);
    const [fullName, setfullName] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [filterCity, setFilterCity] = useState(null);
    const [filterState, setFilterState] = useState(null);
    const [filterCountry, setFilterCountry] = useState(null);
    const [filterBoardingCity, setFilterBoardingCity] = useState(null);
    const [filterHospitalName, setFilterHospitalName] = useState(null);
    const [filterCardNo, setFilterCardNo] = useState(null);
    const [filterCompany, setFilterCompany] = useState(null);
    const [filterSpeciality, setFilterSpeciality] = useState(null);
    const [files, setFiles] = useState([]);
    const [disablesubmit, setdisablesubmit] = useState(true);
    const [id, setid] = useState(new Array());
    const [ActionDisable, setActionDisable] = useState(true);
    let _totalSize = totalSize;

    // Flags for show/hide columns
    const [enableFilter, setEnableFilter] = useState(false);
    const fileUploadRef = useRef(null);
    var usrDetails = userDetails();

    // Const
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    const UserName = localStorage.getItem('Username');

    let DayOfEvent = "";
    let ModifyParticipantList = [];

    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: null,
        genderdropdowndata: null,
        filterMerchandiseIssued: null,
        filterCertificateIssued: null,
        filterPresent: null,
        fullName: null,
        filterHospitalName: null,
        filterEmail: null,
        filterMobileNo: null,
        filterCity: null,
        filterCountry: null,
        filterCardNo: null,
        filterAddress: null,
        filterSpeciality: null,
        filterBoardingCity: null,
        filterState: null,
        filterCountry: null,
        filterdaytwoPresent: null,
    }); // For Sorting And Pagination Purpose

    // On Screen Load
    useEffect(() => {
        getparticipantlist();
        document.documentElement.style.setProperty('overflow', 'hidden')
    }, []);

    //#region Common Methods

    // - Get All Participant Api
    const getparticipantlist = (isExportExcel = false, event, IsPresent = null) => {
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios.post('/api/Participant/GetAll', {
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event && event.sortField ? event.sortField : "Firstname",
            registrationStatus: 'APPROVED',
            CompanyFilter: lazyState.current.filterCompany ? lazyState.current.filterCompany.trim() : '',
            FullName: lazyState.current.fullName ? lazyState.current.fullName.trim() : null,
            HospitalName: lazyState.current.filterHospitalName ? lazyState.current.filterHospitalName.trim() : null,
            Email: lazyState.current.filterEmail ? lazyState.current.filterEmail.trim() : null,
            MobileNo: lazyState.current.filterMobileNo ? lazyState.current.filterMobileNo.trim() : null,
            CardNo: lazyState.current.filterCardNo ? lazyState.current.filterCardNo.trim() : null,
            City: lazyState.current.filterCity ? lazyState.current.filterCity.trim() : null,
            Speciality: lazyState.current.filterSpeciality ? lazyState.current.filterSpeciality.trim() : null,
            BoardingCity: lazyState.current.filterBoardingCity ? lazyState.current.filterBoardingCity.trim() : null,
            State: lazyState.current.filterState ? lazyState.current.filterState.trim() : null,
            country: lazyState.current.filterCountry ? lazyState.current.filterCountry.trim() : null,
            Present: lazyState.current.filterPresent ? lazyState.current.filterPresent.code.trim() : null,
            DayTwoPresent: lazyState.current.filterdaytwoPresent ? lazyState.current.filterdaytwoPresent.code.trim() : null,
        }).then(Response => {
            console.log(Response.data.data)
            DayOfEvent = "Attendance Summary";
            setDayOfEventScreen("Attendance Summary");
            if (!isExportExcel) setParticipantlist([]);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                // Pass body data in excelsheets.
                let allExcelData;
                let excelcolumnData = {
                    Id: dataObj.serialNumber,
                    Name: dataObj.fullName,
                    Company: dataObj.company,
                    Email: dataObj.email,
                    "Mobile No": dataObj.mobileNo,
                    "Present": dataObj.present == true ? "Yes" : dataObj.present == false ? "No" : "",
                    "DayTwoPresent": dataObj.dayTwoPresent == true ? "Yes" : dataObj.dayTwoPresent == false ? "No" : "",
                    "CardNo": dataObj.cardNo,
                    // Hospital: dataObj.hospitalName,
                    // "City": dataObj.cityname,
                };
                if (selectedColumns.length > 0) {
                    for (let selectedColumn of selectedColumns) {
                        let fieldName = selectedColumn.field;
                        let headerName = selectedColumn.header;
                        excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                    };
                    tempData.push(excelcolumnData);
                } else if (isExportExcel && IsPresent != null) {
                    allExcelData = {
                        Id: dataObj.serialNumber,
                        Name: dataObj.fullName,
                        Company: dataObj.company,
                        Email: dataObj.email,
                        "Mobile No": dataObj.mobileNo,
                        "Present": dataObj.present == true ? "Yes" : dataObj.present == false ? "No" : "",
                        "DayTwoPresent": dataObj.dayTwoPresent == true ? "Yes" : dataObj.dayTwoPresent == false ? "No" : "",
                        "CardNo": dataObj.cardNo,
                        // Hospital: dataObj.hospitalName,
                        // "City": dataObj.cityname,
                    };
                    tempData.push(allExcelData);
                }
                else {
                    allExcelData = {
                        Id: dataObj.serialNumber,
                        Name: dataObj.fullName,
                        Company: dataObj.company,
                        Email: dataObj.email,
                        "Mobile No": dataObj.mobileNo,
                        "Present": dataObj.present == true ? "Yes" : dataObj.present == false ? "No" : "",
                        "DayTwoPresent": dataObj.dayTwoPresent == true ? "Yes" : dataObj.dayTwoPresent == false ? "No" : "",
                        "CardNo": dataObj.cardNo,
                        // "City": dataObj.cityname,
                        // Hospital: dataObj.hospitalName,
                    };
                    tempData.push(allExcelData);
                };
            };
            if (isExportExcel) {
                exportData(tempData, DayOfEvent);
                setIsLoading(false);
                return false;
            };
            setTotalRecords(Response.data.totalRecords);
            ModifyParticipantList = Response.data.data.map((TricoConfirmedData) => ({
                ...TricoConfirmedData,
                present: TricoConfirmedData.present === true ? "Yes" : TricoConfirmedData.present === false ? 'No' : ''
            }));
            setParticipantlist(ModifyParticipantList);
            setIsLoading(false);
        })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    const PresentAttendee = () => {
        setIsLoading(true);
        axios.put('/api/Participant/updateparticipantbymultiple', {
            "Id": id.toString(),
            "Present": true,
            ModifiedBy:UserName
        })
            .then(res => {
                setIsLoading(false);
                id.splice(0, id.length);
                setid([]);
                setActionDisable(true);
                setSelectedParticipant([]);
                getparticipantlist(false, lazyState.current, null);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Attendee Present Updated', life: 3000 });
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    const DayTwoPresentAttendee = () => {
        setIsLoading(true);
        axios.put('/api/Participant/updateparticipantbymultiple', {
            "Id": id.toString(),
            "DayTwoPresent": true,
            // "Present": true,
            ModifiedBy:UserName
        })
            .then(res => {
                setIsLoading(false);
                id.splice(0, id.length);
                setid([]);
                setActionDisable(true);
                setSelectedParticipant([]);
                getparticipantlist(false, lazyState.current, null);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Attendee Present Updated', life: 3000 });
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
            setIsLoading(true);
            lazyState.current.sortField = lazyState.current.sortField;
            lazyState.current.sortOrder = lazyState.current.sortOrder;
            lazyState.current.first = event.first;
            lazyState.current.rows = event.rows;
            getparticipantlist(false, lazyState.current, null);
            setIsLoading(false);
        // };
    };

    //Template for pagination
    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (<span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>Showing {options.first} to {options.last} of {options.totalRecords} entries</span>);
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    const openNew = () => {
        // document.documentElement.style.setProperty('overflow', 'hidden');
        setmatierialfile([])
        setSubmitted(false);
        setTicketdialog(true);
    };

    // Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <AccessControl allowedPermissions={["list:attendancesummary"]}>
                <div className="my-2">
                    {/* <Button name="Present" disabled={!(participantlist != null && participantlist.length > 0)} onClick={(e) => { getparticipantlist(true, null, (e.target.name == "Present" ? true : false)); }} icon="pi pi-file-excel " className="mr-2" label="Export Present" outlined></Button> */}
                    {/* <Button name="Absent" disabled={!(participantlist != null && participantlist.length > 0)} onClick={(e) => { getparticipantlist(true, null, (e.target.name == "Present" ? true : false)); }} icon="pi pi-file-excel " className="mr-2" label="Export Absent" outlined></Button> */}
                    <Button icon="fa fa-upload" className="mr-2" onClick={openNew} label="Make Present" outlined></Button>
                    <Button disabled={true} onClick={() => PresentAttendee()} name="Present" className="mr-2" icon="fa fa-check" label="Present" outlined></Button>
                    <Button disabled={ActionDisable} onClick={() => DayTwoPresentAttendee()} name="Present" className="mr-2" icon="fa fa-check" label="Day-2 Present" outlined></Button>
                </div>
            </AccessControl>
        );
    };

    const removeFilter = () => {
        lazyState.current.fullName = null;
        setfullName(null);
        lazyState.current.filterHospitalName = null;
        setFilterHospitalName(null);
        lazyState.current.filterCompany = null;
        setFilterCompany(null);
        lazyState.current.filterEmail = null;
        setFilterEmail(null);
        lazyState.current.filterMobileNo = null;
        setFilterMobileNo(null);
        lazyState.current.filterCity = null;
        setFilterCity(null);
        lazyState.current.filterPresent = null;
        lazyState.current.filterdaytwoPresent = null;
        lazyState.current.filterSpeciality = null;
        setFilterSpeciality(null);
        lazyState.current.filterBoardingCity = null;
        setFilterBoardingCity(null);
        lazyState.current.filterState = null;
        setFilterState(null);
        lazyState.current.filterCountry = null;
        setFilterCountry(null);
        lazyState.current.filterCardNo = null;
        setFilterCardNo(null);
        getparticipantlist(false, lazyState.current, null);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl allowedPermissions={["list:attendancesummary"]}>
                    <Button disabled={lazyState.current.fullName == null && lazyState.current.filterHospitalName == null && lazyState.current.filterCompany == null &&
                        lazyState.current.filterEmail == null && lazyState.current.filterMobileNo == null && lazyState.current.filterCity == null &&
                        lazyState.current.filterPresent == null && lazyState.current.filterSpeciality == null && lazyState.current.filterBoardingCity == null &&
                        lazyState.current.filterState == null && lazyState.current.filterdaytwoPresent == null && lazyState.current.filterCountry == null && lazyState.current.filterCardNo == null ? true : false}
                        icon="pi pi-filter-slash" className="p-button-outlined mr-2" onClick={() => removeFilter()} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }}></Button>
                    {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
                    <div className="my-2">{header}</div>
                </AccessControl>
                <AccessControl allowedPermissions={["export:attendancesummary"]}>
                    <Button disabled={!(participantlist != null && participantlist.length > 0)} onClick={() => { getparticipantlist(true, null); }} icon="pi pi-file-excel" className="mr-2" outlined />
                </AccessControl>
            </div>
        );
    };

    //For Email
    const ButtonMailto = (rowData) => {
        return (
            <Link className='linkcolors' id='mailto'
                to='#'
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >{rowData.email}
            </Link>
        );
    };

    //For Mobile No
    const ButtonPhoneto = (rowData) => {
        return (
            <Link className='linkcolors' id='callto'
                to='#'
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >{rowData.mobileNo}
            </Link>
        );
    };

    // For Sorting
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        //lazyState.current = event;
        getparticipantlist(false, lazyState.current, null);
    };
    //#endregion

    //#region Method for view attendees 
    const ViewAttendees = (rowData) => {
        return (
            <Link className='linkcolors' id='viewattendee'
                to='#'
                onClick={(e) => {
                    history.push('/home/viewparticipant/' + rowData.id)
                }}
            >{rowData.fullName}
            </Link>
        );
    };
    //#endregion

    //#region Method for Import Card No
    const importCardNo = (e, processName = "") => {
        const file = e[0];
        import('xlsx').then(xlsx => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, { type: 'array' });
                const wsname = processName != "" ? "Exception Stat." : wb.SheetNames[0];
                const header = processName != "" ? ['ID', 'Name', 'Department', 'Date', 'On-duty', 'Off-duty'] : 1
                const ws = wb.Sheets[wsname];
                const data = xlsx.utils.sheet_to_json(ws, { header: header });

                // Prepare DataTable
                const cols = data[0];
                data.shift();
                if (processName == "MakePresent") {
                    let particpantData = [];
                    data.forEach(element => {
                        if (element['ID'] && element['On-duty'] && element['Off-duty']) {
                            particpantData.push({
                                name: element['Name'],
                                SerialNumber: parseInt(element['ID'])
                            });
                        };
                    });
                    setMakePresentParticipant(particpantData);
                    setdisablesubmit(false);
                };
            };
            reader.readAsArrayBuffer(file);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    //#endregion

    //#region Method for filteration

    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.fullName = e.target.value ? e.target.value : null
                        break;
                    case "hospital":
                        lazyState.current.filterHospitalName = e.target.value ? e.target.value : null
                        break;
                    case "company":
                        lazyState.current.filterCompany = e.target.value ? e.target.value : null
                        break;
                    case "Email":
                        lazyState.current.filterEmail = e.target.value ? e.target.value : null
                        break;
                    case "MobileNo":
                        lazyState.current.filterMobileNo = e.target.value ? e.target.value : null
                        break;
                    case "city":
                        lazyState.current.filterCity = e.target.value ? e.target.value : null
                        break;
                    case "CardNo":
                        lazyState.current.filterCardNo = e.target.value ? e.target.value : null
                        break;
                    case "address":
                        lazyState.current.filterAddress = e.target.value ? e.target.value : null
                        break;
                    case "speciality":
                        lazyState.current.filterSpeciality = e.target.value ? e.target.value : null
                        break;
                    case "boardingcity":
                        lazyState.current.filterBoardingCity = e.target.value ? e.target.value : null
                        break;
                    case "state":
                        lazyState.current.filterState = e.target.value ? e.target.value : null
                        break;
                    case "country":
                        lazyState.current.filterCountry = e.target.value ? e.target.value : null
                        break;
                };
                getparticipantlist(false, lazyState.current, null);
                setIsLoading(false);
            };
        };
    };

    const UserFilterTemplate = () => {
        return (
            <InputText id="fullName" autoFocus value={fullName} placeholder="Enter FirstName"
                onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    const HospitalFilterTemplate = () => {
        return (
            <InputText id="hospital" value={filterHospitalName} placeholder="Enter Hospital"
                onChange={(e) => setFilterHospitalName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    const EmailFilterTemplate = () => {
        return (
            <InputText id="Email" value={filterEmail} placeholder="Enter Email"
                onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    const MobileFilterTemplate = () => {
        return (
            <InputText id="MobileNo" value={filterMobileNo} placeholder="Enter Mobile no"
                onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    const CardNoFilterTemplate = () => {
        return (
            <InputText id="CardNo" value={filterCardNo} placeholder="Enter Card No"
                onChange={(e) => setFilterCardNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    const CompanyFilterTemplate = () => {
        return (
            <InputText id="company" value={filterCompany} placeholder="Enter Company"
                onChange={(e) => setFilterCompany(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    const CityFilterTemplate = () => {
        return (
            <InputText id="city" value={filterCity} placeholder="Enter City"
                onChange={(e) => setFilterCity(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    const PresentFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.filterPresent} options={GlobalEnumData.GlobalEnum} optionLabel="name" showClear onChange={(e) => { lazyState.current.filterPresent = (e.target.value ? e.target.value : null); getparticipantlist(); e.preventDefault() }} placeholder="Select Present" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const DayTwoPresentFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.filterdaytwoPresent} options={GlobalEnumData.GlobalEnum} optionLabel="name" showClear onChange={(e) => { lazyState.current.filterdaytwoPresent = (e.target.value ? e.target.value : null); getparticipantlist(); e.preventDefault() }} placeholder="Select Day Two Present" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const SpecialityFilterTemplate = () => {
        return (
            <InputText id="speciality" value={filterSpeciality} placeholder="Enter Speciality"
                onChange={(e) => setFilterSpeciality(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    const BoardingCityFilterTemplate = () => {
        return (
            <InputText id="boardingcity" value={filterBoardingCity} placeholder="Enter Boarding City"
                onChange={(e) => setFilterBoardingCity(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    const StateFilterTemplate = () => {
        return (
            <InputText id="state" value={filterState} placeholder="Enter State"
                onChange={(e) => setFilterState(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    const CountryFilterTemplate = () => {
        return (
            <InputText id="country" value={filterCountry} placeholder="Enter Country"
                onChange={(e) => setFilterCountry(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    //#endregion

    //#region File upload template
    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column" style={{ marginTop: '2rem' }}>
                <i className="fa fa-upload " style={{ height: '3rem', fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Upload your Attendance sheet here
                </span>
            </div>
        );
    };

    const headerTemplate = (options) => {
        const { className, chooseButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {/* <Button icon="fa fa-times" className="p-button-outlined p-button-warning p-button-rounded"></Button> */}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span className="mr-2">{formatedValue} / 25MB </span>
                    {/* <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar> */}
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        if (files.filter(x => x.name == file.name)?.length > 0) {
            return (
                <div className="flex align-items-center flex-wrap">
                    <div className="flex align-items-center" style={{ width: '70%' }}>
                        <img role="presentation" src={file.objectURL} />
                        <span className="flex flex-column text-left ">
                            {file.name};
                            <small>{new Date().toLocaleDateString()}</small>
                        </span>
                    </div>
                    <Tag value={props.formatSize} severity="warning" />
                    <Button type="button" icon="fa fa-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto " style={{ width: '2.5rem', height: '2.5rem' }} onClick={() => onTemplateRemove(file, props.onRemove)} />
                </div>
            );
        }
    };

    const UpdateParticipantAttendance = () => {
        setSubmitted(true)
        const url = '/api/Participant/UpdateParticipantPresence';
        const data = makePresetParticipant;
        axios.post(url, data)
            .then(res => {
                setIsLoading(false);
                setTicketdialog(false);
                setTotalSize(0);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Attendance Updated Successfully', life: 3000 });
                // document.documentElement.style.setProperty('overflow', 'auto');
                getparticipantlist(false, lazyState.current, null);
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please Select Appropriate File', life: 3000 });
            });
    };

    const ticketDialogFooter = (
        <div className="mt-0">
            <Button label="Submit" icon="fa fa-check" disabled={disablesubmit} onClick={() => {UpdateParticipantAttendance();}} outlined />
            <Button label="Cancel" icon="fa fa-times" onClick={(e) => { 
                // document.documentElement.style.setProperty('overflow', 'auto');
                 setTotalSize(0); setTicketdialog(false) }} outlined />
        </div>
    );

    const onSelect = (e) => {
        // let _totalSize = totalSize;
        // for (let dataObj of e.files) {
        //     if ((_totalSize + dataObj.size) < 25000000) {
        //         _totalSize += dataObj.size
        //         files.push(dataObj);
        //     }
        // };
        // setTotalSize(_totalSize);
        // importCardNo(files, "MakePresent")
        // setmatierialfile(files);

        for (let dataObj of e.files) {
            if (dataObj.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                if ((_totalSize + dataObj.size) < 26214400) {
                    _totalSize += dataObj.size;
                    files.push(dataObj);
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Warning', detail: 'Please Select File Upto 25Mb Only', life: 3000 });
                }
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Warning', detail: 'Please Select Only Pdf Format File', life: 3000 });
            }
            setTotalSize(_totalSize);
            importCardNo(files, "MakePresent")
            setmatierialfile(files);
        };
    };

    const onTemplateRemove = (file, callback) => {
        var fileSizeAfterRemove = totalSize - file.size;
        setTotalSize(fileSizeAfterRemove);
        var indexofFileToRemove = files.indexOf(file);
        files.splice(indexofFileToRemove, 1);
        setmatierialfile(files);
        callback();
    };
    //#endregion

    //#region Method for Column toggle
    const hidecolumns = [
        // { field: "speciality", header: "Speciality" },
        { field: "hospitalName", header: "Hospital" },
        { field: "countryname", header: "Country" },
        { field: "statename", header: "State" },
        { field: "cityname", header: "City" },
        { field: "boardingcityname", header: "Boarding City" },
    ];

    let unSelectedColumns = [];
    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = hidecolumns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        unSelectedColumns = hidecolumns.filter(o => !selectedColumns.some(i => i.field === o.field && i.header === o.header));
        unselectedColumnData();
        setSelectedColumns(orderedSelectedColumns);
    };

    const unselectedColumnData = () => {
        let isFiltered = false;
        if (unSelectedColumns) {
            unSelectedColumns.map(col => {
                switch (col.header) {
                    case "Hospital":
                        if (lazyState.current.filterHospitalName != null) {
                            lazyState.current.filterHospitalName = null;
                            isFiltered = true;
                            return setFilterHospitalName(null);
                        };
                        break;
                    case "City":
                        if (lazyState.current.filterCity != null) {
                            lazyState.current.filterCity = null;
                            isFiltered = true;
                            return setFilterCity(null);
                        };
                        break;
                    case "Boarding City":
                        if (lazyState.current.filterBoardingCity != null) {
                            lazyState.current.filterBoardingCity = null;
                            isFiltered = true;
                            return setFilterBoardingCity(null);
                        };
                        break;
                    case "State":
                        if (lazyState.current.filterState != null) {
                            lazyState.current.filterState = null;
                            isFiltered = true;
                            return setFilterState(null);
                        };
                        break;
                    case "Country":
                        if (lazyState.current.filterCountry != null) {
                            lazyState.current.filterCountry = null;
                            isFiltered = true;
                            return setFilterCountry(null);
                        };
                        break;
                };
            });
            if (isFiltered) {
                getparticipantlist(false, lazyState.current, null);
            };
        };
    };

    const header = (
        <div style={{ textAlign: 'left', marginRight: '10px' }}>
            <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: '20em' }} display="chip" />
        </div>
    );

    const sortColumn = {
        "Speciality": 'Speciality',
        "Hospital": "HospitalName",
    };

    const hidecolumnComponents = selectedColumns.map(col => {
        return <Column key={col.field} field={col.field} header={col.header} sortable sortField={sortColumn[col.header]} headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false}
            filterElement={col.header == 'Hospital' ? HospitalFilterTemplate : col.header == 'City' ? CityFilterTemplate : col.header == 'Boarding City' ? BoardingCityFilterTemplate : col.header == "State" ? StateFilterTemplate : CountryFilterTemplate} />;
    });

    // Row Selection / Unselect Functions
    const onAllRowSelect = (e) => {
        setActionDisable(false);
        setid(e.data.map((i, k) => {
            return i.id
        }));
    };

    const onRowSelect = (event) => {
        setActionDisable(false);
        setid((ing) => [...ing, event.data.id]);
    };

    const onRowUnselect = (event) => {
        if (id != undefined) {
            var removeidfromdata = id.indexOf(event.data.id);
            id.splice(removeidfromdata, 1);
            if (id.length == 0) {
                setActionDisable(true);
            };
        } else {
            var removefromarray = id.indexOf(event.data.id);
            id.splice(removefromarray, 1);
        };
    };

    const onAllRowUnSelect = (e) => {
        setActionDisable(true);
        setid(e.data.map((i, k) => {
            return i.id
        }));
        id.splice(0, id.length);
    };

    const ViewAttendeeIcon = (rowData) => {
        return (
            <div className="actions">
                <Link to='#' onClick={(e) => history.push({ pathname: '/home/viewattendeeRecord/' + rowData.id, state: { detail: "DayOfEvent" } })}> <span className={usrDetails?.role == "ADMIN" || usrDetails?.role == "ITADMIN"? "fa fa-solid fa-eye" : 'hidden'} id="viewattendeerecord" style={{ color: ' rgb(42, 102, 151)' }}></span></Link>
            </div>
        );
    };


    //#endregion

    return (
        <AccessControl allowedPermissions={["route:attendancesummary"]} renderNoAccess={() => <NoAccess />} >
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <h5 className="headerfont"><span><i className="fa fa-solid fa-calendar mr-2"></i>{dayOfEventScreen}</span></h5>
                                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <Tooltip target="#viewattendee" content="View / Edit Attendee" />
                                    <Tooltip target="#mailto" content="Mail To" />
                                    <Tooltip target="#callto" content="Call To" />
                                    <Tooltip target="#viewattendeerecord" content="View Attendee Record" />
                                    <DataTable selectionMode={"checkbox"} onAllRowsSelect={onAllRowSelect} onRowSelect={onRowSelect}
                                        onRowUnselect={onRowUnselect} onAllRowsUnselect={onAllRowUnSelect} scrollHeight="calc(100vh - 250px)"
                                        size="small" stripedRows ref={dt} value={participantlist} filterDisplay={"row"}
                                        lazy rows={lazyState.current.rows} onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder} removableSort
                                        selection={selectedParticipant} onSelectionChange={(e) => setSelectedParticipant(e.value)}
                                        className="datatable-responsive" responsiveLayout="scroll" scrollable>
                                        <Column body={ViewAttendeeIcon} frozen exportable={true} headerStyle={{ width: '0%', minWidth: '2rem' }} ></Column>
                                        <Column frozen selectionMode="multiple" headerStyle={{ width: '1%', minWidth: '1rem' }}></Column>
                                        <Column body={ViewAttendees} sortField="FullName" frozen field="fullName" header="Name" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={UserFilterTemplate}></Column>
                                        {/* <Column field="hospitalName" header="Hospital" sortField="HospitalName" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate} ></Column> */}
                                        <Column field="company" header="Company" sortField="company" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} ></Column>
                                        <Column body={ButtonMailto} header="Email" sortField="Email" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={EmailFilterTemplate} ></Column>
                                        <Column body={ButtonPhoneto} header="Mobile No" sortField="MobileNo" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={MobileFilterTemplate}></Column>
                                        {/* <Column field="cityname" header="City" sortField="cityname" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={CityFilterTemplate}></Column> */}
                                        <Column field="present" header="Present" sortField="Present" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={PresentFilterTemplate}></Column>
                                        <Column field="dayTwoPresentYesNo" header="Day-2 Present" sortField="DayTwoPresent" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={DayTwoPresentFilterTemplate}></Column>
                                        
                                        <Column field="cardNo" header="Card No" sortField="CardNo" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={CardNoFilterTemplate}></Column>
                                        {hidecolumnComponents}
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>
                                    <Dialog visible={ticketDialog} draggable={false} style={{ width: '500px' }} header={'Upload Attendance Sheet'} modal className="p-fluid" footer={ticketDialogFooter} onHide={(e) => {
                                        // document.documentElement.style.setProperty('overflow', 'auto');
                                         setTicketdialog(false)}}>
                                        <div className="field mb-0">
                                            <label htmlFor="name" className="font-bold">Choose Files :</label>
                                            <FileUpload ref={fileUploadRef} multiple={true} maxFileSize={26214400} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onUpload={(e) => importCardNo(e, "MakePresent")}
                                                onSelect={(e) => onSelect(e)} emptyTemplate={emptyTemplate} headerTemplate={headerTemplate} itemTemplate={itemTemplate}
                                                className={submitted && materialFile.length < 1 ? "p-invalid" : ''}  ></FileUpload>
                                            {submitted && materialFile.length < 1 && <small className="p-invalid">Please select atleast one file.</small>}
                                        </div>
                                    </Dialog>
                                </div>
                            </div >
                        </>
                    )
                }
            </div>
        </AccessControl>
    )
}
export default AttendanceSummary;