import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import { Toolbar } from 'primereact/toolbar';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import 'font-awesome/css/font-awesome.min.css';
import { classNames } from 'primereact/utils';
import { RadioButton } from "primereact/radiobutton";
import { Password } from 'primereact/password';
import { Link } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import * as ActiveStatus from "./../../enums/ActiveStatus"
import * as Roles from "./../../enums/Roles"

const Users = () => {
    let emptyUser = {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        role: '',
    };

    const [User, setUser] = useState(emptyUser);
    // const [Roles, SetRoles] = useState([{ name: '', key: '' }])
    const [selectedUsers, setSelectedUser] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [UserDialog, setUserDialog] = useState(false);
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [rolename, setrolename] = useState(null);
    const [userId, setUserId] = useState(null);
    const [isEnabled, setisEnabled] = useState(null);
    const [firstName, setfirstName] = useState(null);
    const [status, setstatus] = useState(null);
    const [userName, setUserName] = useState(null);
    const [normalizeduserName, setnormalizedUserName] = useState(null);
    const [normalizedemail, setnormalizedemail] = useState(null);
    const [passwordhash, setpasswordhash] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fullName, setfullName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState("");
    const [duplicateuserlist, setduplicateuserlist] = useState([]);
    const [emailvalidation, setemailvalidation] = useState("");
    const toast = useRef(null);
    const dt = useRef(null);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        rolenameFilter: null,
        isenabledfilter: null,
        email: null,
        Company: null,
        phone: null,
        name: null
    }); // For Sorting And Pagination Purpose
    const [enableFilter, setEnableFilter] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [isExportExcel, setIsExportExcel] = useState(false);

    useEffect(() => {
        GetUsersList();
        // GetrolesList();
        document.documentElement.style.setProperty('overflow', 'hidden');
    }, []);

    const GetUsersList = (isExportExcel = false, event) => {
        setIsLoading(true);
        if (!isExportExcel) setSelectedUser([]);
        axios.post('/api/User/GetAll', {
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event && event.sortField ? event.sortField : "FirstName",
            rolename: lazyState.current.rolenameFilter ? lazyState.current.rolenameFilter.name : null,
            UserName: lazyState.current.name ? lazyState.current.name.trim() : null,
            IsEnabled: lazyState.current.isenabledfilter ? lazyState.current.isenabledfilter.code : null,
            email: lazyState.current.email ? lazyState.current.email.trim() : null,
            phone: lazyState.current.phone ? lazyState.current.phone.trim() : null,
            company: lazyState.current.Company ? lazyState.current.Company.trim() : null,            
            Role : 'ITADMIN',
        }).then(Response => {
            if (!isExportExcel) setSelectedUser([]);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                // Pass body data in excelsheets.
                let allExcelData;
                let excelcolumnData = {
                    // Id: dataObj.id,
                    Email: dataObj.email,
                    PhoneNumber: dataObj.phoneNumber,
                    Company: dataObj.companyName,
                    Role: dataObj.role,
                    Status: dataObj.status,
                }
                if (selectedColumns.length > 0) {
                    for (let selectedColumn of selectedColumns) {
                        let fieldName = selectedColumn.field;
                        let headerName = selectedColumn.header;
                        excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                    }
                    tempData.push(excelcolumnData);
                } else {
                    allExcelData = {
                        // Id: dataObj.id,
                        Email: dataObj.email,
                        PhoneNumber: dataObj.phoneNumber,
                        Company: dataObj.companyName,
                        Role: dataObj.role,
                        Status: dataObj.status,
                    }
                    tempData.push(allExcelData);
                }
            }
            if (isExportExcel) {
                exportData(tempData, 'Users');
                setIsLoading(false);
                return false;
            }
            setSelectedUser(Response.data.data);
            setTotalRecords(Response.data.totalRecords);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    // const GetrolesList = () => {
    //     SetRoles([])
    //     axios.post('/api/Roles/GetAll', {
    //         paginationRequired: false,
    //         RoleName: null
    //     }).then(Response => {
    //         let data = []
    //         for (const dataObj of Response.data.data) {
    //             data.push({ name: dataObj.name, key: dataObj.id })
    //         }
    //         SetRoles(data);
    //         setIsLoading(false);
    //     }).catch((error) => {
    //         setIsLoading(false);
    //         toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
    //     });
    // };



    const confirmDeleteUser = () => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setIsLoading(true);
        setDeleteUserDialog(true);
        setIsLoading(false);
    };

    const saveUser = () => {
        setSubmitted(true);
        if (User.id && User.firstName.trim() && User.lastName.trim() && User.phoneNumber && User.email.trim() &&
            (formik.errors.email == '' || formik.errors.email == undefined) && (formik.errors.phoneNumber == '' || formik.errors.phoneNumber == undefined)
            && (formik.errors.password == '' || formik.errors.password == undefined)) {
            setIsLoading(true);
            axios.put(`api/User/update`, {
                id: User.id,
                firstName: User.firstName,
                lastName: User.lastName,
                email: User.email,
                phoneNumber: User.phoneNumber,
                password: User.password,
                confirmPassword: User.confirmPassword,
                role: selectedRoles.name,
                userName: userName,
                normalizedEmail: normalizedemail,
                normalizedUserName: normalizeduserName,
                passwordHash: passwordhash,
                isEnabled: User.isEnabled
            })
                .then(res => {
                    // const data = res.data;
                    // setUser(data);
                    setUserDialog(false);
                    setUser(emptyUser);
                    setSelectedRoles([]);
                    // SetRoles([]);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Updated Successfully', life: 3000 });
                    // document.documentElement.style.setProperty('overflow', 'auto');
                    GetUsersList(isExportExcel, lazyState.current);
                    setIsLoading(false);
                });
        };
        if (User.id == null && User.firstName.trim() && User.lastName.trim() && User.phoneNumber && User.email.trim() &&
            (formik.errors.email == '' || formik.errors.email == undefined) && (formik.errors.phoneNumber == '' || formik.errors.phoneNumber == undefined)
            && (formik.errors.password == '' || formik.errors.password == undefined) && User.confirmPassword == User.password) {
            axios.post(`/api/Account/registerbyadmin`, {
                firstName: User.firstName,
                lastName: User.lastName,
                email: User.email,
                phoneNumber: User.phoneNumber,
                password: User.password,
                confirmPassword: User.confirmPassword,
                role: selectedRoles.name
            }
            )
                .then(res => {
                    const data = res.data;
                    setUser(data);
                    setUserDialog(false);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'New' + " " + selectedRoles.name + " " + 'Added', life: 3000 });
                    // document.documentElement.style.setProperty('overflow', 'auto');
                    GetUsersList(isExportExcel, lazyState.current);
                    setIsLoading(false);
                }).catch((error) => {
                    if (error.response.data[0].code == "DuplicateUserName") {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'User Already Exist', life: 3000 });
                    }
                    if (error.response.data[0].code == "DuplicateUserName") {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'User Already Exist', life: 3000 });
                    }
                    else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                    };
                });
        };
    };

    const UpdateUserStatus = (id, isEnabled) => {
        setIsLoading(true);
        axios.get(`/api/User/UpdateUserStatusById/${id}?isEnabled=` + isEnabled)
            .then(res => {
                const data = res.data;
                setUser(data);
                setDeleteUserDialog(false);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Status Changed For User', life: 3000 });
                GetUsersList(isExportExcel, lazyState.current);
                GetUsersList(isExportExcel, lazyState.current);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    const UserFilterTemplate = () => {
        return (
            <InputText id="fullName" value={fullName} placeholder="Enter Name" style={{ width: '15rem' }}
                onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />
        );
    };

    const EmailFilterTemplate = () => {
        return (
            <InputText id="email" value={email} placeholder="Enter Email" style={{ width: '15rem' }}
                onChange={(e) => setEmail(e.target.value)} onKeyDown={(e) => EmailFilterOnEnter(e)} />
        );
    };

    const CompanyFilterTemplate = () => {
        return (
            <InputText id="company" value={company} placeholder="Enter Company" style={{ width: '15rem' }}
                onChange={(e) => setCompany(e.target.value)} onKeyDown={(e) => CompanyFilterOnEnter(e)} />
        );
    };

    const PhoneNoFilterTemplate = () => {
        return (
            <InputText id="phone" value={phone} placeholder="Enter Contact No" style={{ width: '15rem' }}
                onChange={(e) => setPhone(e.target.value)} onKeyDown={(e) => PhoneFilterOnEnter(e)} />
        );
    };

    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.name = e.target.value ? e.target.value : null
                GetUsersList(isExportExcel, lazyState.current);
                setIsLoading(false);
            }
        }
    };

    const EmailFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.email = e.target.value ? e.target.value : null
                GetUsersList(isExportExcel, lazyState.current);
                setIsLoading(false);
            };
        }
    };

    const PhoneFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.phone = e.target.value ? e.target.value : null
                GetUsersList(isExportExcel, lazyState.current);
                setIsLoading(false);
            };
        };
    };

    const CompanyFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.Company = e.target.value ? e.target.value : null
                GetUsersList(isExportExcel, lazyState.current);
                setIsLoading(false);
            };
        };
    };

    const StatusFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.isenabledfilter} options={ActiveStatus.ActiveStatus} optionLabel="name" showClear onChange={(e) => { lazyState.current.isenabledfilter = (e.target.value ? e.target.value : null); GetUsersList(isExportExcel, { IsEnabled: e.value }); e.preventDefault() }} placeholder="Select One" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const RoleFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.rolenameFilter} options={Roles.Roles} optionLabel="name" showClear onChange={(e) => { lazyState.current.rolenameFilter = (e.target.value ? e.target.value : null); GetUsersList(isExportExcel, { rolename: e.value }); e.preventDefault() }} placeholder="Select One" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const openNew = () => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        // GetrolesList();
        setUser(emptyUser);
        setSelectedRoles([]);
        setSubmitted(false);
        setUserDialog(true);
    };

    const editUser = (user) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setUser({ ...user });
        setUserDialog(true);
    };

    const hideDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setUserDialog(false);
        setSelectedRoles([]);
        setUser({ ...User });
        setemailvalidation("");
        //  setUser(emptyUser);
    };

    const hideDeleteProductDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setDeleteUserDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <AccessControl
                    allowedPermissions={["edit:user"]}>
                    <Button icon="fa fa-pencil" className="p-button-rounded mr-2" onClick={() => { editUser(rowData); setrolename(rowData.roleId); setUserName(rowData.userName); setnormalizedUserName(rowData.normalizedUserName); setnormalizedemail(rowData.normalizedEmail); setpasswordhash(rowData.passwordHash) }} tooltip="Edit" tooltipOptions={{ position: 'bottom' }} outlined />
                    <Button icon={rowData.isEnabled == true ? "fa fa-toggle-on" : "fa fa-toggle-off"} className="p-button-rounded mr-2" style={{ backgroundColor: '#6c757d', border: '#6c757d' }} tooltip={rowData.status == 'Active' ? 'Deactive' : 'Active'} tooltipOptions={{ position: 'bottom' }}
                        onClick={() => {
                            confirmDeleteUser(rowData); setUserId(rowData.id); setisEnabled(!rowData.isEnabled); setfirstName(rowData.firstName);
                            if (rowData.status == 'Active') {
                                setstatus('Deactive');
                            }
                            else {
                                setstatus('Active');
                            };
                        }}
                        outlined />
                </AccessControl>
            </div>
        );
    };

    const UserDialogFooter = (
        <>
            <Button label="Save" icon="fa fa-check" onClick={(e) => saveUser(e)} outlined />
            <Button label="Cancel" icon="fa fa-times" onClick={(e) => { hideDialog(e); }} outlined />
        </>
    );

    const deleteUserDialogFooter = (
        <>
            <Button label="Yes" icon="fa fa-check" autoFocus onClick={(e) => { UpdateUserStatus(userId, isEnabled); GetUsersList(isExportExcel, e) }} outlined />
            <Button label="No" icon="fa fa-times" onClick={hideDeleteProductDialog} outlined />
        </>
    );

    const leftToolbarTemplate = () => {
        return (
            <AccessControl
                allowedPermissions={["add:user"]}>
                <div className="my-2">
                    <Button label="Add User" icon="fa fa-plus" className="mr-2" onClick={openNew} outlined />
                </div>
            </AccessControl>
        );
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = { ...User };
        _user[`${name}`] = val;
        setUser(_user);
    };

    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        GetUsersList(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
            setIsLoading(true);
            lazyState.current.sortField = lazyState.current.sortField;
            lazyState.current.sortOrder = lazyState.current.sortOrder;
            lazyState.current.first = event.first;
            lazyState.current.rows = event.rows;
            GetUsersList(isExportExcel, lazyState.current);
            setSelectedUser([]);
            setIsLoading(false);
        // }
    }

    //Template for pagination
    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    const ButtonMailto = (rowData) => {
        return (
            <Link className='linkcolors' id='mailto'
                to='#'
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >{rowData.email}
            </Link>
        );
    };

    const ButtonPhoneto = (rowData) => {
        return (
            <Link className='linkcolors' id='callto'
                to='#'
                onClick={(e) => {
                    window.location.href = `tel:${rowData.phoneNumber}`;
                    e.preventDefault();
                }}
            >{rowData.phoneNumber}
            </Link>
        );
    };

    const validate = (user) => {
        const errors = {}
        if (!user.email) {
            errors.email = ''
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user.email)) {
            errors.email = 'Invalid email address  '
        };
        if (!user.phoneNumber) {
            errors.phoneNumber = ''
        } else if (! /^(\+\d{1,2}\s?)?\(?\d{3,4}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(user.phoneNumber)) {
            errors.phoneNumber = 'Invalid phone number'
        };
        if (!user.password) {
            errors.password = ''
        } else if (! /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/g.test(user.password)) {
            errors.password = 'Password must be minimum 8 characters, contain at least one number, one special character and a mixture of upper and lower case letters'
        };
        return errors
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            phoneNumber: '',
            password: ''
        },
        validate,
        onSubmit: (user) => {
            saveUser(user);
        },
    });


    const removeFilter = () => {
        lazyState.current.name = null;
        setfullName(null);
        lazyState.current.email = null;
        setEmail(null);
        lazyState.current.Company = null;
        setCompany(null);
        lazyState.current.phone = null;
        setPhone(null);
        lazyState.current.rolenameFilter = null;
        lazyState.current.isenabledfilter = null;
        GetUsersList(isExportExcel, lazyState.current);
        GetUsersList(isExportExcel, lazyState.current);
    }

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button disabled={lazyState.current.name == null && lazyState.current.email == null && lazyState.current.Company == null && lazyState.current.phone == null && lazyState.current.rolenameFilter == null && lazyState.current.isenabledfilter == null ? true : false}
                    icon="pi pi-filter-slash" className="mr-2" onClick={() => removeFilter()} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>
                {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} /> : <Button type="button" icon="pi pi-filter" className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} />} */}
                <AccessControl
                    allowedPermissions={["export:user"]}>
                    <Button disabled={!(selectedUsers != null && selectedUsers.length > 0)} onClick={() => { GetUsersList(true); }} icon="pi pi-file-excel" className="mr-2" tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined />
                </AccessControl>
            </div>
        )
    }

    return (
        <AccessControl
            allowedPermissions={["list:user"]}
            renderNoAccess={() => <NoAccess />}
        >
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <h5 className="headerfont"><span><i className="fa fa-solid fa-user mr-2"></i>Users</span></h5>
                                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <DataTable lazy rows={lazyState.current.rows} stripedRows ref={dt} value={selectedUsers} size="small"
                                        filterDisplay={"row"} 
                                        dataKey="id" className="datatable-responsive"
                                        onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                        removableSort
                                        responsiveLayout="scroll" emptyMessage="No Users found." scrollHeight="calc(100vh - 250px)" scrollable>
                                        <Column field="firstName" sortField="FirstName" header="Name" sortable filter showFilterMenu={false} filterElement={UserFilterTemplate} headerStyle={{ minWidth: '8rem' }}></Column>
                                        <Column body={ButtonMailto} sortField="Email" header="Email" sortable headerStyle={{ minWidth: '8rem' }} filter showFilterMenu={false} filterElement={EmailFilterTemplate}></Column>
                                        <Column body={ButtonPhoneto} sortField="PhoneNumber" sortable header="Contact No" headerStyle={{ minWidth: '8rem' }} filter showFilterMenu={false} filterElement={PhoneNoFilterTemplate}></Column>
                                        <Column field="companyName" sortField="CompanyName" header="Company" sortable headerStyle={{ minWidth: '8rem' }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate}></Column>
                                        <Column field="role" sortField="role" header="Role" sortable filter showFilterMenu={false} filterElement={RoleFilterTemplate} headerStyle={{ minWidth: '8rem' }}></Column>
                                        <Column field="status" sortField="Status" header="Status" sortable filter showFilterMenu={false} filterElement={StatusFilterTemplate} headerStyle={{ minWidth: '8rem' }}></Column>
                                        <div style={{ display: "none" }}>
                                            <Column field="isEnabled" header="isEnabled" sortable ></Column>
                                            <Column field="normalizedEmail" header="normalizedEmail" sortable ></Column>
                                            <Column field="userName" header="userName" sortable ></Column>
                                            <Column field="normalizedUserName" header="normalizedUserName" sortable ></Column>
                                            <Column field="passwordHash" header="passwordHash" sortable ></Column>
                                        </div>
                                        <Column body={actionBodyTemplate} exportable={false} header="Action" style={{ minWidth: '8rem' }}></Column>
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>
                                    <Dialog visible={UserDialog} draggable={false} style={{ width: '550px' }} header={User.id == null ? <span><i className="fa fa-plus-square mr-2"></i>Add User</span> : <span><i className="fa fa-edit mr-2"></i>Update User</span>} modal className="p-fluid" footer={UserDialogFooter} onHide={(e) => { hideDialog(e); GetUsersList(isExportExcel, e) }}>
                                        <div className="p-fluid formgrid grid">
                                            <div className="field col-12 md:col-12 sm:col-12">
                                                <label id="label" htmlFor="FirstName" className="mb-1 font-bold">First Name</label>
                                                <label id="Mandatoryfield" >*</label>
                                                <InputText autoFocus id="FirstName" required value={User.firstName} placeholder="Enter FirstName"
                                                    onChange={(e) => onInputChange(e, "firstName")}
                                                    maxlength="20"
                                                    className={classNames({ 'p-invalid': submitted && !User.firstName })} />
                                                {submitted && !User.firstName && <small className="p-invalid">Firstname is required.</small>}
                                            </div>

                                            <div className="field col-12 md:col-12 sm:col-12">
                                                <label id="label" htmlFor="LastName" className="mb-1 font-bold">Last Name</label>
                                                <label id="Mandatoryfield" >*</label>
                                                <InputText id="LastName" required value={User.lastName} placeholder="Enter LastName"
                                                    onChange={(e) => onInputChange(e, "lastName")}
                                                    maxlength="20"
                                                    className={classNames({ 'p-invalid': submitted && !User.lastName })} />
                                                {submitted && !User.lastName && <small className="p-invalid">Lastname is required.</small>}
                                            </div>

                                            <div className="field col-12 md:col-12 sm:col-12">
                                                <label id="label" className="mb-1" htmlFor="Email">Email</label>
                                                <label id="Mandatoryfield" >*</label>
                                                <InputText disabled={User.id == null ? false : true} value={User.email} onBlur={formik.handleBlur} type="email" name="email"
                                                    required placeholder="Enter Email" onChange={(e) => { onInputChange(e, 'email'); formik.handleChange(e) }}
                                                    className={classNames({ 'p-invalid': submitted && !User.email })} />
                                                {submitted && User.email && formik.touched.email && formik.errors.email && (<span className="p-invalid">{formik.errors.email}</span>)}
                                                {submitted && !User.email && <small className="p-invalid">Email is required.</small>}
                                            </div >

                                            <div className="field col-12 md:col-12 sm:col-12"  >
                                                <label id="label" htmlFor="Company" className={User.companyName == null ? "hidden" : "mb-1 font-bold"}>Company</label>
                                                <InputText id="Company" className={User.companyName == null ? "hidden" : classNames({ "mb-2": submitted })} required value={User.companyName} disabled={User.companyName != null ? true : false}
                                                    onChange={(e) => onInputChange(e, "Company")} />
                                            </div>

                                            <div className="field col-12 md:col-12 sm:col-12">
                                                <label id="label" htmlFor="PhoneNumber" className="mb-1 font-bold">Phone Number</label>
                                                <label id="Mandatoryfield" >*</label>
                                                <InputText value={User.phoneNumber} onBlur={formik.handleBlur} name="phoneNumber"
                                                    onChange={(e) => { onInputChange(e, 'phoneNumber'); formik.handleChange(e) }} maxlength="20"
                                                    required placeholder="Enter PhoneNumber"
                                                    className={classNames({ 'p-invalid': submitted && !User.phoneNumber })} />
                                                {submitted && formik.touched.phoneNumber && formik.errors.phoneNumber && (<span className="p-invalid">{formik.errors.phoneNumber}</span>)}
                                                {submitted && !User.phoneNumber && <small className="p-invalid">Phonenumber is required.</small>}
                                            </div>

                                            <div className={User.id == null ? 'field col-12 md:col-12 sm:col-12' : "hidden"}>
                                                <label id="label" htmlFor="Password" className="mb-1 font-bold"  >Password</label>
                                                <label id="Mandatoryfield" >*</label>
                                                <Password value={User.password} onBlur={formik.handleBlur}
                                                    type="password" name="password" placeholder="Enter Password" onChange={(e) => { onInputChange(e, "password"); formik.handleChange(e) }} toggleMask feedback={false}
                                                    className={classNames({ 'p-invalid': submitted && !User.password })} />
                                                {submitted && formik.touched.password && formik.errors.password && (<span className="p-invalid">{formik.errors.password}</span>)}
                                                {submitted && !User.password && <small className="p-invalid">Password is required.</small>}
                                            </div>
                                            <div className={User.id == null ? 'field col-12 md:col-12 sm:col-12' : "hidden"}>
                                                <label id="label" htmlFor="Confirm Password" className="mb-1 font-bold">Confirm Password</label>
                                                <label id="Mandatoryfield" >*</label>
                                                <Password value={User.confirmPassword} placeholder="Confirm Password" onChange={(e) => onInputChange(e, "confirmPassword")} toggleMask feedback={false}
                                                    className={classNames({ 'p-invalid': submitted && !User.confirmPassword })} />
                                                {submitted && !User.confirmPassword && <small className="p-invalid">Confirm password is required.</small>}
                                                {submitted && User.confirmPassword != '' && User.confirmPassword != User.password && <small className="p-invalid">Password and Confirm Password does not match.</small>}
                                            </div>

                                            <div className="field col-12 md:col-12 sm:col-12">
                                                <label id="label" htmlFor="Select Role" className="mb-2 font-bold">Select Role</label>
                                                <label id="Mandatoryfield" >*</label>
                                                <div className="flex align-items-center mb-2">
                                                    <Dropdown value={selectedRoles} onChange={(e) => setSelectedRoles(e.value)} options={Roles.Roles} optionLabel="name"
                                                        placeholder={User.role ? User.role : "Select Role"} disabled={User.role ? true : false} className="w-full" />
                                                </div>
                                                {submitted && !User.role && !selectedRoles.name && <small className="p-invalid">Please select atleast one role for user.</small>}
                                            </div>
                                        </div>
                                    </Dialog>

                                    <Dialog visible={deleteUserDialog} draggable={false} style={{ width: '450px' }} header="Confirm" modal footer={deleteUserDialogFooter} onHide={hideDeleteProductDialog}>
                                        <div className="flex align-items-center">
                                            <i
                                                className="fa fa-exclamation-triangle mr-3"
                                                style={{ fontSize: "2rem" }}
                                            />
                                            {
                                                deleteUserDialog && (
                                                    <span>
                                                        Are you sure you want to {status} user <b>{firstName}</b>?
                                                    </span>
                                                )}
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </AccessControl>
    )
}

export default Users;